import "./svaraleikjanautabón.css";
import "../../../almenncss/villaoghleður.css";
import { useState } from "react";
import miðlarabón from "../../../miðlarabón";
import TakkiAðgerð2 from "../../../hlutir/almennirhlutar/takkar/takkiAðgerð2/TakkiAðgerð2";
import { fáMiðlaravillu } from "../../../gjörníngar/ímsir";
import slóðminda from "../../../slóðminda";


const SvaraLeikjanautabón = ({
  svaraLeikjanautabón, 
  skikkaSvaraLeikjanautabón, 
  skikkaLeikjanautabónum,
  skikkaNíjumLeikjanautum
}) => {
  const [svarMiðlara, skikkaSvariMiðlara] = useState(null);
  const [hleður, skikkaHleður] = useState(null);
  const [villa, skikkaVillu] = useState(null);

  async function jánkaLeikjanautabón(jánkaðurgutlari) {
    skikkaHleður("Jánkar...");
    skikkaVillu(null);
    skikkaSvariMiðlara(null);
    const einki = jánkaðurgutlari.einki;

    try {
      const svar = await miðlarabón.post("/leikjanautar/jankaleikjanautabon/" + einki);
      if (svar.status === 200) {
        skikkaSvariMiðlara(svar.data);
        //fjarlæja úr leikjanautabónalista
        skikkaLeikjanautabónum(firriLeikjanautabónir => {
          return firriLeikjanautabónir.filter(bón => {
            return bón.einki !== einki;
          })
        })
        skikkaNíjumLeikjanautum(firriListi => [...firriListi, jánkaðurgutlari])
      }
    } catch (villaBónar) {
      skikkaVillu(fáMiðlaravillu(villaBónar));
    }
    skikkaHleður(null);
  }

  async function sinjaLeikjanautabón(einki) {
    skikkaHleður("Synjar...");
    skikkaVillu(null);
    skikkaSvariMiðlara(null);

    try {
      const svar = await miðlarabón.put("/leikjanautar/sinjaleikjanautabon/" + einki);
      if (svar.status === 200) {
        skikkaSvariMiðlara(svar.data);
        //fjarlæja gutlara úr leikjanautabónalista
        skikkaLeikjanautabónum(firriLeikjanautabónir => {
          return firriLeikjanautabónir.filter(bón => {
            return bón.einki !== einki;
          })
        })
      }
    } catch (villaBónar) {
      skikkaVillu(fáMiðlaravillu(villaBónar));
    }
    skikkaHleður(null);
  }

  return (
    <section className="svaraleikjanautabón-aðal">
      <h4 className="svaraleikjanautabón-firirsögn">Svara leikjanautabón</h4>
      <p className="svaraleikjanautabón-nafn">{svaraLeikjanautabón.gælunafn}</p>
      <img className="svaraleikjanautabón-mind" src={`${slóðminda}gutlaramindir/${svaraLeikjanautabón.mind ? svaraLeikjanautabón.mind : "171604359020013420.png"}`} alt="sætur gutlari"/>
      {villa && <p className="svaraleikjanautabón-nafn villaoghleður-villa">{villa}</p>}
      {svarMiðlara && (
        <>
          <p className="svaraleikjanautabón-nafn villaoghleður-tókst">{svarMiðlara}</p>
          <div className="svaraleikjanautabón-takki">
            <TakkiAðgerð2 texti="Flott" aðgerð={() => {
              skikkaSvaraLeikjanautabón(null);
            }}/>
          </div>
        </>
      )}
      {!(svarMiðlara || hleður) && (
        <div className="svaraleikjanautabón-takkakassi">
          <div className="svaraleikjanautabón-takki">
            <TakkiAðgerð2 texti="Jánka bón" aðgerð={() => jánkaLeikjanautabón(svaraLeikjanautabón)}/>
          </div>
          <div className="svaraleikjanautabón-takki">
            <TakkiAðgerð2 texti="Synja bón" aðgerð={() => sinjaLeikjanautabón(svaraLeikjanautabón.einki)}/>
          </div>
        </div>
      )}
      {hleður && <p className="svaraleikjanautabón-nafn villaoghleður-hleður">{hleður}</p>}
    </section>
  )
}

export default SvaraLeikjanautabón;