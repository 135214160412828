import React, { useEffect, useRef } from "react"
import "./smeija.css";
import "../../../almenncss/grunncss.css";

const Smeija = ({children, lokaSmeiju}) => {

  const skrunhæð = useRef();

  useEffect(() => {

    //svo bakgrunnurinn skrunast ekki
    skrunhæð.current = window.scrollY;
    document.body.style.overflow = "hidden";
    //áður í æfón skrunaðist bakgrunnurinn þegar opnað var smeiju, en það gerist ekki leingur held ég, veit ekki alveg hvað þetta var
    // if (window.innerWidth/window.innerHeight < 6/5) {
    //   document.body.style.position = "fixed";
    // }
    
    return () => {
      document.body.style.overflow = "unset";
      document.body.style.position = "static";
      //svo farið er á sama stað aftur
      window.scrollTo(0,skrunhæð.current);
    }
 }, []);

  return (
    <>
      <div className="smeija-smeijugrunnur umgjörð-lárétt" /* onClick={(v) => {
        //"smeija-smeijugrunnur umgjörð-lárétt"
          if (v.target.className === "smeija-smeijugrunnur umgjörð-lárétt") {
            lokaSmeiju(false)
          }
        }} */>
        <div className="smeija-smeija umgjörð-lóðrétt-ómiðjað">
          {children}
        </div>
        {lokaSmeiju && <div className="smeija-lokasmeiju umgjörð-lárétt smellanlegur" onClick={() => lokaSmeiju(false)}>X</div>}
      </div>
    </>
  )
}

export default Smeija;