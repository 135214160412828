import "../../almenncss/tölvuskeitissíður.css"
import "../../almenncss/grunncss.css";
import { useParams } from "react-router-dom";
import { useState } from "react";
import ÚtfillíngarTexti from "../../hlutir/almennirhlutar/útfillíngar/útfillíngartexti/ÚtfillíngarTexti.js"
import miðlarabón from "../../miðlarabón";
import TilkinníngarSmeija from "../../hlutir/almennirhlutar/tilkinníngarsmeija/TilkinníngarSmeija.js";
import VilluHleðurTakki from "../../hlutir/almennirhlutar/villaoghleður/villuhleðurtakki/VilluHleðurTakki.js";
import { fáMiðlaravillu } from "../../gjörníngar/ímsir.js";


const GleimtLikilorð = () => {
  const [likilorð, skikkaLikilorði] = useState("");
  const [endurtekiðLikilorð, skikkaEndurteknuLikilorði] = useState("");

  const [svarFráMiðlara, skikkaSvariFráMiðlara] = useState(null);
  const [hleður, skikkaHleður] = useState(null);
  const [villa, skikkaVillu] = useState(null);

  
  const {adgangur, dulmal} = useParams();

  async function breitaLikilorði() {
    if (!likilorð) {
      skikkaVillu("Vantar lykilorð");
      skikkaHleður(null);
      return;
    }
    if (likilorð !== endurtekiðLikilorð) {
      skikkaVillu("Lykilorð er ekki það sama og endurtekið likilorð");
      skikkaHleður(null);
      return;
    }

    skikkaVillu(null);
    skikkaSvariFráMiðlara(null);
    skikkaHleður("Breytir...");
    
    let slóð = "/gutlaraskraning/gleimtlikilord/";
    if (adgangur === "arnadarmadur") {
      slóð = "/arnadarmadur/gleimtlikilord/"
    }

    try {
      const svarMiðlara = await miðlarabón.put(slóð + dulmal, {
        likilorð
      });
      if (svarMiðlara.status === 200) {
        skikkaSvariFráMiðlara(svarMiðlara.data);
      }
    } catch (villaMiðlara) {
      skikkaVillu(fáMiðlaravillu(villaMiðlara));
    }
    skikkaHleður(null);
  }


  return (
    <section className="almenn-grunnur tölvuskeitissíða-aðal">
      <h3 className="tölvuskeitissíða-firirsögn">Breyttu um likilorð</h3>
      <p className="tölvuskeitissíða-lítilskilaboð">Athugaðu að vefslóðin gildir aðeins í 15 mínútur frá því að tölvupósturinn var sendur til þín</p>
      <div className="tölvuskeitissíða-inntak">
        <ÚtfillíngarTexti 
          texti="Lykilorð" 
          gildi={likilorð} 
          breitaGildi={skikkaLikilorði} 
          tegund="password"
        />
      </div>
      <div className="tölvuskeitissíða-inntak">
        <ÚtfillíngarTexti 
          texti="Endurtekið lykilorð" 
          gildi={endurtekiðLikilorð} 
          breitaGildi={skikkaEndurteknuLikilorði} 
          tegund="password"
        />
      </div>
      <div className="tölvuskeitissíða-minnitakki">
        <VilluHleðurTakki
          hleður={hleður}
          villa={villa}
          textiAðgerðar="Breyta lykilorði"
          aðgerð={breitaLikilorði}
        />
      </div>
      {svarFráMiðlara && (
        <TilkinníngarSmeija
          skilaboð={svarFráMiðlara}
          til={adgangur === "arnadarmadur" ? "/arnadarmadurinnskraning" : "/innskraning"}
        />
      )}
    </section>
  )
}

export default GleimtLikilorð;