import { useEffect, useMemo, useRef, useState } from "react";
import TakkiAðgerð1 from "../takkar/takkiAðgerð1/TakkiAðgerð1";
import "./flettíngarlisti.css";
import Listi from "../listi/Listi";
import TakkiAðgerð2 from "../takkar/takkiAðgerð2/TakkiAðgerð2";
import ÚtfillíngarTexti from "../útfillíngar/útfillíngartexti/ÚtfillíngarTexti.js";
import "../../../almenncss/villaoghleður.css";
import sækjaFlettilista from "../../../gjörníngar/sækjaFlettilista.js";


const FlettíngarListiEfri = ({
  veffáng, 
  tegundLista, 
  breiddLista, 
  textiAðgerðarLista,
  aðgerðLista,
  mestiFjöldiíLista,
  athugunLeitarorðs,
  leit,
  sækjaStrax,
  stærð,
  listi,
  skikkaLista,
  birjaListaVísir, 
  skikkaBirjaListaVísi,
  erNæsti,
  skikkaErNæsti,
  villa,
  skikkaVillu,
  tími,
  liðsröðun
}) => {

  const [hleður, skikkaHleður] = useState(null);
  const leitarorð = useRef("");
  const síðastaVeffáng = useRef(veffáng);

  const takkarSínileigir = (birjaListaVísir.length > 1 || erNæsti);// && listi.length > 0;
  
  const {hæðListaCSS, aukastærð} = useMemo(() => {
    const aukastærð = stærð ? `-${stærð}` : "";
    
    let hæðListaCSS = "";
    if (takkarSínileigir && leit) {
      hæðListaCSS = "flettíngarlisti-listi-aukaleitogtakkar" + aukastærð;
    } else if (takkarSínileigir) {
      hæðListaCSS = "flettíngarlisti-listi-aukatakkar" + aukastærð;
    } else if (leit) {
      hæðListaCSS = "flettíngarlisti-listi-aukaleit" + aukastærð;
    }


    return {hæðListaCSS, aukastærð}
  }, [takkarSínileigir, stærð, leit])

  
  async function náíLista(notaLeitarorð, firri, næsti, vísalisti) {
    sækjaFlettilista(
      notaLeitarorð,
      firri,
      næsti,
      vísalisti,
      skikkaVillu,
      skikkaHleður,
      athugunLeitarorðs,
      erNæsti,
      veffáng,
      mestiFjöldiíLista,
      skikkaBirjaListaVísi,
      skikkaErNæsti,
      skikkaLista,
      tími,
      liðsröðun
    )
  }


  useEffect(() => {
    //ef það á að ná í lista strax í birjun keirir það hér
    if (síðastaVeffáng.current !== veffáng) {
      náíLista(leitarorð.current, false, false, birjaListaVísir);
    } else if (sækjaStrax && !villa && listi.length < 1) {
      síðastaVeffáng.current = veffáng;
      náíLista(leitarorð.current, false, false, birjaListaVísir);
    }

  }, [veffáng])

  return (
    <section className="flettíngarlisti-aðal">
      {leit && (
        <>
          <div className={`flettíngarlisti-leit-umgjörð${aukastærð}`}>
            <ÚtfillíngarTexti 
              texti="" gildi={leitarorð.current} 
              breitaGildi={(gildi) => {
                leitarorð.current = gildi
              }}
              staðfestaTakki={() => {
                náíLista(leitarorð.current, false, false, birjaListaVísir);
              }}
            />
          </div>
          <div className={`flettíngarlisti-leit-takki${aukastærð}`}>
            <TakkiAðgerð2 texti="Leita" aðgerð={() => {
              náíLista(leitarorð.current, false, false, birjaListaVísir);
            }}/>
          </div>
        </>
      )}
      {(villa || hleður) ? (
        <>
          {villa && <p className="flettíngarlisti-skilaboðmiðlara villaoghleður-villa">{villa}</p>}
          {hleður && <p className="flettíngarlisti-skilaboðmiðlara villaoghleður-hleður">{hleður}</p>}
        </>
      ) : (
        <>
          <div className={`flettíngarlisti-listi ${hæðListaCSS}`}>
            <Listi 
              tegund={tegundLista} 
              breidd={breiddLista} 
              listi={listi} 
              textiAðgerðar={textiAðgerðarLista} 
              aðgerð={aðgerðLista}
            />
          </div>
          {takkarSínileigir && (
            <div className={`flettíngarlisti-takkar${aukastærð}`}>
              {((birjaListaVísir.length > 1 && !erNæsti) || birjaListaVísir.length > 2) && <TakkiAðgerð1 texti="Bakfletta" aðgerð={() => náíLista(leitarorð.current, true, false, birjaListaVísir)}/>}
              {erNæsti && <TakkiAðgerð1 texti="Fletta" aðgerð={() => náíLista(leitarorð.current, false, true, birjaListaVísir)}/>}
            </div>
          )}
        </>
      )}
    </section>
  )
}

export default FlettíngarListiEfri;