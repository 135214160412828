import React from "react"
import fáTímaLesmál from "../../../../gjörníngar/fáTímaLesmál"
import TakkiAðgerð1 from "../../takkar/takkiAðgerð1/TakkiAðgerð1"
import TakkiAðgerð2 from "../../takkar/takkiAðgerð2/TakkiAðgerð2"
import "./staðfestatíma.css"
import "../../../../almenncss/grunncss.css";
import { useNavigate } from "react-router-dom"

const StaðfestaTíma = ({
  skikkaTíma, 
  tímiHluta, 
  breitaTímaHluta, 
  lokaSmeiju, 
  völlurVallarsíðu
}) => {
  const [valinnDagsetníng, valinnTími] = fáTímaLesmál(tímiHluta[0], tímiHluta[1]);
  const sendaÞángað = useNavigate();

  function staðfestaTíma() {
    if (völlurVallarsíðu) {
      const búatilleikUpplísíngar = {
        völlur: völlurVallarsíðu,
        tími: tímiHluta
      }
      localStorage.setItem("buatilleik-upplisingar", JSON.stringify(búatilleikUpplísíngar));
      sendaÞángað("/buatilnijanleik?vollur=satt");
    } else {
      skikkaTíma(tímiHluta); 
      if (lokaSmeiju) {lokaSmeiju(false)}
    }
  }

  return (
    <div className="staðfestatíma-umgjörð umgjörð-lóðrétt">
      <p className="staðfestatíma-dagsetníng">{valinnDagsetníng}</p>
      <p className="staðfestatíma-tími">{valinnTími}</p>
      <div className="staðfestatíma-staðfestatíma">
        <TakkiAðgerð2 
          texti={völlurVallarsíðu ? "Búa til leik á velli fyrir þennan tíma" : "Staðfesta tíma"} 
          aðgerð={staðfestaTíma}
        />
      </div>
      <div className="staðfestatíma-breitaumtíma">
        <TakkiAðgerð1 texti="Velja annan tíma" aðgerð={() => { breitaTímaHluta([]); }} />
      </div>
      
    </div>
  )
}

export default StaðfestaTíma;