import React from "react"
import { Link } from "react-router-dom";
import "./takki2.css"

const Takki2 = ({texti, til}) => {
  return (
    <Link to={til} className="takki2">{texti}</Link>
  )
}

export default Takki2;