import "../../../almenncss/grunncss.css";
import Smeija from "../smeija/Smeija";

const SpurnUpptekni = ({spurn, skikkaSpurn}) => {
  
  return (
    <>
      {spurn && (
        <Smeija lokaSmeiju={skikkaSpurn}>
          <p className="smeija-aðaltakki spurn-rammi">Skipaður: Þegar leikur er skipaður geta aðrir skipulagt leik á sama tíma á sama velli. En aðrir sjá tímaskráninguna í dagskrá vallar.</p>
          <p className="smeija-aðaltakki spurn-rammi">Uppteknarbón: Þegar upptekni leiks er skráð sem uppteknarbón þá geta aðrir skipulagt leik á sama tíma en sent hefur verið beiðni á árnaðarmann um að gera leikinn upptekinn.</p>
          <p className="smeija-aðaltakki spurn-rammi">Upptekinn: Þegar leikur er upptekinn getur enginn annar skipulagt leik á sama tíma á sama velli.</p>
        </Smeija>
      )}
    </>
  )
}


export default SpurnUpptekni;