import React from "react"
import "./listi.css"
import LeppurGutlari from "./leppurgutlari/LeppurGutlari"
import LeppurLið from "./leppurlið/LeppurLið"
import LeppurAlmennLeikur from "./leppuralmennleikur/LeppurAlmennLeikur"
import LeppurVeljaVöll from "./leppurveljavöll/LeppurVeljaVöll"
import LeppurKappleikur from "./leppurkappleikur/LeppurKappleikur.js"
import LeppurTímaskrá from "./leppurtímaskrá/LeppurTímaskrá.js"
import LeppurFasturTími from "./leppurfasturtími/LeppurFasturTími.js"
import LeppurFirirætlun from "./leppurfirirætlun/LeppurFirirætlun.js"
import LeppurFirirtími from "./leppurfirirtími/LeppurFirirtími.js"

  

 
const Listi = ({ tegund, breidd, listi, textiAðgerðar, aðgerð }) => {
  let fáLepp;
  let cssnafn = "listi-leppakassi-" + tegund + breidd;
  
  if (tegund === "gutlarar") {
    fáLepp = (gögnLepps) => {
      return <LeppurGutlari gutlari={gögnLepps} textiAðgerðar={textiAðgerðar} aðgerð={aðgerð} />
    }
  } else if (tegund === "lið") {
    fáLepp = (gögnLepps) => {
      return <LeppurLið lið={gögnLepps} textiAðgerðar={textiAðgerðar} aðgerð={aðgerð} />
    }
  } else if (tegund === "leikur") {
    fáLepp = (gögnLepps) => {
      return <LeppurAlmennLeikur leikur={gögnLepps} textiAðgerðar={textiAðgerðar} aðgerð={aðgerð}/>
    }
  } else if (tegund === "kappleikur") {
    cssnafn = `listi-leppakassi-leikur${breidd}`;
    fáLepp = (gögnLepps) => {
      return <LeppurKappleikur leikur={gögnLepps} textiAðgerðar={textiAðgerðar} aðgerð={aðgerð}/>
    }
  } else if (tegund === "liðgögn") {
    fáLepp = (gögnLepps) => {
      return <LeppurLið lið={gögnLepps} textiAðgerðar={textiAðgerðar} aðgerð={aðgerð} smeija="liðsgögn"/>
    }
  } else if (tegund === "vellir") {
    fáLepp = (gögnLepps) => {
      return <LeppurVeljaVöll völlur={gögnLepps} textiAðgerðar={textiAðgerðar} aðgerð={aðgerð}/>
    }
  } else if (tegund === "liðsmenníleik") {
    cssnafn = `listi-leppakassi-lið${breidd}`;
    fáLepp = (gögnLepps) => {
      return <LeppurLið lið={gögnLepps} textiAðgerðar={textiAðgerðar} aðgerð={aðgerð} smeija="liðsmenníleik"/>
    }
  } else if (tegund === "firirætlun") {
    cssnafn = `listi-leppakassi-leikur${breidd}`;
    fáLepp = (gögnLepps) => {
      return <LeppurFirirætlun firirætlun={gögnLepps}/>
    }
  } else if (tegund === "firirtími") {
    cssnafn = `listi-leppakassi-leikur${breidd}`;
    fáLepp = (gögnLepps) => {
      return <LeppurFirirtími valinTímaskrá={gögnLepps} textiAðgerðar={textiAðgerðar} aðgerð={aðgerð}/>
    }
  } else if (tegund === "tímaskrá") {
    cssnafn = `listi-leppakassi-leikur${breidd}`;
    fáLepp = (gögnLepps) => {
      return <LeppurTímaskrá valinTímaskrá={gögnLepps}/>
    }
  } else if (tegund === "fasturtími") {
    cssnafn = `listi-leppakassi-leikur${breidd}`;
    fáLepp = (gögnLepps) => {
      return <LeppurFasturTími valinnFasturTími={gögnLepps}/>
    }
  }
  
  return (
    <section className="listi-aðal">
      {listi.map(stak => {
        let auðkenni = stak.einki;
        if (tegund === "kappleikur") {
          //Þegar sami leikurinn er í mörgum leppum því maður er boðinn í kappleik með mörgum liðum
          //það ætti samt bara að vera firir kappleiki sem maður er boðinn í, en auðkenni verður bara t.d. 13oundefined, sem sleppur alveg
          auðkenni = stak.einki + "o" + stak.mittliðeinki;
        } else if (stak.raðeinki) {
          //firir gutlara í gutlaraleik
          auðkenni = stak.raðeinki;
        }
        return (
          <div className={cssnafn} key={auðkenni}>
            {fáLepp(stak)}
          </div>
         )
      })}
      
    </section>
  )
}

export default Listi;