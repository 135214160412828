import { useNavigate, useParams } from "react-router-dom";
import "../../almenncss/tölvuskeitissíður.css";
import "../../almenncss/grunncss.css";
import miðlarabón from "../../miðlarabón.js"
import { useContext, useEffect, useState } from "react";
import { GutlariSamheingi } from "../../samheingi/gutlariSamheingi";
import VilluHleðurTakki from "../../hlutir/almennirhlutar/villaoghleður/villuhleðurtakki/VilluHleðurTakki";
import { fáMiðlaravillu } from "../../gjörníngar/ímsir.js";


const StaðfestaGutlarasmíð = () => {
  const [skilaboðMiðlara, skikkaSkilaboðumMiðlara] = useState(null);
  const [villa, skikkaVillu] = useState(null);
  const [hleður, skikkaHleður] = useState(null);

  const { gutlari } = useContext(GutlariSamheingi);

  const {slembitala} = useParams();
  const sendaÞángað = useNavigate();

  async function staðfestaSmíðina() {
    //senda á miðlara að gera gutlara virkan og eiða slembitölu
    skikkaHleður("Staðfestir...");
    skikkaVillu(null);
    skikkaSkilaboðumMiðlara(null);

    try {
      const svarMiðlara = await miðlarabón.put("/gutlaraskraning/innskraning/" + slembitala);
      if (svarMiðlara.status === 200) {
        skikkaSkilaboðumMiðlara(svarMiðlara.data);
        skikkaHleður(null);
        setTimeout(() => sendaÞángað("/innskraning"), 2000);
        return;
      }
    } catch (villaBónar) {
      skikkaVillu(fáMiðlaravillu(villaBónar));
    }
    skikkaHleður(null);
  }

  useEffect(() => {
    if (gutlari.gutlaranafn) {
      sendaÞángað("/");
    }
  }, [gutlari.gutlaranafn])

  return (
    <section className="almenn-grunnur tölvuskeitissíða-aðal">
      <p className="tölvuskeitissíða-lítilskilaboð">Athugaðu að vefslóðin gildir aðeins í 15 mínútur frá því að tölvupósturinn var sendur til þín</p>
      <div className="tölvuskeitissíða-stærritakki">
        <VilluHleðurTakki
          svarMiðlara={skilaboðMiðlara}
          villa={villa}
          hleður={hleður}
          aðgerð={staðfestaSmíðina}
          textiAðgerðar="Staðfesta gutlarasmíð"
        />
      </div>
    </section>
  )
}


export default StaðfestaGutlarasmíð;