import { useContext, useEffect, useRef, useState } from "react";
import miðlarabón from "../../../../../../miðlarabón.js"
import { fáMiðlaravillu } from "../../../../../../gjörníngar/ímsir.js";
import { fáDagLesmál, fáFráTilLesmál } from "../../../../../../gjörníngar/fáTímaLesmál.js";
import { fáTegund, fáUpptekni } from "../../LeppurTímaskrá.js";
import "./leikurtímaskrá.css";
import "../../../../../../almenncss/grunncss.css";
import "../../../../../../almenncss/villaoghleður.css";
import {ÁrnaðarmaðurSamheingi} from "../../../../../../samheingi/árnaðarmaðurSamheingi.js";
import Smeija from "../../../../smeija/Smeija.js";
import Gutlarasmeija from "../../../../gutlarasmeija/Gutlarasmeija.js";
import TakkiAðgerð1 from "../../../../takkar/takkiAðgerð1/TakkiAðgerð1.js";
import SpjallÁrnaðarmaður from "../../../../spjallárnaðarmaður/SpjallÁrnaðarmaður.js";
import LeikurTímaskráTakkar from "./leikurtímaskrátakkar/LeikurTímaskráTakkar.js";
import ÓtækurFasturSmeija from "../../../../smeijur/ótækurfastursmeija/ÓtækurFasturSmeija.js"


const SmeijaTímaskrá = ({tímaskrá, skikkaTímaskrá}) => { 
  const [gögnTímaskráar, skikkaGögnumTímaskráar] = useState(null);
  const [hleður, skikkaHleður] = useState(null);
  const [villa, skikkaVillu] = useState(null);
  
  const [sjáFirirætlun, skikkaSjáFirirætlun] = useState(false);
  const [sjáLeikstefni, skikkaSjáLeikstefni] = useState(false);
  const [breittUpptekni, skikkaBreittriUpptekni] = useState(tímaskrá.upptekni);


  const tilAðBreitaUpptekni = useRef();
  const breittNíSkilaboð = useRef(tímaskrá.ní_skilaboð);
  const tímaskráFargað = useRef(tímaskrá.fargað);
  tilAðBreitaUpptekni.current = breittUpptekni;

  const {völlur} = useContext(ÁrnaðarmaðurSamheingi);

  const tímiFrá = new Date(tímaskrá.tími_frá);
  const tímiTil = new Date(tímaskrá.tími_til);

  const upptekni = fáUpptekni(breittUpptekni);
  const einkiTímaskráar = tímaskrá.aðaltími ? tímaskrá.aðaltími : tímaskrá.einki;

  useEffect(() => {

    async function náíGögnTímaskráar() {
      skikkaHleður("Sækir meira um leik...");
      try {
        const náíGögn = await miðlarabón.get(`timaskra/gogn/${einkiTímaskráar}`);
        if (!náíGögn.data.tegund_leiks) {
          skikkaVillu("Leikur tímaskráar fannst ekki");
        } else {
          skikkaGögnumTímaskráar(náíGögn.data);
          skikkaBreittriUpptekni(náíGögn.data.upptekni);
        }
      } catch (villaBónar) {
        skikkaVillu(fáMiðlaravillu(villaBónar));
      }
      skikkaHleður(null);
    }

    if (!gögnTímaskráar && !tímaskrá.fargað) {
      náíGögnTímaskráar();
    }

    //uppfæra á leppi ef breitt var einkverju
    return () => {
      if (tilAðBreitaUpptekni.current !== tímaskrá.upptekni || breittNíSkilaboð.current !== tímaskrá.ní_skilaboð || tímaskráFargað.current) {
        skikkaTímaskrá(firriTímaskrá => {
          const uppfærðTímaskrá = {...firriTímaskrá};
          uppfærðTímaskrá.upptekni = tilAðBreitaUpptekni.current;
          uppfærðTímaskrá.ní_skilaboð = breittNíSkilaboð.current;
          uppfærðTímaskrá.fargað = tímaskráFargað.current;
          return uppfærðTímaskrá;
        })
      }
    }

  }, [tímaskrá.einki])

  return (
    <section className="leikurtímaskrá-aðal umgjörð-lóðrétt-ómiðjað">
      {gögnTímaskráar ? (
        <h3 className="smeija-firirsögn">{gögnTímaskráar.nafn} {gögnTímaskráar.aukanafn} {gögnTímaskráar.stærð_undirvallar}</h3>
      ) : (
        <h3 className="smeija-firirsögn">{völlur.nafn} {völlur.aukanafn} {völlur.stærð_undirvallar}</h3>  
      )}
        
      <p className="leikurtímaskrá-tími">{fáDagLesmál(tímiFrá)}</p>
      <p className="leikurtímaskrá-tími">{fáFráTilLesmál(tímiFrá, tímiTil)}</p>
      <p className="leikurtímaskrá-gögn">{upptekni} {gögnTímaskráar ? fáTegund(gögnTímaskráar.tegund_leiks) : fáTegund(tímaskrá.tegund_leiks)}</p>
      {gögnTímaskráar ? (
        <>
          <p className="leikurtímaskrá-gögn" onClick={() => skikkaSjáLeikstefni(true)}>Leikstefnirinn: <span className="leikurtímaskrá-leikstefnir">{gögnTímaskráar.leikur.skírnarnafn}</span></p>
          {(gögnTímaskráar.leikur.fjöldi_vinstri_lið || gögnTímaskráar.leikur.fjöldi_vinstri_lið === 0) && <p className="leikurtímaskrá-gögn">Fjöldi í heimaliði: {gögnTímaskráar.leikur.fjöldi_vinstri_lið}</p>}
          {(gögnTímaskráar.leikur.fjöldi_hægri_lið || gögnTímaskráar.leikur.fjöldi_hægri_lið === 0) && <p className="leikurtímaskrá-gögn">Fjöldi í mótherjaliði: {gögnTímaskráar.leikur.fjöldi_hægri_lið}</p>}
          {(gögnTímaskráar.leikur.fjöldi_gutlara) && <p className="leikurtímaskrá-gögn">Fjöldi gutlara: {gögnTímaskráar.leikur.fjöldi_gutlara}</p>}
          {(gögnTímaskráar.leikur.aðgeingi) && <p className="leikurtímaskrá-gögn">Aðgeingi leiks: {gögnTímaskráar.leikur.aðgeingi}</p>}
          <LeikurTímaskráTakkar 
            upptekni={breittUpptekni} 
            einki={einkiTímaskráar} 
            skikkaBreittriUpptekni={skikkaBreittriUpptekni}
            tímaskráFargað={tímaskráFargað}
            einkavöllur={völlur.einkavöllur}
            tegundLeiks={gögnTímaskráar.tegund_leiks}
          />
        </>
      ) : (
        <>
          {hleður && <p className="leikurtímaskrá-firirsögn villaoghleður-hleður">{hleður}</p>}
          {villa && <p className="leikurtímaskrá-firirsögn villaoghleður-villa">{villa}</p>}
          {tímaskrá.fargað  && <p className="leikurtímaskrá-firirsögn villaoghleður-villa">Tímaskrá hefur verið fargað</p>}
        </>
      )}
      {(tímaskrá.áætlun) && (
        <div className="leikurtímaskrá-gögn">
          <TakkiAðgerð1 texti="Fara í fyrirætlun" aðgerð={() => skikkaSjáFirirætlun(true)}/>
        </div>
      )}
      {!tímaskrá.fargað && (
        <>
          <p className="smeija-lítilltakki">Til að fá ný skilaboð þarf að fara úr spjalli og aftur inn í það</p>
          <SpjallÁrnaðarmaður 
            einkitímaskráar={einkiTímaskráar} 
            breittNíSkilaboð={breittNíSkilaboð} 
            sendaSlóð={`timaskra/sendaaleik/${einkiTímaskráar}`}
            sækjaSlóð={`timaskra/faskilabod/${einkiTímaskráar}?`}
          />
        </>
      )}
      {sjáLeikstefni && (
        <Smeija lokaSmeiju={skikkaSjáLeikstefni}>
          <Gutlarasmeija einkigutlara={gögnTímaskráar.leikur.leikstefnir}/>
        </Smeija>
      )}
      {sjáFirirætlun && (
        <Smeija lokaSmeiju={skikkaSjáFirirætlun}>
          <ÓtækurFasturSmeija einkiFastsTíma={tímaskrá.áætlun}/>
        </Smeija>
      )}
    </section>
  )
}


export default SmeijaTímaskrá;

