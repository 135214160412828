import React from "react"
import "./veljadag.css";
import "../../../../almenncss/grunncss.css";
import { mánuðir } from "../../../../gjörníngar/fáTímaLesmál";

const dagurinníDag = new Date();
const mánaðardaguríDag = dagurinníDag.getDate();
const mánuðuríDag = dagurinníDag.getMonth();
const áríDag = dagurinníDag.getFullYear();

const fáNæstaMánuð = (mánuður, ár) => {
  //fá mánuðinn sem er næstur í röðinni
  if (mánuður >= 0 && mánuður < 11) {
    return [mánuður + 1, ár];
  } else {
    return [0, ár + 1];
  }
}

const fáNæstuMánuði = () => {
  const mánuðir = [];
  let talaMánaðar = mánuðuríDag;
  let talaÁrs = áríDag;
  for (let i = 0; i < 12; i++) {
    let vikudagurFirsta = new Date(talaÁrs, talaMánaðar, 1).getDay();
    let notaMánuð = talaMánaðar;
    let notaÁr = talaÁrs;
    [talaMánaðar, talaÁrs] = fáNæstaMánuð(talaMánaðar, talaÁrs);
    let fjöldiDagaíMánuði = new Date(talaÁrs, talaMánaðar, 0).getDate();
    mánuðir.push({ár: notaÁr, mánuður: notaMánuð, vikudagurFirsta, fjöldiDagaíMánuði})
  }
  return mánuðir;
}

const sínaDaga = (ár, mánuður, fjöldiDaga, vikudagurFirsta, skikkaDeigi) => {
  const listiDiva = [];
  const núverandiMánuður = mánuður === mánuðuríDag;

  for (let i = 1; i <= fjöldiDaga; i++) {
    let búinn = false;
    if (núverandiMánuður) {
      //þannig búnir dagar eru rauðir
      búinn = mánaðardaguríDag > i;
    }
    const útlit = (i === 1) ? {marginLeft: `${vikudagurFirsta*4+0.5}em`} : {};
    listiDiva.push((
      <div className={`veljadag-dagur umgjörð-lárétt ${búinn ? "veljadag-búinn" : "smellanlegur"}`} key={`${i}${mánuður}${ár}`} style={útlit} onClick={() => {if (!búinn) {skikkaDeigi(new Date(ár, mánuður, i))}}}>
        {i}
      </div>
    ))
  }
  return listiDiva;
}

const VeljaDag = ({ skikkaDeigi, loka }) => {

  function breitaDeiji(dagur) {
    
    skikkaDeigi(dagur);
    if (loka) {
      loka();
    }
  }

  return (
    <section className="umgjörð-lóðrétt-ómiðjað">
      <p className="veljadag-firirsögn-mánuðir">Veldu dag</p>
      <div className="veljadag-listiivirdaga umgjörð-lóðrétt-ómiðjað">
        {fáNæstuMánuði().map((mánuður,index) => {
          return (
            <React.Fragment key={index}>
              <p className="veljadag-nafnmánaðar">{mánuðir[mánuður.mánuður]}</p>
              <div className="veljadag-nafnvikudaga">
                <p className="veljadag-nafnvikudags">
                  Sun
                </p>
                <p className="veljadag-nafnvikudags">
                  Mán
                </p>
                <p className="veljadag-nafnvikudags">
                  Þri
                </p>
                <p className="veljadag-nafnvikudags">
                  Mið
                </p>
                <p className="veljadag-nafnvikudags">
                  Fim
                </p>
                <p className="veljadag-nafnvikudags">
                  Fös
                </p>
                <p className="veljadag-nafnvikudags">
                  Lau
                </p>
              </div>
              <div className="veljadag-dagarmánaðar">
                {sínaDaga(mánuður.ár, mánuður.mánuður, mánuður.fjöldiDagaíMánuði, mánuður.vikudagurFirsta, breitaDeiji)}
              </div>
            </React.Fragment>
          )
        })}
      </div>
    </section>
  )
}

export default VeljaDag;