import React, { useContext, useRef, useState } from "react"
import TakkiAðgerð1 from "../../../../hlutir/almennirhlutar/takkar/takkiAðgerð1/TakkiAðgerð1";
import TakkiAðgerð2 from "../../../../hlutir/almennirhlutar/takkar/takkiAðgerð2/TakkiAðgerð2";
import "./veljaaðraeiginleikaleiks.css"
import ÚtfillíngarTexti from "../../../../hlutir/almennirhlutar/útfillíngar/útfillíngartexti/ÚtfillíngarTexti"
import ÚtfillíngarTala from "../../../../hlutir/almennirhlutar/útfillíngar/útfillíngartala/ÚtfillíngarTala"
import ÚtfillíngarHak from "../../../../hlutir/almennirhlutar/útfillíngar/útfillíngarhak/ÚtfillíngarHak"
import ÚtfillíngarMöguleikar from "../../../../hlutir/almennirhlutar/útfillíngar/útfillíngarmöguleikar/ÚtfillíngarMöguleikar"
import Smeija from "../../../../hlutir/almennirhlutar/smeija/Smeija";
import VeljaVöll from "../../../../hlutir/almennirhlutar/veljavöll/VeljaVöll";
import VeljaLið from "../../../../hlutir/almennirhlutar/veljalið/VeljaLið";
import { BúatilleikSamheingi } from "../../../../samheingi/búatilleikSamheingi";
import "../../../../almenncss/villaoghleður.css";
import "../../../../almenncss/grunncss.css";
import { fáÍþrótt } from "../../../../gjörníngar/ímsir";
import { íþróttir } from "../../../../almenngögn";
import slóðminda from "../../../../slóðminda";
import SpurnAðgeingiLeiks from "../../../../hlutir/almennirhlutar/spurnsmeijur/SpurnAðgeingiLeiks";
import SpurnLiðLeiks from "../../../../hlutir/almennirhlutar/spurnsmeijur/SpurnLiðLeiks";
import SpurnUpptekni from "../../../../hlutir/almennirhlutar/spurnsmeijur/SpurnUpptekni";
import SpurnUppteknarbón from "../../../../hlutir/almennirhlutar/spurnsmeijur/SpurnUppteknarbón";

//samsvarandi einkar aðgeingis í gagnasafni
export function fáAðgeingi(aðgeingi) {
  if (aðgeingi === "Luktur leikur") return 1;
  if (aðgeingi === "Opinn leikur") return 2;
  if (aðgeingi === "Bláopinn leikur") return 3;
  return 1;
}

//samsvarandi einkar kina í gagnasafni
export function fáKin(kin, valið) {
  if (valið) {
    if (kin === "Meyjaleikur \u2640") return 2;
    if (kin === "Sveinaleikur \u2642") return 1;
  }
  return null;
}

export function fáAldur(veljaAldur, lægstiAldur, hæstiAldur) {
  if (veljaAldur) {
    if (hæstiAldur < lægstiAldur) {
      return {villaAldur: "Hæsti aldur er lægri en lægsti aldur"}
    }
    return {valinnLægstiAldur: Number(lægstiAldur), valinnHæstiAldur: Number(hæstiAldur)}
  }
  return {valinnLægstiAldur: null, valinnHæstiAldur: null}

}

export function fáUppteknarbón(bón, völlur, tími) {
  if (völlur && völlur.árnaðarmaður && tími && tími.length === 2) {
    if (völlur.einkavöllur) {
      return true;
    }
    return bón;
  }
  return false;

}





const VeljaAðraEiginleikaLeiks = ({
  tími, 
  tegundLeiks, 
  skikkaSínaSmeiju,
  tilaðBreitaEiginleikumLeiks,
  völlur,
  staðurLeiks,
  skikkaStaðLeiks,
  firriEiginleikar
}) => {
  
  const {heimalið} = useContext(BúatilleikSamheingi);

  const [íþrótt, skikkaÍþrótt] = useState(firriEiginleikar.íþrótt ? firriEiginleikar.íþrótt.sínilegtGildi : "Knattspyrna");
  const [veljaVöllSmeija, skikkaVeljaVöllSmeiju] = useState(false);
  const [varavöllur, skikkaVaravelli] = useState(firriEiginleikar.varavöllur ? firriEiginleikar.varavöllur : null);

  const leiksmæli = useRef(firriEiginleikar.leiksmæli ? firriEiginleikar.leiksmæli : "");
  const geraSamanEftirLeik = useRef(firriEiginleikar.geraSamanEftirLeik ? firriEiginleikar.geraSamanEftirLeik : "");
  const [sendaUppteknarbón, skikkaSendaUppteknarbón] = useState(firriEiginleikar.sendaUppteknarbón ? firriEiginleikar.sendaUppteknarbón : false);
  
  const [mestiFjöldiíLiði, skikkaMestaFjöldaíLiði] = useState(firriEiginleikar.mestiFjöldiíLiði ? firriEiginleikar.mestiFjöldiíLiði : 10);

  const [mestiFjöldiGutlara, skikkaMestaFjöldaGutlara] = useState(firriEiginleikar.mestiFjöldiGutlara ? firriEiginleikar.mestiFjöldiGutlara : 30);
  const [aðrirMeigaBjóða, skikkaAðrirMeigaBjóða] = useState(firriEiginleikar.aðrirMeigaBjóða ? firriEiginleikar.aðrirMeigaBjóða : true);
  const [aðgeingi, skikkaAðgeingi] = useState(firriEiginleikar.aðgeingi ? firriEiginleikar.aðgeingi.sínilegtGildi : "Luktur leikur");
  const [forritSkiftiríLið, skikkaForritSkiftiríLið] = useState(firriEiginleikar.forritSkiftiríLið ? firriEiginleikar.forritSkiftiríLið : false);

  const [veljaAldur, skikkaVeljaAldur] = useState(firriEiginleikar.hæstiAldur ? true : false);
  const [hæstiAldur, skikkaHæstaAldri] = useState(firriEiginleikar.hæstiAldur ? firriEiginleikar.hæstiAldur : 90);
  const [lægstiAldur, skikkaLægstaAldri] = useState(firriEiginleikar.lægstiAldur ? firriEiginleikar.lægstiAldur : 5);

  const [veljaLiðLeiksSmeija, skikkaVeljaLiðLeiksSmeiju] = useState(false);
  const [liðLeiks, skikkaLiðiLeiks] = useState(firriEiginleikar.liðLeiks ? firriEiginleikar.liðLeiks : heimalið);

  const [veljaKin, skikkaVeljaKin] = useState((firriEiginleikar.kin && firriEiginleikar.kin.gildi) ? true : false);
  const [kin, skikkaKini] = useState(firriEiginleikar.kin ? firriEiginleikar.kin.sínilegtGildi : "");
  
  const [villa, skikkaVillu] = useState(null);
  const [spurn, skikkaSpurn] = useState(false);
  const [spurnLið, skikkaSpurnLið] = useState(false);
  const [spurnUpptekni, skikkaSpurnUpptekni] = useState(false);


  function breitaLeiksmælum(breitt) {
    leiksmæli.current = breitt;
  }

  function breitaGeraSaman(breitt) {
    geraSamanEftirLeik.current = breitt; 
  }

  function breitaÖðrumEiginleikumLeiks() {
    
    if (tegundLeiks === "gutlaraleikur" && mestiFjöldiGutlara < 2) {
      skikkaVillu("Mesti fjöldi gutlara þarf að vera hærri");
      return; 
    }
    if (tegundLeiks === "gutlaraleikur" && lægstiAldur < 0) {
      skikkaVillu("Lægsti aldur getur ekki verið mínus tala");
      return;
    }
    if (geraSamanEftirLeik.current.length > 300) {
      skikkaVillu("Texti í gera saman eftir leik er alltof langur");
      return;
    }
    if (leiksmæli.current.length > 100) {
      skikkaVillu("Leiksmæli eru alltof löng");
      return;
    }
    if (tegundLeiks === "gutlaraleikur" && !aðgeingi) {
      skikkaVillu("Þarft að velja aðgengi leiks");
      return;
    }
    if ((tegundLeiks === "kappleikur") && mestiFjöldiíLiði < 2) {
      skikkaVillu("Mesti fjöldi í liði ætti að vera hærri");
      return;
    }
 
    const valiðAðgeingi = fáAðgeingi(aðgeingi);

    const valiðKin = fáKin(kin, veljaKin);

    const valinÍþrótt = fáÍþrótt(íþrótt);

    const {valinnLægstiAldur, valinnHæstiAldur, villaAldur} = fáAldur(veljaAldur, lægstiAldur, hæstiAldur);
    if (villaAldur) {
      skikkaVillu(villaAldur);
      return;
    } 

    const valinSendaUppteknarbón = fáUppteknarbón(sendaUppteknarbón, völlur, tími);

    
    tilaðBreitaEiginleikumLeiks(
      {
        geraSamanEftirLeik: geraSamanEftirLeik.current,
        leiksmæli: leiksmæli.current,
        íþrótt: {gildi: valinÍþrótt, sínilegtGildi: íþrótt},
        varavöllur: varavöllur,
        mestiFjöldiíLiði: Number(mestiFjöldiíLiði),
        mestiFjöldiGutlara: Number(mestiFjöldiGutlara),
        aðrirMeigaBjóða: aðrirMeigaBjóða,
        aðgeingi: {gildi: valiðAðgeingi, sínilegtGildi: aðgeingi},
        forritSkiftiríLið: forritSkiftiríLið,
        hæstiAldur: valinnHæstiAldur,
        lægstiAldur: valinnLægstiAldur,
        liðLeiks: liðLeiks,
        kin: {gildi: valiðKin, sínilegtGildi: kin},
        sendaUppteknarbón: valinSendaUppteknarbón
      }
    )
    skikkaSínaSmeiju(false);
  }


  return (
    <section className="veljaeiginleiks-umgjörð umgjörð-lóðrétt-ómiðjað">
      <p className="veljaeiginleiks-firirsögn-smeiju">Veldu eiginleika leiks</p>
      <div className="veljaeiginleiks-kassi">
        <ÚtfillíngarTexti 
          texti="Hvað skal gera saman eftir leik" 
          gildi={geraSamanEftirLeik.current} 
          breitaGildi={breitaGeraSaman}
        />
      </div>
      <div className="veljaeiginleiks-kassi">
        <ÚtfillíngarTexti 
          texti="Leiksmæli (athugasemd um leik)" 
          gildi={leiksmæli.current} 
          breitaGildi={breitaLeiksmælum}
        />
      </div>

      {tegundLeiks === "gutlaraleikur" && 
        <>
          <div className="veljaeiginleiks-kassi">
            <ÚtfillíngarTala 
              texti="Mesti fjöldi gutlara" 
              gildi={mestiFjöldiGutlara} 
              breitaGildi={skikkaMestaFjöldaGutlara}
            />
          </div>
          <div className="veljaeiginleiks-kassi">
            <ÚtfillíngarHak 
              texti="Aðrir mega bjóða" 
              gildi={aðrirMeigaBjóða} 
              breitaGildi={skikkaAðrirMeigaBjóða}
            />
          </div>
          <div className="veljaeiginleiks-kassi">
            <ÚtfillíngarMöguleikar 
              texti="Aðgengi leiks" 
              gildi={aðgeingi} 
              breitaGildi={skikkaAðgeingi} 
              möguleikar={["Luktur leikur", "Opinn leikur", "Bláopinn leikur"]}
              birtaSmeiju={skikkaSpurn}
            />
          </div>
          {(liðLeiks) ? (
            <div className="umgjörð-lóðrétt-ómiðjað">
              <p className="veljaeiginleiks-varavöllur-texti">Lið leiks: <span onClick={() => skikkaSpurnLið(true)} className="spurn smellanlegur">?</span></p>
              <img 
                className="veljaeiginleiks-lið-skjaldamerki" 
                src={`${slóðminda}lidamindir/${liðLeiks.skjaldamerki ? liðLeiks.skjaldamerki : "170809081376912242.png"}`} alt="Skjaldarmerki liðs"
              />
              <p className="veljaeiginleiks-varavöllur-texti">{liðLeiks.nafn}</p>
              <div className="veljaeiginleiks-varavöllur-takkar">
                <div className="veljaeiginleiks-varavöllur-takkakassi">
                  <TakkiAðgerð1 
                    texti="Breyta liði leiks" 
                    aðgerð={() => skikkaVeljaLiðLeiksSmeiju(true)} 
                  />
                </div>
                <div className="veljaeiginleiks-varavöllur-takkakassi">
                  <TakkiAðgerð1 
                    texti="Eyða liði leiks" 
                    aðgerð={() => {skikkaLiðiLeiks(null)}}
                  />
                </div>
              </div>
            </div>
          ) : (
            
              <div className="veljaeiginleiks-velja">
                <TakkiAðgerð1 texti="Velja lið leiks" aðgerð={() => {
                  skikkaVeljaLiðLeiksSmeiju(true)}} />
              </div>
            
          )}
          <div className="veljaeiginleiks-kassi">
            <ÚtfillíngarHak 
              texti="Láta forrit skipta í lið" 
              gildi={forritSkiftiríLið} 
              breitaGildi={skikkaForritSkiftiríLið}
            />
          </div>
          <div className="veljaeiginleiks-velja">
            <TakkiAðgerð1 
              texti={veljaAldur ? "Sleppa aldurstakmörkun" : "Hafa aldurstakmörkun"} 
              aðgerð={() => skikkaVeljaAldur(!veljaAldur)}
            />
          </div>
          {veljaAldur && (
            <>
              <div className="veljaeiginleiks-kassi">
                <ÚtfillíngarTala 
                  texti="Hæsti aldur" 
                  gildi={hæstiAldur} 
                  breitaGildi={skikkaHæstaAldri}
                />
              </div>
              <div className="veljaeiginleiks-kassi">
                <ÚtfillíngarTala 
                  texti="Lægsti aldur" 
                  gildi={lægstiAldur} 
                  breitaGildi={skikkaLægstaAldri}
                />
              </div>
            </>
          )}
          <div className="veljaeiginleiks-velja">
            <TakkiAðgerð1 
              texti={veljaKin ? "Ókynskorðaður leikur" : "Kynskorðaður leikur"} 
              aðgerð={() => skikkaVeljaKin(firraGildi => !firraGildi)}
            />
          </div>
          {veljaKin && (
            <div className="veljaeiginleiks-kassi">
              <ÚtfillíngarMöguleikar 
                texti="Kyn" 
                gildi={kin} 
                breitaGildi={skikkaKini} 
                möguleikar={["Meyjaleikur \u2640", "Sveinaleikur \u2642"]}
              />
            </div>
          )}
        </>
      }

      {(tegundLeiks === "kappleikur") && 
        <>
          <div className="veljaeiginleiks-kassi">
            <ÚtfillíngarTala 
              texti="Mesti fjöldi í liði" 
              gildi={mestiFjöldiíLiði} 
              breitaGildi={skikkaMestaFjöldaíLiði}
            />
          </div>
        </>
      }

      <div className="veljaeiginleiks-kassi">
        <ÚtfillíngarMöguleikar 
          texti="Leikháttur leiks" 
          gildi={íþrótt} 
          breitaGildi={skikkaÍþrótt} 
          möguleikar={[...íþróttir, "Annað"]}
        />
      </div>
      {(völlur && völlur.árnaðarmaður && tími && tími.length === 2) && (
        <>
          {völlur.einkavöllur && <p className="veljaeiginleiks-velja">Verður að senda uppteknarbón á einkavöll</p>}
          <div className="veljaeiginleiks-kassi">
            <ÚtfillíngarHak 
              texti="Senda uppteknarbón" 
              gildi={(sendaUppteknarbón || völlur.einkavöllur)} 
              breitaGildi={skikkaSendaUppteknarbón}
              birtaSmeiju={skikkaSpurnUpptekni}
            />
          </div>
        </>
      )}
      {((!(staðurLeiks || varavöllur) && völlur)) && (
        <div className="veljaeiginleiks-velja">
          <TakkiAðgerð1 texti="Velja varavöll" aðgerð={() => skikkaVeljaVöllSmeiju(true)} />
        </div>
      )}
      {(völlur && (varavöllur || staðurLeiks)) && (
        <div className="umgjörð-lóðrétt-ómiðjað">
          {(varavöllur && (varavöllur.einki === völlur.einki)) ? (
            <p className="veljaeiginleiks-varavöllur-texti">Varavöllurinn getur ekki verið sá sami og aðalvöllurinn</p>
          ) : (
            <>
              <p className="veljaeiginleiks-varavöllur-texti">Varavöllur:</p>
              <p className="veljaeiginleiks-varavöllur-texti">{varavöllur ? `${varavöllur.nafn}${varavöllur.aukanafn ? ` ${varavöllur.aukanafn} ${varavöllur.stærð_undirvallar}` : ""}` : staðurLeiks}</p>
            </>  
          )}
          <div className="veljaeiginleiks-varavöllur-takkar">
            <div className="veljaeiginleiks-varavöllur-takkakassi">
              <TakkiAðgerð1 texti="Breyta varavelli" aðgerð={() => skikkaVeljaVöllSmeiju(true)} />
            </div>
            <div className="veljaeiginleiks-varavöllur-takkakassi">
              <TakkiAðgerð1 texti="Eyða varavelli" aðgerð={() => {
                if (völlur) {
                  skikkaVaravelli(null);
                  skikkaStaðLeiks("")
                }
              }} />
            </div>
          </div>
        </div>
      )} 

      {villa && <p className="veljaeiginleiks-firirsögn-smeiju villaoghleður-villa">{villa}</p>}
      <div className="veljaeiginleiks-staðfestaval-kassi">
        <TakkiAðgerð2 texti="Staðfesta val" aðgerð={breitaÖðrumEiginleikumLeiks}/>
      </div>

      {veljaVöllSmeija && (
        <Smeija lokaSmeiju={skikkaVeljaVöllSmeiju}>
          <VeljaVöll 
            skikkaVelli={skikkaVaravelli} 
            skikkaStaðLeiks={skikkaStaðLeiks} 
            tími={tími} 
            lokaSmeiju={skikkaVeljaVöllSmeiju} 
          />
        </Smeija>
      )}
      {veljaLiðLeiksSmeija && (
        <Smeija lokaSmeiju={skikkaVeljaLiðLeiksSmeiju}>
          <VeljaLið 
            veljaLiðAðgerð={(lið) => {
              skikkaLiðiLeiks(lið);
              skikkaVeljaLiðLeiksSmeiju(false);
            }}
          />
        </Smeija>
      )}
      <SpurnAðgeingiLeiks spurn={spurn} skikkaSpurn={skikkaSpurn}/>
      <SpurnLiðLeiks spurn={spurnLið} skikkaSpurn={skikkaSpurnLið}/>
      <SpurnUppteknarbón spurn={spurnUpptekni} skikkaSpurn={skikkaSpurnUpptekni}/>
    </section>
  )
}

export default VeljaAðraEiginleikaLeiks;