import React, { useState } from "react";
import "../leikjanautar.css";
import "../../../almenncss/villaoghleður.css";
import Smeija from "../../../hlutir/almennirhlutar/smeija/Smeija";
import SvaraLeikjanautabón from "../svaraleikjanautabón/SvaraLeikjanautabón";
import FlettíngarListiEfri from "../../../hlutir/almennirhlutar/flettíngartakkar/FlettíngarListiEfri.js"

const Leikjanautabónir = ({skikkaNíjumLeikjanautum, bónir}) => {
  const [leikjanautabónir, skikkaLeikjanautabónum] = useState([]);
  const [erNæsti, skikkaErNæsti] = useState(false);
  const [vísalisti, skikkaVísalista] = useState([0])
  const [villa, skikkaVillu] = useState(null);
  const [svaraLeikjanautabón, skikkaSvaraLeikjanautabón] = useState(null);
  
  //FlettíngarListiEfri til þess að hægt er að svara bón
  return (
    <div className="leikjanautar-listi" ref={bónir} id="leikjanautar-leikjanautabónir">
      <h3 className="leikjanautar-listi-firirsögn">Leikjanautabónir</h3>
      <div className="leikjanautar-listi-listi">
        <FlettíngarListiEfri
          veffáng="leikjanautar/leikjanautabonir?" 
          tegundLista="gutlarar" 
          breiddLista={3} 
          textiAðgerðarLista="Svara bón" 
          aðgerðLista={(gutlari) => {skikkaSvaraLeikjanautabón(gutlari)}}
          mestiFjöldiíLista={24}
          sækjaStrax={true}
          listi={leikjanautabónir}
          skikkaLista={skikkaLeikjanautabónum}
          erNæsti={erNæsti}
          skikkaErNæsti={skikkaErNæsti}
          birjaListaVísir={vísalisti}
          skikkaBirjaListaVísi={skikkaVísalista}
          villa={villa}
          skikkaVillu={skikkaVillu}
        />
      </div> 
      {svaraLeikjanautabón && (
        <Smeija lokaSmeiju={(gildi) => {
          if (!gildi) {
            skikkaSvaraLeikjanautabón(null);
          }
        }}>
          <SvaraLeikjanautabón
            svaraLeikjanautabón={svaraLeikjanautabón}
            skikkaLeikjanautabónum={skikkaLeikjanautabónum}
            skikkaSvaraLeikjanautabón={skikkaSvaraLeikjanautabón}
            skikkaNíjumLeikjanautum={skikkaNíjumLeikjanautum}
          />
        </Smeija>
      )}
    </div>
  )
}

export default Leikjanautabónir;