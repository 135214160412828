import React, { useEffect, useRef, useState } from "react";
import Takki1 from "../../hlutir/almennirhlutar/takkar/takki1/Takki1.js";
import "./leikjanautar.css";
import "../../almenncss/grunncss.css";
import NíjirLeikjanautar from "./níjirleikjanautar/NíjirLeikjanautar.js";
import Leikjanautabónir from "./leikjanautabónir/Leikjanautabónir.js";
import MínirLeikjanautar from "./mínirleikjanautar/MínirLeikjanautar.js";
import TakkiAðgerð1 from "../../hlutir/almennirhlutar/takkar/takkiAðgerð1/TakkiAðgerð1.js";


const Leikjanautar = () => {

  const [níjirLeikjanautar, skikkaNíjumLeikjanautum] = useState([]);
  const bónir = useRef();
  const níjir = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  //<span className="leikjanautar-minni-firirsögn">eikjan</span>
  return (
    <section className="leikjanautar-aðal">
        <div className="leikjanautar-heimtakki">
          <Takki1 til="/" texti="Heim"/>
        </div>
        <h1 className="leikjanautar-firirsögn">Leikjanautar</h1>
        <div className="leikjanautar-takkar">
          <TakkiAðgerð1 texti="Sjá leikjanautabónir" aðgerð={() => {
            bónir.current.scrollIntoView({behavior: "smooth"});
          }}/>
          <TakkiAðgerð1 texti="Finna nýja leikjanauta" aðgerð={() => níjir.current.scrollIntoView({behavior: "smooth"})}/>
        </div>
      <div className="leikjanautar-leikjanautalistar">
        <MínirLeikjanautar níjirLeikjanautar={níjirLeikjanautar}/>
        <Leikjanautabónir skikkaNíjumLeikjanautum={skikkaNíjumLeikjanautum} bónir={bónir}/>
        <NíjirLeikjanautar níjir={níjir}/>
      </div>
      
    </section>
  )
}

export default Leikjanautar;