import { createContext, useState, useEffect } from "react";



export const GutlariSamheingi = createContext();

export const GutlariSamheingiUmgjörð = ({children}) => {
  const [gutlari, skikkaGutlara] = useState(
    JSON.parse(localStorage.getItem("gutlari")) || {gutlaranafn: null}
  )
    

  useEffect(() => {
    localStorage.setItem("gutlari", JSON.stringify(gutlari));
    

  }, [gutlari.gutlaranafn]);

  return (
    <GutlariSamheingi.Provider value={{gutlari, skikkaGutlara}}>
      {children}
    </GutlariSamheingi.Provider>
  )
}
