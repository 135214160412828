import { useEffect, useState } from "react";
import Takki1 from "../../hlutir/almennirhlutar/takkar/takki1/Takki1";
import "./opnirleikir.css";
import miðlarabón from "../../miðlarabón.js"
import OpnirLeikirVal from "./opnirleikirval/OpnirLeikirVal";
import "../../almenncss/villaoghleður.css";
import "../../almenncss/grunncss.css";
import FlettíngarListiEfri from "../../hlutir/almennirhlutar/flettíngartakkar/FlettíngarListiEfri.js";
import { fáMiðlaravillu, fáÍþrótt } from "../../gjörníngar/ímsir.js";



const upphafsvísalisti = [[new Date(), 0]]

const OpnirLeikir = () => {
  const [leitarhleður, skikkaLeitarhleður] = useState(null);
  const [leitarvilla, skikkaLeitarvillu] = useState(null);
  
  const [vinstriSíðaVilla, skikkaVinstriSíðaVilla] = useState(null);
  const [hægriSíðaVilla, skikkaHægriSíðaVilla] = useState(null);

  const [opnirleikir, skikkaOpnumLeikjum] = useState(null);
  const [opnirErNæsti, skikkaOpnirErNæsti] = useState(false);
  const [opnirVísalisti, skikkaOpnirVísalisti] = useState(upphafsvísalisti);

  const [bláopnirleikir, skikkaBláopnumLeikjum] = useState(null);
  const [bláopnirErNæsti, skikkaBláopnirErNæsti] = useState(false);
  const [bláopnirVísalisti, skikkaBláopnirVísalisti] = useState(upphafsvísalisti);

  const [valinOpnun, skikkaValdriOpnun] = useState(null);
  const [valinSlóð, skikkaValdriSlóð] = useState("");

  const mestiFjöldiíLista = 24;
                      
  //Raða lista firir flettíngarlista
  function raðaLista(listi) {
     
    const fjöldiíLista = listi.length;
    const fullurListi = fjöldiíLista > mestiFjöldiíLista;
    const gildiNæsta = fullurListi;
    let nírVísalisti = upphafsvísalisti;
    if (fullurListi) {
      const næstiVísir = [listi[mestiFjöldiíLista - 1].tími_frá, listi[mestiFjöldiíLista - 1].einki]

      nírVísalisti = [upphafsvísalisti[0], næstiVísir];
      listi.pop();
    }
    return [listi, gildiNæsta, nírVísalisti];
  }

  async function sækjaOpnaLeiki(veljaAldur, neðriMörkAldurs, efriMörkAldurs, kin, tegundOpinn, leikháttur, efriMörkTíma, neðriMörkTíma, þorp, völlur) {
    skikkaLeitarvillu(null);
    skikkaLeitarhleður("Sækir leiki...");

    let valinNeðriMörkAldurs = null;
    let valinEfriMörkAldurs = null;
    if (veljaAldur) {
      if (neðriMörkAldurs > efriMörkAldurs) {
        skikkaLeitarvillu("Þú ert ekki að fara að finna leik með þessi aldursmörk, ég er alveg viss um það");
        skikkaLeitarhleður(null);
        return;
      }
      valinNeðriMörkAldurs = neðriMörkAldurs;
      valinEfriMörkAldurs = efriMörkAldurs;
    }

    if (efriMörkTíma && neðriMörkTíma && efriMörkTíma < neðriMörkTíma) {
      skikkaLeitarvillu("Ekkert vit í völdu tímabili");
      skikkaLeitarhleður(null);
      return;
    }

    let valiðKin = null;
    if (kin === "Sveinar") {
      valiðKin = 1;
    } else if  (kin === "Meyjur") {
      valiðKin = 2;
    }

    let valinOpnun = null;
    if (tegundOpinn === "Opinn") {
      valinOpnun = 2;
    } else if (tegundOpinn === "Bláopinn") {
      valinOpnun = 3;
    }

    let valinLeikháttur = fáÍþrótt(leikháttur);

    const slóð = `/leikir/gutlaraleikur/opnirleikir?efrialdur=${valinEfriMörkAldurs}&neðrialdur=${valinNeðriMörkAldurs}&kin=${valiðKin}&leikháttur=${valinLeikháttur}&efritími=${efriMörkTíma}&neðritími=${neðriMörkTíma}${þorp ? `&þorp=${þorp.gildi}` : ""}${völlur ? `&völlur=${völlur.einki}` : ""}`;

    try {
      const svarMiðlara = await miðlarabón.get(`${slóð}&opnun=${valinOpnun}&birjanaesta=${upphafsvísalisti[0]}`) ;
      if (svarMiðlara.status === 200) {
        //valinOpnun = 2 þíðir bara opnir leikir
        //valinOpnun = 3 þíðir bara bláopnir leikir
        let opnirleikirMiðlara;
        let bláopnirleikirMiðlara;
        if (valinOpnun === 2) {
          opnirleikirMiðlara = svarMiðlara.data;
        } else if (valinOpnun === 3) {
          bláopnirleikirMiðlara = svarMiðlara.data;
        } else {
          opnirleikirMiðlara = svarMiðlara.data.opnirleikir;
          bláopnirleikirMiðlara = svarMiðlara.data.bláopnirleikir;
        }

        if (!bláopnirleikirMiðlara || bláopnirleikirMiðlara.length < 1) {
          //ef eingir bláopnirleikir feingust, þá bara sína opna leiki
          valinOpnun = 2;
        } else if (!opnirleikirMiðlara || opnirleikirMiðlara.length < 1) {
          //ef eingir opnirleikir feingust, þá bara sína bláopna leiki
          valinOpnun = 3;
        } 

        //Raða lista firir flettíngarlista
        if (valinOpnun === 2) {
          const [listiOpnir, gildiNæstaOpnir, vísalistiOpnir] = raðaLista(opnirleikirMiðlara);
          skikkaOpnumLeikjum(listiOpnir);
          skikkaOpnirErNæsti(gildiNæstaOpnir);
          skikkaOpnirVísalisti(vísalistiOpnir);
          skikkaBláopnumLeikjum(null);
        } else if (valinOpnun === 3) {
          const [listiBláopnir, gildiNæstaBláopnir, vísalistiBláopnir] = raðaLista(bláopnirleikirMiðlara);
          skikkaBláopnumLeikjum(listiBláopnir);
          skikkaBláopnirErNæsti(gildiNæstaBláopnir);
          skikkaBláopnirVísalisti(vísalistiBláopnir);
          skikkaOpnumLeikjum(null);
        } else {
          const [listiOpnir, gildiNæstaOpnir, vísalistiOpnir] = raðaLista(opnirleikirMiðlara);
          const [listiBláopnir, gildiNæstaBláopnir, vísalistiBláopnir] = raðaLista(bláopnirleikirMiðlara);
          skikkaOpnumLeikjum(listiOpnir);
          skikkaOpnirErNæsti(gildiNæstaOpnir);
          skikkaOpnirVísalisti(vísalistiOpnir);
          skikkaBláopnumLeikjum(listiBláopnir);
          skikkaBláopnirErNæsti(gildiNæstaBláopnir);
          skikkaBláopnirVísalisti(vísalistiBláopnir);
        }

        skikkaValdriOpnun(valinOpnun);
        skikkaValdriSlóð(slóð);
      } 
    } catch (villaBónar) {
      skikkaLeitarvillu(fáMiðlaravillu(villaBónar));
      skikkaBláopnumLeikjum(null);
      skikkaOpnumLeikjum(null);
    }
    skikkaLeitarhleður(null);
  }

  async function sendaMemmbónÁLeik(leikur, skikkaSvariMiðlara, skikkaHleður, skikkaVillu) {
    skikkaSvariMiðlara(null);
    skikkaVillu(null);
    skikkaHleður("Sendir memmbón...");
    try {
      const svarMiðlara = await miðlarabón.post("/leikir/gutlaraleikur/sendamemmbon/" + leikur.einki);
      skikkaSvariMiðlara(svarMiðlara.data);
    } catch (villaBónar) {
      skikkaVillu(fáMiðlaravillu(villaBónar));
    }
    skikkaHleður(null)
  }

  async function memmaLeikBeintÁnBónar(leikur, skikkaSvariMiðlara, skikkaHleður, skikkaVillu) {
    skikkaSvariMiðlara(null);
    skikkaVillu(null);
    skikkaHleður("Memmar...");
    try {
      const svarMiðlara = await miðlarabón.post("/leikir/gutlaraleikur/memmaanbonar/" + leikur.einki);
      skikkaSvariMiðlara(svarMiðlara.data);
    } catch (villaBónar) {
      skikkaVillu(fáMiðlaravillu(villaBónar));
    }
    skikkaHleður(null);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])


  return (
    <section className="opnirleikir-aðal">
      <div className="almenn-heimtakki">
        <Takki1 texti="Heim" til="/"/>
      </div>
      <h1 className="opnirleikir-firirsögn">Opnir leikir</h1> 
      <div className="opnirleikir-umgjörð">
        <OpnirLeikirVal
          sækjaOpnaLeiki={sækjaOpnaLeiki}
          leitarhleður={leitarhleður}
        />
        <div className="opnirleikir-listarafleikjum">
          {leitarvilla && <p className="opnirleikir-firirsagnir villaoghleður-villa">{leitarvilla}</p>}
          {leitarhleður && <p className="opnirleikir-firirsagnir villaoghleður-hleður">{leitarhleður}</p>}
          {!(leitarvilla || leitarhleður) && (
            <>
              {(opnirleikir && opnirleikir.length > 0) && (
                <div className="opnirleikir-listi-umgjörð">
                  <p className={`opnirleikir-firirsagnir ${valinOpnun === 2 && "opnirleikir-firirsagnir-stærri"}`}>Opnir leikir</p>
                  <div className={valinOpnun === 2 ? "opnirleikir-stærrilisti" : "opnirleikir-minnilisti"}>
                    <FlettíngarListiEfri
                      veffáng={`${valinSlóð}&opnun=2&`}
                      tegundLista="leikur"
                      breiddLista={(valinOpnun === 2) ? 3 : 2}
                      textiAðgerðarLista="Senda memmbón"
                      aðgerðLista={sendaMemmbónÁLeik}
                      mestiFjöldiíLista={mestiFjöldiíLista}
                      stærð={(valinOpnun === 2) ? 1 : 0}
                      listi={opnirleikir}
                      skikkaLista={skikkaOpnumLeikjum}
                      birjaListaVísir={opnirVísalisti} 
                      skikkaBirjaListaVísi={skikkaOpnirVísalisti}
                      erNæsti={opnirErNæsti}
                      skikkaErNæsti={skikkaOpnirErNæsti}
                      villa={vinstriSíðaVilla}
                      skikkaVillu={skikkaVinstriSíðaVilla}
                      tími={true}
                    />
                  </div>
                </div>
              )}
              {(bláopnirleikir && bláopnirleikir.length > 0) && (
                <div className="opnirleikir-listi-umgjörð">
                  <p className={`opnirleikir-firirsagnir ${valinOpnun === 3 && "opnirleikir-firirsagnir-stærri"}`}>Bláopnir leikir</p>
                  <div className={valinOpnun === 3 ? "opnirleikir-stærrilisti" : "opnirleikir-minnilisti"}>
                    <FlettíngarListiEfri
                      veffáng={`${valinSlóð}&opnun=3&`}
                      tegundLista="leikur"
                      breiddLista={(valinOpnun === 3) ? 3 : 2}
                      textiAðgerðarLista="Memma leik beint"
                      aðgerðLista={memmaLeikBeintÁnBónar}
                      mestiFjöldiíLista={mestiFjöldiíLista}
                      stærð={(valinOpnun === 3) ? 1 : 0}
                      listi={bláopnirleikir}
                      skikkaLista={skikkaBláopnumLeikjum}
                      birjaListaVísir={bláopnirVísalisti} 
                      skikkaBirjaListaVísi={skikkaBláopnirVísalisti}
                      erNæsti={bláopnirErNæsti}
                      skikkaErNæsti={skikkaBláopnirErNæsti}
                      villa={hægriSíðaVilla}
                      skikkaVillu={skikkaHægriSíðaVilla}
                      tími={true}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </section>
  )
}

export default OpnirLeikir;