import "./árnaðarbandsmeija.css";
import "../../../../../almenncss/grunncss.css";
import "../../../../../hlutir/almennirhlutar/listi/leppurtímaskrá/smeijur/leikurtímaskrá/leikurtímaskrá.css";
import { fáDagLesmál, fáFráTilLesmál } from "../../../../../gjörníngar/fáTímaLesmál";
import { useEffect, useRef, useState } from "react";
import VilluHleðurTakki from "../../../../../hlutir/almennirhlutar/villaoghleður/villuhleðurtakki/VilluHleðurTakki";
import { fáMiðlaravillu } from "../../../../../gjörníngar/ímsir";
import miðlarabón from "../../../../../miðlarabón";
import { useParams } from "react-router-dom";
import SpjallÁrnaðarmaður from "../../../../../hlutir/almennirhlutar/spjallárnaðarmaður/SpjallÁrnaðarmaður";


//Texti aðgerðartakka miðað við fráteknistöðu vallar
export function fáTextaTakka(upptekni) {
  if (upptekni === "Uppteknarbón") return "Afturkalla bón";
  if (upptekni === "Upptekinn") return "Afturkalla upptekni";
  if (upptekni === "Skipaður") return "Senda uppteknarbón";
  return "";
}

//gildi tegundar leiks í gagnasafni
function fáTegundLeiks(tegund) {
  if (tegund === "gutlaraleikur") return 1;
  if (tegund === "kappleikur") return 2;
  return null;
}


const ÁrnaðarbandSmeija = ({leikur, tegundleiks, skikkaLeik}) => {

  /*
  
  nafn vallar
  tími

  upptekni



  
  */
  const [svar, skikkaSvari] = useState(null);
  const [hleður, skikkaHleður] = useState(null);
  const [villa, skikkaVillu] = useState(null);
  const breitaUpptekni = useRef(leikur.leikur.upptekni);
  const breittNíSkilaboð = useRef(leikur.leikur.ní_skilaboð);


  const {einkileiks} = useParams();
  const tegund = fáTegundLeiks(tegundleiks);

  async function eigaViðUpptekni(textiAðgerðar) {
    let slóð;
    let hleðurSkilaboð;
    let breittUpptekni;
    if (textiAðgerðar === "Afturkalla bón") {
      slóð = "afturkallabon";
      hleðurSkilaboð = "Afturkallar...";
      breittUpptekni = "Skipaður";
    } else if (textiAðgerðar === "Afturkalla upptekni") {
      slóð = "afturkallaupptekni";
      hleðurSkilaboð = "Afturkallar...";
      breittUpptekni = "Skipaður";
    } else if (textiAðgerðar === "Senda uppteknarbón") {
      slóð = "sendauppteknarbon";
      hleðurSkilaboð = "Sendir...";
      breittUpptekni = "Uppteknarbón";
    } else {
      return;
    }

    skikkaHleður(hleðurSkilaboð);
    skikkaVillu(null);

    try {
      const svarMiðlara = await miðlarabón.put(`timaskra/gutlari/${slóð}/${leikur.leikur.tímaskrá}`, {tegundleiks: tegund, einkileiks});
      breitaUpptekni.current = breittUpptekni;
      skikkaSvari(svarMiðlara.data);
    } catch (villaBónar) {
      skikkaVillu(fáMiðlaravillu(villaBónar))
    }
    skikkaHleður(null);

  }

  const tímiFrá = new Date(leikur.leikur.tími_frá); 
  const tímiTil = new Date(leikur.leikur.tími_til);

  const textiTakka = fáTextaTakka(leikur.leikur.upptekni);

  useEffect(() => {

    return () => {
      if (breitaUpptekni.current !== leikur.leikur.upptekni || breittNíSkilaboð.current !== leikur.leikur.ní_skilaboð) {

        skikkaLeik(firriLeikur => {
          const breitturLeikur = {...firriLeikur};
          breitturLeikur.leikur.upptekni = breitaUpptekni.current;
          breitturLeikur.leikur.ní_skilaboð = breittNíSkilaboð.current
          
          return breitturLeikur;
        })
      }
    }

  }, [])


  return (
    <section className="árnband-aðal umgjörð-lóðrétt-ómiðjað">
      <h3 className="leikurtímaskrá-firirsögn">{leikur.leikur.nafnvallar} {leikur.leikur.aukanafn} {leikur.leikur.stærð_undirvallar}</h3>
      {leikur.leikur.tími_frá && (
        <>
          <p className="leikurtímaskrá-tími">{fáDagLesmál(tímiFrá)}</p>
          <p className="leikurtímaskrá-tími">{fáFráTilLesmál(tímiFrá, tímiTil)}</p>
        </>
      )}
      {leikur.leikur.tímaskrá && leikur.leikur.árnaðarmaður && !leikur.leikur.einkavöllur && (
        <>
          <p className="leikurtímaskrá-gögn">Upptekni leiks: {breitaUpptekni.current}</p>
          <div className="leikurtímaskrá-takkar umgjörð-lóðrétt-ómiðjað">
            <VilluHleðurTakki
              svarMiðlara={svar}
              hleður={hleður}
              villa={villa}
              textiAðgerðar={textiTakka}
              aðgerð={() => eigaViðUpptekni(textiTakka)}
            />
          </div>
        </>
      )}
      <h5 className="leikurtímaskrá-gögn">Spjall við árnaðarmenn</h5>
      <p className="smeija-lítilltakki">Til að fá ný skilaboð þarf að fara úr spjalli og aftur inn í það</p>
      <SpjallÁrnaðarmaður
        einkileiks={einkileiks}
        breittNíSkilaboð={breittNíSkilaboð}
        tegundLeiks={tegund}
        sendaSlóð={`timaskra/gutlari/sendaskilabod/${einkileiks}`}
        sækjaSlóð={`timaskra/gutlari/saekjaskilabod/${einkileiks}?tegund=${tegund}&`}
      />
    </section>
  )
}


export default ÁrnaðarbandSmeija;