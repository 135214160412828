import React from "react"
import Takki1 from "../../../hlutir/almennirhlutar/takkar/takki1/Takki1";
import "./hægrihægriheim.css"
import "../../../almenncss/grunncss.css";

const HægriHægriHeim = () => {
  return (
    <section className="hhheim-aðal umgjörð-lóðrétt-ómiðjað">
      <div className="hhheim-takkakassi">
        <Takki1 texti="StiΠingar" til="/stillingar"/>
      </div>
      <div className="hhheim-takkakassi">
        <Takki1 texti="VeΠir" til="/vellir"/>
      </div>
      <div className="hhheim-takkakassi">
        <Takki1 texti="Opnir leikir" til="/opnirleikir"/>
      </div>
      <div className="hhheim-takkakassi">
        <Takki1 texti="Leikjanautar" til="/leikjanautar"/>
      </div>
      <div className="hhheim-takkakassi">
        <Takki1 texti="Um" til="/um"/>
      </div>
      <div className="hhheim-takkakassi">
        <Takki1 texti="Orðaforði" til="/ordafordi"/>
      </div>
      <div className="hhheim-takkakassi">
        <Takki1 texti="Hugmyndir" til="/hugmindir"/>
      </div>
    </section>
  )
}

export default HægriHægriHeim;