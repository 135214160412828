import React from "react"
import "./útfillíngarmöguleikar.css"
import "../../../../almenncss/grunncss.css"

/*

if (margmöguleika) {
  erValið = (val) => {
    return gildi.includes(val)
  }
  breitaGildiFall = (valkostur, ervalið) => {
    if (!ervalið) {
      breitaGildi(firriGildi => {
        return [...firriGildi, valkostur]
      })
    } else {
      breitaGildi(firriGildi => {
        return firriGildi.filter(gildíLista => {
          return gildíLista !== valkostur
        })
      })
    }
  }
}

*/

const ÚtfillíngarMöguleikar = ({ texti, gildi, breitaGildi, möguleikar, ósleppanlegt, birtaSmeiju }) => {
  
  function erValið(val) {
    return gildi === val;
  }

  function breitaGildiFall(val) {
    if (!ósleppanlegt && gildi === val) {
      breitaGildi("");
    } else {
      breitaGildi(val);
    }
  }
  
  return (
    <div className="hahúm-aðal">
      <p className="hahúm-lesmálvals">{texti} {birtaSmeiju && (<span onClick={() => birtaSmeiju(true)} className="spurn smellanlegur">?</span>)}</p>
      
      {möguleikar.map((valkostur, index) => {
        return (
          <label key={index} className="hahúm-inntakskassi">
            <input
              type="checkbox"
              name={valkostur}
              className="hahúm-inntak"
              checked={erValið(valkostur)}
              onChange={() => { breitaGildiFall(valkostur) }}
            />
            <div className="hahúm-hak"></div> 
            <p className="hahúm-lesmál">{valkostur}</p>
          </label>
        )
      })}
      
    </div>
  )
}

export default ÚtfillíngarMöguleikar;