export const vikudagar = ["Sunnudaginn", "Mánudaginn", "Þriðjudaginn", "Miðvikudaginn", "Fimmtudaginn", "Föstudaginn", "Laugardaginn"];

export const mánuðir = ["janúar", "febrúar", "mars", "apríl", "maí", "júní", "júlí", "ágúst", "september", "október", "nóvember", "desember"];

export const vikudagarÞágu = ["Sunnudegi", "Mánudegi", "Þriðjudegi", "Miðvikudegi", "Fimmtudegi", "Föstudegi", "Laugardegi"];

export const vikudagarÞáguFleir = ["Sunnudögum", "Mánudögum", "Þriðjudögum", "Miðvikudögum", "Fimmtudögum", "Föstudögum", "Laugardögum"];

export const bætaNúlliEfÞarf = (mín) => {
  if (mín === 0) {
    return "00"
  } else {
    return mín
  }
}

export function fáDagLesmál(dagur) {
  return `${vikudagar[dagur.getDay()]} ${dagur.getDate()}.${mánuðir[dagur.getMonth()]} ${dagur.getFullYear()}`;
}

export function fáFráTilLesmál(tímiFrá, tímiTil) {
  return `${tímiFrá.getHours()}:${bætaNúlliEfÞarf(tímiFrá.getMinutes())} til ${tímiTil.getHours()}:${bætaNúlliEfÞarf(tímiTil.getMinutes())}`
}

export function fáKlstMínLesmál(klstfrá, mínfrá, klsttil, míntil) {
  return `${klstfrá}:${bætaNúlliEfÞarf(mínfrá)} til ${klsttil}:${bætaNúlliEfÞarf(míntil)}`
}

export function fáDagLesmálÞágu(dagur) {
  return `${vikudagarÞágu[dagur.getDay()]} ${dagur.getDate()}.${mánuðir[dagur.getMonth()]} ${dagur.getFullYear()}`;
}

const fáTímaLesmál = (fyrriTími, seinniTími) => {
  let notaFirriTíma = fyrriTími;
  let notaSeinniTíma = seinniTími;
  if (Number.isInteger(fyrriTími) && Number.isInteger(seinniTími)) {  
    notaFirriTíma = new Date(fyrriTími);
    notaSeinniTíma = new Date(seinniTími);
  }

  const dagsetníngLeiks = `${vikudagar[notaFirriTíma.getDay()]} ${notaFirriTíma.getDate()}.${mánuðir[notaFirriTíma.getMonth()]} ${notaFirriTíma.getFullYear()}`;
  
  const tímiLeiks = `${notaFirriTíma.getHours()}:${bætaNúlliEfÞarf(notaFirriTíma.getMinutes())} - ${notaSeinniTíma.getHours()}:${bætaNúlliEfÞarf(notaSeinniTíma.getMinutes())}`;

  return [dagsetníngLeiks, tímiLeiks];
}

export default fáTímaLesmál;