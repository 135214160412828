import "../../almenncss/tölvuskeitissíður.css"
import "../../almenncss/grunncss.css"
import "../../almenncss/villaoghleður.css"

const Hleður = () => {

  return (
    <section className="almenn-grunnur tölvuskeitissíða-aðal">
      <p className="tölvuskeitissíða-stærritakki villaoghleður-hleður">
        Sækir síðu...
      </p>
    </section>
  ) 
}


export default Hleður;