import { useContext, useEffect, useState } from "react";
import VilluHleðurTakki from "../../hlutir/almennirhlutar/villaoghleður/villuhleðurtakki/VilluHleðurTakki";
import "../innskráníng/skráinn/skráinn.css";
import "../innskráníng/innskráníng.css";
import "../../almenncss/grunncss.css";
import Takki1 from "../../hlutir/almennirhlutar/takkar/takki1/Takki1";
import ÚtfillíngarTexti from "../../hlutir/almennirhlutar/útfillíngar/útfillíngartexti/ÚtfillíngarTexti.js"
import { fáMiðlaravillu } from "../../gjörníngar/ímsir.js";
import miðlarabón from "../../miðlarabón.js";
import {ÁrnaðarmaðurSamheingi} from "../../samheingi/árnaðarmaðurSamheingi.js";
import { useNavigate } from "react-router-dom";
import TakkiAðgerð1 from "../../hlutir/almennirhlutar/takkar/takkiAðgerð1/TakkiAðgerð1.js";
import { GutlariSamheingi } from "../../samheingi/gutlariSamheingi.js";

const ÁrnaðarInnskráníng = () => {
  const [netfáng, skikkaNetfángi] = useState("");
  const [likilorð, skikkaLikilorði] = useState("");
  const [villa, skikkaVillu] = useState(null);
  const [hleður, skikkaHleður] = useState(null);
  const [gleimtLikilorðNetfáng, skikkaGleimtLikilorðNetfáng] = useState("");
  const [gleimtSvarMiðlara, skikkaGleimtSvarMiðlara] = useState(null);
  const [gleimtHleður, skikkaGleimtHleður] = useState(null);
  const [gleimtVilla, skikkaGleimtVillu] = useState(null);  
  const [fillaInnGleimtLikilorð, skikkaFillaInnGleimtLikilorð] = useState(false);

  const {árnaðarmaður, skikkaÁrnaðarmanni} = useContext(ÁrnaðarmaðurSamheingi);
  const {skikkaGutlara} = useContext(GutlariSamheingi);
  const sendaÞángað = useNavigate();

  async function skráInn() {
    skikkaVillu(null);
    skikkaHleður("Innskráir...");
    const lágstafanetfáng = netfáng.toLowerCase();
    try {
      const svar = await miðlarabón.post("/arnadarmadur/innskraning", {
        netfáng: lágstafanetfáng,
        likilorð
      })
      skikkaGutlara({gutlaranafn: null});
      skikkaÁrnaðarmanni(svar.data);
      sendaÞángað("/arnadarmadur");
    } catch (villaMiðlara) {
      skikkaVillu(fáMiðlaravillu(villaMiðlara));
      skikkaHleður(null);
    }
  }

  async function gleimtLikilorð() {
    skikkaGleimtHleður("Sendir...");
    skikkaGleimtSvarMiðlara(null);
    skikkaGleimtVillu(null);
    const gleiminsNetfáng = gleimtLikilorðNetfáng.trim();
    if (gleiminsNetfáng.length < 3) {
      skikkaGleimtVillu("Netföng eru lengri en þetta");
      skikkaGleimtHleður(null);
      return;
    }
    try {
      const svarMiðlara = await miðlarabón.post("/arnadarmadur/gleimtlikilordsendapost", {netfáng: gleiminsNetfáng});
      
      skikkaGleimtSvarMiðlara(svarMiðlara.data);
    } catch (villaBónar) {
      skikkaGleimtVillu(fáMiðlaravillu(villaBónar));
    }
    skikkaGleimtHleður(null);
  }

  useEffect(() => {
    if (árnaðarmaður.netfáng) {
      sendaÞángað("/arnadarmadur");
    }
  }, [árnaðarmaður.netfáng])

  return (
    <main className="innskráníng-umgjörð umgjörð-lóðrétt-ómiðjað">
      <div className="innskráníng-umgjörð-takkar umgjörð-lárétt-ómiðjað">
        <div className="innskráníng-umgjörð-takki">
          <Takki1 texti="Gutlari" til="/innskraning"/>
        </div>
      </div>
      <section className="umgjörð-lóðrétt skráinn-aðal">
        <h2 className="skráinn-firirsögn">Skrá inn sem árnaðarmaður</h2>
        <form className="skráinn-inntök">
          <div className="skráinn-inntak-lesmál">
            <ÚtfillíngarTexti 
              texti="Netfang" 
              gildi={netfáng} 
              breitaGildi={skikkaNetfángi} 
              tegund="email"
              staðfestaTakki={skráInn}
            />
          </div>
          <div className="skráinn-inntak-lesmál">
            <ÚtfillíngarTexti 
              texti="Lykilorð" 
              gildi={likilorð} 
              breitaGildi={skikkaLikilorði} 
              tegund="password"
              staðfestaTakki={skráInn}
            />
          </div>
          <div className="umgjörð-lóðrétt skráinn-staðfestaogvilla">
            <div className="skráinn-staðfestaogvilla-takki">
              <VilluHleðurTakki
                villa={villa}
                hleður={hleður}
                aðgerð={skráInn}
                textiAðgerðar="Skrá inn!"
              />
            </div>
            {fillaInnGleimtLikilorð ? (
              <>
                <div className="skráinn-inntak-lesmál">
                  <ÚtfillíngarTexti 
                    texti="Skráðu netfang þitt til að geta endurheimt lykilorðið" 
                    gildi={gleimtLikilorðNetfáng} 
                    breitaGildi={skikkaGleimtLikilorðNetfáng} 
                    tegund="email"
                    staðfestaTakki={gleimtLikilorð}
                  />
                </div>
                <div className="skráinn-gleimtlikilorð-senda">
                  <VilluHleðurTakki
                    svarMiðlara={gleimtSvarMiðlara}
                    hleður={gleimtHleður}
                    villa={gleimtVilla}
                    aðgerð={gleimtLikilorð}
                    textiAðgerðar="Senda tölvupóst til að endurheimta lykilorð"
                  />
                </div>
              </>
            ) : (
              <>
                <div className="skráinn-gleimtlikilorð">
                  <TakkiAðgerð1 texti="jæja elliglöpin að elta mann, ég gleymdi lykilorðinu mínu..." aðgerð={() => skikkaFillaInnGleimtLikilorð(true)}/>
                </div>
                <p className="skráinn-gleimtlikilorð-senda">
                  Ef þú hefur völl sem þú vilt hafa umsjón yfir, sentu þá tölvupóst á madur@mannsgaman.is
                </p>
                <p className="skráinn-gleimtlikilorð-senda">
                  (Árnaðarmaður er umsjónarmaður vallar)
                </p>
              </>
            )}
          </div>
        </form>
      </section>  
    </main>
  )
}


export default ÁrnaðarInnskráníng;