import Smeija from "../smeija/Smeija"
import Takki2 from "../takkar/takki2/Takki2";
import "./tilkinníngarsmeija.css";


const TilkinníngarSmeija = ({skilaboð}) => {


  return (
    <Smeija>
      <div className="tilkinníngarsmeija-umgjörð">
        <h1 className="tilkinníngarsmeija-skilaboð">{skilaboð}</h1>
        <div className="tilkinníngarsmeija-takki">
          <Takki2 texti="Flotter" til="/"/>
        </div>
      </div>
    </Smeija>
  )
}

export default TilkinníngarSmeija;