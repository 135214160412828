import { useEffect, useState } from "react";
import "../../../../../almenncss/grunncss.css";
import "../../../../../almenncss/villaoghleður.css";

import "../../../tímaskrárfastur/tímaskrárfastur.css";
import miðlarabón from "../../../../../miðlarabón";
import { fáMiðlaravillu } from "../../../../../gjörníngar/ímsir";
import Listi from "../../../listi/Listi";


const LeikirFastur = ({fasturTími}) => {
  const [tímaskrár, skikkaTímaskrám] = useState([]);
  const [hleður, skikkaHleður] = useState(null);
  const [villa, skikkaVillu] = useState(null);

  useEffect(() => {
    async function náíTímaskráar() {
      const einki = fasturTími.aðaltími ? fasturTími.aðaltími : fasturTími.einki;
      skikkaHleður("Sækir leiki...");
      try {
        const náíGögn = await miðlarabón.get(`fastur/faleikifaststima/${einki}`);
        skikkaTímaskrám(náíGögn.data);
      } catch (villaBónar) {
        skikkaVillu(fáMiðlaravillu(villaBónar));
      }
      skikkaHleður(null);
    }

    if (!tímaskrár || tímaskrár.length < 1) {
      náíTímaskráar();
    }
  }, [])

  return (
    <section className="umgjörð-lóðrétt">
      <h4 className="smeija-aðaltakki">Leikir</h4>
      {hleður && <p className="smeija-takki villaoghleður-hleður">{hleður}</p>}
      {villa && <p className="smeija-takki villaoghleður-villa">{villa}</p>}
      <div className="smeija-listi">
        <Listi
          tegund="tímaskrá"
          breidd={3}
          listi={tímaskrár}
        />
      </div>
    </section>
  )
}

export default LeikirFastur;