import React from "react"
import "../takki1/takki1.css"

const TakkiAðgerð1 = ({ texti, aðgerð, litur, einginnbakgrunnur }) => {
  let cssnafn;
  if (!litur) {
    cssnafn = "takki1";
  } else {
    cssnafn = "takki1litur"
  }
  if (einginnbakgrunnur) {
    cssnafn = "takki1einginnbakgrunnur"
  }

  return (
    <button onClick={() => aðgerð()} className={cssnafn} type="button">{texti}</button>
  )
}

export default TakkiAðgerð1;