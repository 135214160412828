import "../../../almenncss/grunncss.css";
import Smeija from "../smeija/Smeija";

const SpurnAðgeingiLeiks = ({spurn, skikkaSpurn}) => {
  
  return (
    <>
      {spurn && (
        <Smeija lokaSmeiju={skikkaSpurn}>
          <p className="smeija-aðaltakki spurn-rammi">Luktur leikur: Leikur þar sem aðeins þeir sem eru boðnir geta memmað (ef leikur er takmarkaður við kyn eða aldur er aðeins hægt að bjóða þeim sem passa við þá takmörkun)</p>
          <p className="smeija-aðaltakki spurn-rammi">Opinn leikur: Leikur þar sem hver sem er getur sent bón um að fá að memma leik (ef leikur er takmarkaður við kyn eða aldur geta aðeins þeir sem passa við þá takmörkun sent memmbón)</p>
          <p className="smeija-aðaltakki spurn-rammi">Bláopinn leikur: Leikur þar sem hver sem er getur memmað leikinn beint (ef leikur er takmarkaður við kyn eða aldur geta aðeins þeir sem passa við þá takmörkun memmað beint)</p>
        </Smeija>
      )}
    </>
  )
}


export default SpurnAðgeingiLeiks;