import { useEffect, useState } from "react";
import { fáMiðlaravillu } from "../../gjörníngar/ímsir";
import miðlarabón from "../../miðlarabón";
import { useNavigate, useParams } from "react-router-dom";
import "../../almenncss/grunncss.css";
import "../../almenncss/villaoghleður.css";
import "../../almenncss/tölvuskeitissíður.css";
import "./svaraboði.css";
import Smeija from "../../hlutir/almennirhlutar/smeija/Smeija";
import Völlur from "../völlur/Völlur";
import { fáDagLesmál, fáFráTilLesmál } from "../../gjörníngar/fáTímaLesmál";
import TakkiAðgerð1 from "../../hlutir/almennirhlutar/takkar/takkiAðgerð1/TakkiAðgerð1";
import TakkiAðgerð2 from "../../hlutir/almennirhlutar/takkar/takkiAðgerð2/TakkiAðgerð2";
import SmíðaGutlara from "../innskráníng/smíðagutlara/SmíðaGutlara";




const SvaraBoði = () => {
  const [boð, skikkaBoði] = useState(null);
  const [hleður, skikkaHleður] = useState("Sækir boð...");
  const [villa, skikkaVillu] = useState(null);

  const [svar, skikkaSvari] = useState(null);
  const [svarHleður, skikkaSvarHleður] = useState(null);
  const [svarVilla, skikkaSvarVillu] = useState(null);

  const [sjáVöll, skikkaSjáVöll] = useState(false);

  const [búaTilAðgáng, skikkaBúaTilAðgáng] = useState(false);

  const {einkileiks, likill} = useParams();
  const sendaÞángað = useNavigate();

  useEffect(() => {

    async function náíBoð() {
      
      try {
        const svar = await miðlarabón.get(`/leikir/gutlaraleikur/fabod/${einkileiks}/${likill}`);
        skikkaBoði(svar.data);
      } catch (villaBónar) {
        skikkaVillu(fáMiðlaravillu(villaBónar));
      }
      skikkaHleður(null);
    }

    náíBoð();
  }, []);

  async function aðgerðLeikur(slóð, hleðurTexti, svarTexti) {
    skikkaSvarHleður(hleðurTexti);
    skikkaSvarVillu(null);
    try {
      await miðlarabón.put(`/leikir/gutlaraleikur/ogutlari/${slóð}/${einkileiks}/${likill}`)
      skikkaSvari(svarTexti);
    } catch (villaBónar) {
      skikkaSvarVillu(fáMiðlaravillu(villaBónar))
    }
    skikkaSvarHleður(null);
  }


  function fáStöðumanns(ástand) {
    if (ástand === "Jánkað") {
      return "Memmaður"
    }
    if (ástand === "Ósvarað") {
      return "Á eftir að svara boði"
    }
    if (ástand === "Sinjað") {
      return "Hefur synjað boði í leik"
    }
    return "";
  }



  return (
    <section className="svaraboði-grunnur tölvuskeitissíða-aðal">
      {boð ? (
        <div className="svaraboði-umgjörð umgjörð-lóðrétt">
          <p className="tölvuskeitissíða-minnitakki">{boð.völlur ? <span className="smellanlegur" onClick={() => skikkaSjáVöll(true)}>{boð.völlur}</span> : "Óákveðinn völlur"}</p>
          {(boð.tími_frá && boð.tími_til) ? (
            <>
              <p className="tölvuskeitissíða-minnitakki">{fáDagLesmál(new Date(boð.tími_frá))}</p>
              <p className="tölvuskeitissíða-minnitakki">{fáFráTilLesmál(new Date(boð.tími_frá), new Date(boð.tími_til))}</p>
            </>
          ) : (
            <p className="tölvuskeitissíða-minnitakki">Óákveðinn tími</p>
          )}
          <p className="tölvuskeitissíða-minnitakki">Skráningarnafn þitt: {boð.nafn}</p>
          <p className="tölvuskeitissíða-minnitakki">Staða mín í leik: {fáStöðumanns(boð.ástand)}</p>
          <div className="tölvuskeitissíða-minnitakki">
            <TakkiAðgerð1 texti="Fara á síðu leiks" aðgerð={() => sendaÞángað(`/sjaleik/${einkileiks}/${boð.likill}`)}/>
          </div>
          {svarVilla && <div className="tölvuskeitissíða-minnitakki villaoghleður-villa">{svarVilla}</div>}
          {!(svarHleður || svar) && (
            <>
              {boð.ástand === "Jánkað" && (
                <div className="tölvuskeitissíða-minnitakki">
                  <TakkiAðgerð2 texti="Skrá mig úr leik" aðgerð={() => aðgerðLeikur("skraur", "Skráir úr...", "Skráður úr leik")}/>
                </div>
              )}
              {boð.ástand === "Ósvarað" && (
                <>
                  <div className="tölvuskeitissíða-minnitakki">
                    <TakkiAðgerð2 texti="Samþykkja boð" aðgerð={() => aðgerðLeikur("memma", "Samþykkir...", "Boð samþykkt")}/>
                  </div>
                  <div className="tölvuskeitissíða-minnitakki">
                    <TakkiAðgerð2 texti="Synja boð" aðgerð={() => aðgerðLeikur("sinja", "Synjar...", "Boð synjað")}/>
                  </div>
                </>
              )}
              {boð.ástand === "Sinjað" && (
                <div className="tölvuskeitissíða-minnitakki">
                  <TakkiAðgerð2 texti="Gerast hluti af leik" aðgerð={() => aðgerðLeikur("memma", "Memmar...", "Leikur memmaður")}/>
                </div>
              )}
            </>
          )}
          {svar && <div className="tölvuskeitissíða-minnitakki villaoghleður-tókst">{svar}</div>}
          {svarHleður && <div className="tölvuskeitissíða-minnitakki villaoghleður-hleður">{svarHleður}</div>}
          {búaTilAðgáng ? (
            <SmíðaGutlara likill={likill}/>
          ) : (
            <div className="tölvuskeitissíða-minnitakki">
              <TakkiAðgerð1 texti="Búa til aðgang" aðgerð={() => skikkaBúaTilAðgáng(true)}/>
            </div>
          )}
        </div>
      ) : (
        <p className="tölvuskeitissíða-stærritakki villaoghleður-hleður">{hleður}</p>
      )}
      {sjáVöll && (
        <Smeija lokaSmeiju={skikkaSjáVöll}>
          <Völlur einkivallar={boð.einkivallar} lokasmeiju={skikkaSjáVöll}/>
        </Smeija>
      )}
    </section>
  )
}


export default SvaraBoði;