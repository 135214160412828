import "../../../almenncss/grunncss.css";
import fáDagsetnínguLesmál from "../../../gjörníngar/fáDagsetnínguLesmál";
import {bætaNúlliEfÞarf} from "../../../gjörníngar/fáTímaLesmál"
import TakkiAðgerð1 from "../takkar/takkiAðgerð1/TakkiAðgerð1";
import VeljaDag from "../veljatíma/veljadag/VeljaDag";
import "../veljatíma/veljatímasetníngar/veljatímasetníngar.css";
import { useState } from "react";

const VeljaEinnTíma = ({skikkaTíma, lokaSmeiju}) => {
  const [dagur, skikkaDeigi] = useState(null);

  function fáTímasetníngar() {
    const divTíma =[];
    for (let i = 0; i < 24; i++) {
      let litladiv = (
        <div className="umgjörð-lárétt-ómiðjað" key={i}>
          <div className={`veljatíma-tímabil veljatíma-veljanleigir`} onClick={() => eigaViðSmell(dagur, i, 0)}>{`${bætaNúlliEfÞarf(i)}:00`}</div>
          <div className={`veljatíma-tímabil veljatíma-veljanleigir`} onClick={() => eigaViðSmell(dagur, i, 10)}>{`${bætaNúlliEfÞarf(i)}:10`}</div>
          <div className={`veljatíma-tímabil veljatíma-veljanleigir`} onClick={() => eigaViðSmell(dagur, i, 20)}>{`${bætaNúlliEfÞarf(i)}:20`}</div>
          <div  className={`veljatíma-tímabil veljatíma-veljanleigir`} onClick={() => eigaViðSmell(dagur, i, 30)}>{`${bætaNúlliEfÞarf(i)}:30`}</div>
          <div className={`veljatíma-tímabil veljatíma-veljanleigir`} onClick={() => eigaViðSmell(dagur, i, 40)}>{`${bætaNúlliEfÞarf(i)}:40`}</div>
          <div className={`veljatíma-tímabil veljatíma-veljanleigir`} onClick={() => eigaViðSmell(dagur, i, 50)}>{`${bætaNúlliEfÞarf(i)}:50`}</div>
        </div>
      );
      divTíma.push(litladiv);
    }
    return divTíma;
  }

  function eigaViðSmell(dagur, klst, mín) {
    const árDags = dagur.getFullYear();
    const mánuðurDags = dagur.getMonth();
    const dagsetníngDags = dagur.getDate();
    const valinDagsetníng = new Date(árDags, mánuðurDags, dagsetníngDags, klst, mín);

    
    skikkaTíma(valinDagsetníng);
    lokaSmeiju(false);

  }

  return (
    <>
      {dagur ? (
        <div className="veljatíma-umgjörð umgjörð-lóðrétt-ómiðjað">
          <p className="veljatíma-dagsetníng-lesmál">
            {fáDagsetnínguLesmál(dagur)}
          </p>
          <div className="veljatíma-tímardags umgjörð-lóðrétt-ómiðjað">
            {fáTímasetníngar()}
          </div>
          <div className="veljatíma-breitaumdag">
            <TakkiAðgerð1 texti="Breyta dagsetningu" aðgerð={() => { skikkaDeigi(null) }} />
          </div>
        </div>
      ) : (
        <VeljaDag skikkaDeigi={skikkaDeigi}/>
      )}
    </>

  )
}


export default VeljaEinnTíma;