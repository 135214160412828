import "./forsíðutakkar.css";
import TakkiAðgerð1 from "../../../../hlutir/almennirhlutar/takkar/takkiAðgerð1/TakkiAðgerð1";
import miðlarabón from "../../../../miðlarabón";
import { fáMiðlaravillu } from "../../../../gjörníngar/ímsir";


const Kappleikjalistar = ({
  skikkaLista,
  sjáKvaðaLista,
  skikkaSjáKvaðaLista,
  lið,
  liðabónir,
  hleður
}) => {
  
  async function memmaLið(liðAðgerð, skikkaSvariMiðlaraAðgerð, skikkaHleðurAðgerð, skikkaVilluAðgerð) {
    skikkaHleðurAðgerð("Memmar...");
    skikkaSvariMiðlaraAðgerð(null);
    skikkaVilluAðgerð(null);
    //tókst er svo það er vitað kvort það þarf að fjarlæja lið úr listanum sem hann er í
    let tókst = false;
    try {
      const svar = await miðlarabón.put("/lid/memmamedbon/" + liðAðgerð.einki);
      if (svar.status === 200) {
        //bæta nímemmuðu liði við listi ivir lið
        lið.current.listi = [...lið.current.listi, liðAðgerð];
        tókst = true;
        skikkaSvariMiðlaraAðgerð(svar.data);
      }
    } catch (villaBónar) {
      skikkaVilluAðgerð(fáMiðlaravillu(villaBónar));
    }
    skikkaHleðurAðgerð(null);
    return tókst;
  }

  return (
    <>
      <div className="forsíðutakkar-leikjatakkar">
        <div className="forsíðutakkar-leikjatakkakassi">
          <TakkiAðgerð1 
            texti="Liðin mín" 
            aðgerð={() => {
              if (hleður) {
                return;
              }
              skikkaSjáKvaðaLista("lið");
              skikkaLista({
                firirsögn: "Liðin mín",
                veffáng: "lid/saekja/minlid?",
                tegundLista: "lið",
                breiddLista: 2,
                textiAðgerðarLista: null,
                aðgerðLista: null,
                leit: null,
                efri: lið,
                tími: false
              })
            }} 
            einginnbakgrunnur={sjáKvaðaLista === "lið"} 
            />
        </div>
        <div className="forsíðutakkar-leikjatakkakassi">
          <TakkiAðgerð1 
            texti="Boð í lið" 
            aðgerð={() => {
              if (hleður) {
                return;
              }
              skikkaSjáKvaðaLista("liðabónir");
              skikkaLista({
                firirsögn: "Boð í lið",
                veffáng: "lid/saekja/bonir?",
                tegundLista: "lið",
                breiddLista: 2,
                textiAðgerðarLista: "Memma lið",
                aðgerðLista: memmaLið,
                leit: null,
                efri: liðabónir,
                tími: false
              })
            }} 
            einginnbakgrunnur={sjáKvaðaLista === "liðabónir"}
            />
        </div>
      </div>
    </>
  )
}


export default Kappleikjalistar;