import "../../almenncss/tölvuskeitissíður.css"
import "../../almenncss/grunncss.css"

const Varasíða = () => {

  return (
    <section className="almenn-grunnur tölvuskeitissíða-aðal">
      <p className="tölvuskeitissíða-stærritakki">
        Þetta er ekki rétt síða..
      </p>
    </section>
  )
}


export default Varasíða;