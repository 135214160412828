import { useState } from "react";
import TakkiAðgerð1 from "../takkar/takkiAðgerð1/TakkiAðgerð1";
import "../veljatímafirirætlun/veljatímafirirætlun.css";
import Smeija from "../smeija/Smeija";
import VeljaDag from "../veljatíma/veljadag/VeljaDag";
import { fáDagLesmál } from "../../../gjörníngar/fáTímaLesmál";


const VeljaTímabil = ({
  dagurFrá,
  skikkaDagFrá,
  dagurTil,
  skikkaDagTil
}) => {
  const [veljaDagFrá, skikkaVeljaDagFrá] = useState(false);
  const [veljaDagTil, skikkaVeljaDagTil] = useState(false);

  function breitaDeigi(dagur) {
    if (veljaDagFrá) {
      skikkaDagFrá(dagur);
    } else if (veljaDagTil) {
      skikkaDagTil(dagur);
    }
  }

  return (
    <>
      <div className="veljatfirir-dagar">
        {dagurFrá ? (
          <p className="veljatfirir-dagur smellanlegur" onClick={() => skikkaDagFrá(null)}>{fáDagLesmál(dagurFrá)}</p>
        ) : (
          <TakkiAðgerð1 texti="Velja upphaf tímabils" aðgerð={() => skikkaVeljaDagFrá(true)}/>
        )}
        {(dagurFrá || dagurTil) && <p className="veljatfirir-dagurtil">Til og með</p>}
        {dagurTil ? (
          <p className="veljatfirir-dagur smellanlegur" onClick={() => skikkaDagTil(null)}>{fáDagLesmál(dagurTil)}</p>
        ) : (
          <TakkiAðgerð1 texti="Velja lok tímabils" aðgerð={() => skikkaVeljaDagTil(true)}/>
        )}
      </div>
      {(veljaDagFrá || veljaDagTil) && (
        <Smeija lokaSmeiju={(gildi) => {
          if (!gildi) {
            skikkaVeljaDagFrá(false);
            skikkaVeljaDagTil(false);
          }
        }}>
          <VeljaDag skikkaDeigi={breitaDeigi} loka={() => {
            skikkaVeljaDagFrá(false);
            skikkaVeljaDagTil(false);
          }}/>
        </Smeija>
      )}
    </>
  )
}

export default VeljaTímabil;