import { useContext, useEffect, useMemo, useRef, useState } from "react";
import TakkiAðgerð1 from "../takkar/takkiAðgerð1/TakkiAðgerð1";
import "./flettíngarlisti.css";
import Listi from "../listi/Listi";
import TakkiAðgerð2 from "../takkar/takkiAðgerð2/TakkiAðgerð2";
import ÚtfillíngarTexti from "../útfillíngar/útfillíngartexti/ÚtfillíngarTexti.js";
import "../../../almenncss/villaoghleður.css";
import sækjaFlettilista from "../../../gjörníngar/sækjaFlettilista.js";
import { GutlariSamheingi } from "../../../samheingi/gutlariSamheingi.js";


const FlettíngarListiMargir = ({
  veffáng, 
  tegundLista, 
  breiddLista, 
  textiAðgerðarLista,
  aðgerðLista,
  mestiFjöldiíLista,
  athugunLeitarorðs,
  leit,
  sækjaStrax,
  stærð,
  efri,
  tími,
  skikkaSækjandi,
}) => {
  const [listi, skikkaLista] = useState(efri.current.listi);
  const [birjaListaVísir, skikkaBirjaListaVísi] = useState(efri.current.birjaListaVísir);
  const [erNæsti, skikkaErNæsti] = useState(efri.current.erNæsti);
  const [villa, skikkaVillu] = useState(efri.current.villa);
  const [hleður, skikkaHleður] = useState(null);
  const leitarorð = useRef("");
  const firirLokin = useRef({
    listi: listi,
    erNæsti: erNæsti, 
    birjaListaVísir: birjaListaVísir,
    villa: villa
  });
  const {skikkaGutlara} = useContext(GutlariSamheingi);

  const takkarSínileigir = (birjaListaVísir.length > 1 || erNæsti);// && listi.length > 0;
  
  const {hæðListaCSS, aukastærð} = useMemo(() => {
    const aukastærð = stærð ? `-${stærð}` : "";
    
    let hæðListaCSS = "";
    if (takkarSínileigir && leit) {
      hæðListaCSS = "flettíngarlisti-listi-aukaleitogtakkar" + aukastærð;
    } else if (takkarSínileigir) {
      hæðListaCSS = "flettíngarlisti-listi-aukatakkar" + aukastærð;
    } else if (leit) {
      hæðListaCSS = "flettíngarlisti-listi-aukaleit" + aukastærð;
    }


    return {hæðListaCSS, aukastærð}
  }, [takkarSínileigir, stærð, leit])
  
  
  async function náíLista(notaLeitarorð, firri, næsti, vísalisti) {
    
    await sækjaFlettilista(
      notaLeitarorð, 
      firri, 
      næsti, 
      vísalisti, 
      skikkaVillu, 
      skikkaHleður, 
      athugunLeitarorðs,
      erNæsti,
      veffáng,
      mestiFjöldiíLista,
      skikkaBirjaListaVísi,
      skikkaErNæsti,
      skikkaLista,
      tími,
      false,
      firirLokin,
      skikkaSækjandi,
      skikkaGutlara,
    ) 
    
  }

  useEffect(() => {
    if (sækjaStrax && !efri.current.villa && (efri.current.listi.length < 1)) {
      náíLista(leitarorð.current, false, false, efri.current.birjaListaVísir);
    } else {
      //annars ef listi er gefinn þá nota hann
      firirLokin.current = {
        listi: efri.current.listi, 
        erNæsti: efri.current.erNæsti, 
        birjaListaVísir: efri.current.birjaListaVísir,
        villa: efri.current.villa
      };
      skikkaLista(efri.current.listi);
      skikkaErNæsti(efri.current.erNæsti);
      skikkaBirjaListaVísi(efri.current.birjaListaVísir);
      skikkaVillu(efri.current.villa);
    }

    return () => {
      //vista ástand lista þegar skift er í annan lista
      efri.current = {
        listi: firirLokin.current.listi,
        erNæsti: firirLokin.current.erNæsti,
        birjaListaVísir: firirLokin.current.birjaListaVísir,
        villa: firirLokin.current.villa
      };
    }
  }, [veffáng])

  async function aðgerðFirirLista(viðfáng, skikkaSvariMiðlaraAðgerð, skikkaHleðurAðgerð, skikkaVilluAðgerð) {
    if (!aðgerðLista) {
      return;
    }
    const tókst = await aðgerðLista(viðfáng, skikkaSvariMiðlaraAðgerð, skikkaHleðurAðgerð, skikkaVilluAðgerð);
    if (!tókst) {
      return;
    }

    //Þannig ef eikvað á ekki leingur að vera í lista þá er það fjarlægt
    let nírListi;
    if (viðfáng.raðeinki) {
      //firir gutlara í gutlaraleik
      const einkiViðfángs = viðfáng.raðeinki;
      nírListi = firirLokin.current.listi.filter(stakíLista => {
        return stakíLista.raðeinki !== einkiViðfángs;
      })
    } else {
      const einkiViðfángs = viðfáng.einki;
      nírListi = firirLokin.current.listi.filter(stakíLista => {
        return stakíLista.einki !== einkiViðfángs;
      })
    }

    firirLokin.current = {
      listi: nírListi,
      erNæsti: firirLokin.current.erNæsti, 
      birjaListaVísir: firirLokin.current.birjaListaVísir,
      villa: firirLokin.current.villa
    }
  }


  return (
    <section className="flettíngarlisti-aðal">
      {leit && (
        <>
          <div className={`flettíngarlisti-leit-umgjörð${aukastærð}`}>
            <ÚtfillíngarTexti 
              texti="" 
              gildi={leitarorð.current} 
              breitaGildi={(gildi) => {
                leitarorð.current = gildi
              }}
              staðfestaTakki={() => {
                náíLista(leitarorð.current, false, false, birjaListaVísir);
              }}
            />
          </div>
          <div className={`flettíngarlisti-leit-takki${aukastærð}`}>
            <TakkiAðgerð2 texti="Leita" aðgerð={() => {
              náíLista(leitarorð.current, false, false, birjaListaVísir);
            }}/>
          </div>
        </>
      )}
      {(villa || hleður) ? (
        <>
          {villa && <p className="flettíngarlisti-skilaboðmiðlara villaoghleður-villa">{villa}</p>}
          {hleður && <p className="flettíngarlisti-skilaboðmiðlara villaoghleður-hleður">{hleður}</p>}
        </>
      ) : (
        <>
          <div className={`flettíngarlisti-listi ${hæðListaCSS}`}>
            <Listi
              tegund={tegundLista}
              breidd={breiddLista}
              listi={listi}
              textiAðgerðar={textiAðgerðarLista} 
              aðgerð={aðgerðFirirLista}
            />
          </div>
          {takkarSínileigir && (
            <div className={`flettíngarlisti-takkar${aukastærð}`}>
              {((birjaListaVísir.length > 1 && !erNæsti) || birjaListaVísir.length > 2) && <TakkiAðgerð1 texti="Bakfletta" aðgerð={() => náíLista(leitarorð.current, true, false, birjaListaVísir)}/>}
              {erNæsti && <TakkiAðgerð1 texti="Fletta" aðgerð={() => náíLista(leitarorð.current, false, true, birjaListaVísir)}/>}
            </div>
          )}
        </>
      )}
    </section>
  )
}

export default FlettíngarListiMargir;