import "../veljavöll.css";
import "./leitveljavöll.css";
import "../../../../almenncss/grunncss.css";
import ÚtfillíngarTexti from "../../../almennirhlutar/útfillíngar/útfillíngartexti/ÚtfillíngarTexti";
import ÚtfillíngarMöguleikar from "../../../almennirhlutar/útfillíngar/útfillíngarmöguleikar/ÚtfillíngarMöguleikar";
import ÚtfillíngarHak from "../../../almennirhlutar/útfillíngar/útfillíngarhak/ÚtfillíngarHak.js"
import TakkiAðgerð2 from "../../takkar/takkiAðgerð2/TakkiAðgerð2";
import { lazy, Suspense, useState } from "react";
import Smeija from "../../smeija/Smeija.js"
import TakkiAðgerð1 from "../../takkar/takkiAðgerð1/TakkiAðgerð1.js";
import { íþróttir } from "../../../../almenngögn.js";
import SpurnStærðValla from "../../spurnsmeijur/SpurnStærðValla.js";
import SmeijaHleður from "../../smeijahleður/SmeijaHleður.js";
const VeljaÞorp = lazy(() => import("../../veljaþorp/VeljaÞorp.js"));


const LeitVeljaVöll = ({sækjaVelli, tími}) => {
  const [leitarorð, skikkaLeitarorði] = useState("");
  const [tegund, skikkaTegund] = useState("");
  const [undirlag, skikkaUndirlagi] = useState("");
  const [stærð, skikkaStærð] = useState("");
  const [inniúti, skikkaInniúti] = useState("");
  const [íþrótt, skikkaÍþrótt] = useState("");
  const [þorp, skikkaÞorpi] = useState(null);
  const [veljaÞorp, skikkaVeljaÞorp] = useState(false);
  const [undirvellir, skikkaUndirvellir] = useState(false);
  const [einkavöllur, skikkaEinkavelli] = useState(false);

  const [spurn, skikkaSpurn] = useState(false);

  const meðEðaÁnTíma = (tími && tími.length) ? "medtima" : "antima";


  return (
    <form className="leitveljavöll-valmöguleikar">
      <p className="leitveljavöll-leit-firirsögn">Leit</p>
      {/* <p className="leitveljavöll-texti">(Verið er að bæta völlum)</p> */}
      <div className="leitveljavöll-valmöguleikar-listi">
        <div className="leitveljavöll-leit-dálkur">
          <div className="leitveljavöll-leit-nafn">
            <ÚtfillíngarTexti texti="Velja nafn vallar" gildi={leitarorð} breitaGildi={skikkaLeitarorði} staðfestaTakki={() => {
              sækjaVelli({
                leitarorð,
                tegund,
                undirlag,
                inniúti,
                stærð,
                íþrótt,
                meðEðaÁnTíma,
                þorp: þorp ? þorp.gildi : null,
                undirvellir,
                einkavöllur
              });
            }} />
          </div>
        </div>
        <div className="leitveljavöll-leit-dálkur">
          {þorp ? (
            <>
              <p className="leitveljavöll-þorp-valið">Valið nágrenni:</p>
              <p className="leitveljavöll-þorp-nafn">{þorp.nafn}</p>
              <div className="leitveljavöll-þorp-takki">
                <TakkiAðgerð1 texti="Velja annað" aðgerð={() => skikkaVeljaÞorp(true)}/>
              </div>
              <div className="leitveljavöll-þorp-takki">
                <TakkiAðgerð1 texti="Afvelja" aðgerð={() => skikkaÞorpi(null)}/>
              </div>
            </>
          ) : (
            <div className="leitveljavöll-þorp-takki">
              <TakkiAðgerð1 texti="Velja nágrenni" aðgerð={() => skikkaVeljaÞorp(true)}/>
            </div>
          )}
        </div>
        <div className="leitveljavöll-leit-dálkur">
          <ÚtfillíngarHak 
            texti="Undirvellir" 
            gildi={undirvellir} 
            breitaGildi={skikkaUndirvellir}
          />
        </div>
        <div className="leitveljavöll-leit-dálkur">
          <ÚtfillíngarMöguleikar texti="Velja tegund vallar" gildi={tegund} breitaGildi={skikkaTegund} möguleikar={["Skólavöllur", "Hverfisvöllur", "Félagsvöllur", "Annað"]} />
        </div>
        <div className="leitveljavöll-leit-dálkur">
          <ÚtfillíngarHak 
            texti="Einkavöllur" 
            gildi={einkavöllur} 
            breitaGildi={skikkaEinkavelli}
          />
        </div>
        <div className="leitveljavöll-leit-dálkur">
          <ÚtfillíngarMöguleikar texti="Velja undirlag vallar" gildi={undirlag} breitaGildi={skikkaUndirlagi} möguleikar={["Gras", "Gervigras", "Malbik", "Möl", "Fjalir", "Gólfdúkur", "Rauðdregill", "Vallargrindur", "Vatn", "Sandur", "Pallar", "Annað"]} />
        </div>
        <div className="leitveljavöll-leit-dálkur">
          <ÚtfillíngarMöguleikar texti="Velja stærð vallar" gildi={stærð} breitaGildi={skikkaStærð} möguleikar={["Stubblingur", "Dverglingur", "Þokkalingur", "Snotrungur", "Drjúgungur", "Bosmungur", "Fullstór"]} birtaSmeiju={skikkaSpurn} />
        </div>
        <div className="leitveljavöll-leit-dálkur">
          <ÚtfillíngarMöguleikar texti="Velja leikhátt" gildi={íþrótt} breitaGildi={skikkaÍþrótt} möguleikar={íþróttir} />
        </div>
        <div className="leitveljavöll-leit-dálkur">
          <ÚtfillíngarMöguleikar texti="Velja umhverfi" gildi={inniúti} breitaGildi={skikkaInniúti} möguleikar={["Úti", "Inni"]} />
        </div>
        
      </div>
      <div className="leitveljavöll-leitafvellival-kassi">
        <TakkiAðgerð2 texti="Leita" aðgerð={(v) => {
          v.preventDefault();
          sækjaVelli({
            leitarorð,
            tegund,
            undirlag,
            inniúti,
            stærð,
            íþrótt,
            meðEðaÁnTíma,
            þorp: þorp ? þorp.gildi : null,
            undirvellir,
            einkavöllur
          });
        }} />
      </div>
      {veljaÞorp && (
        <Smeija lokaSmeiju={skikkaVeljaÞorp}>
          <Suspense fallback={<SmeijaHleður texti="nágrenni"/>}>
            <VeljaÞorp skikkaÞorpi={skikkaÞorpi} lokaSmeiju={skikkaVeljaÞorp}/>
          </Suspense>
        </Smeija>
      )}
      <SpurnStærðValla spurn={spurn} skikkaSpurn={skikkaSpurn}/>
    </form>
    

  )
}


export default LeitVeljaVöll;