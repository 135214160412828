import React, { useState } from "react"
import Smeija from "../../smeija/Smeija";
import TakkiAðgerð2 from "../../takkar/takkiAðgerð2/TakkiAðgerð2";
import "./leppurveljavöll.css"
import "../../../../almenncss/grunncss.css";
import Völlur from "../../../../síðurnar/völlur/Völlur";
import slóðminda from "../../../../slóðminda";

const LeppurVeljaVöll = ({ völlur, textiAðgerðar, aðgerð }) => {
  const [smeija, skikkaSmeiju] = useState(false);

  return (
    <div className="leppurvöllur-aðal umgjörð-lóðrétt">
      <div className="leppurvöllur-nafnogmynd umgjörð-lóðrétt smellanlegur" onClick={() => {skikkaSmeiju(true)}}>
        <p className="leppurvöllur-nafnvallar">{völlur.nafn}</p>
        {völlur.aukanafn && <p className="leppurvöllur-aukanafn">{völlur.aukanafn} {völlur.stærð_undirvallar}</p>}
        <img src={`${slóðminda}vallamindir/${völlur.mind1}`} alt="mynd af velli" className="leppurvöllur-myndafvelli" />
      </div>
      {textiAðgerðar && <div className="leppurvöllur-takkakassi">
        <TakkiAðgerð2 texti={textiAðgerðar} aðgerð={() => aðgerð(völlur)}/>
      </div>}
      {smeija && (
        <Smeija lokaSmeiju={skikkaSmeiju}>
          <Völlur einkivallar={völlur.einki} lokasmeiju={skikkaSmeiju}/>
        </Smeija>
      )}
    </div>
  )
}

export default LeppurVeljaVöll;