import { useEffect, useState } from "react";
import "./veljatímafirirætlun.css";
import Smeija from "../smeija/Smeija";
import TakkiAðgerð1 from "../takkar/takkiAðgerð1/TakkiAðgerð1";
import { fáFráTilLesmál } from "../../../gjörníngar/fáTímaLesmál";
import VeljaTímasetníngar from "../veljatíma/veljatímasetníngar/VeljaTímasetníngar";
import VeljaTímabil from "../veljatímabil/VeljaTímabil";

const vikudagar = ["Sunnudögum", "Mánudögum", "Þriðjudögum", "Miðvikudögum", "Fimmtudögum", "Föstudögum", "Laugardögum"];

const íDag = new Date();
const dagur = new Date(íDag.getFullYear(), íDag.getMonth(), íDag.getDate());

function fáMánaðardaga(veljaTexta, veljaDag, loka, veljaSeinni) {
  const mánaðardagar = [];
  const texti = ". hvers mánaðar"
  for (let i = 1; i < 32; i++) {
    mánaðardagar.push(
      <TakkiAðgerð1 key={i} texti={i + texti} aðgerð={() => {
        veljaDag(i);
        veljaTexta(i + texti);
        veljaSeinni(((i % 31) + 1) + texti);
        loka(false);
      }}/>
    )
  }
  return mánaðardagar;
}


const VeljaTímaFirirætlun = ({
  tegundTímabils,
  skikkaTegundTímabils,
  dagurFrá,
  skikkaDagFrá,
  dagurTil,
  skikkaDagTil,
  skikkaDeijiTímabils,
  skikkaTíma
}) => {
  const [veljaDagTímabils, skikkaVeljaDagTímabils] = useState(false);
  const [valinnDagurTímabils, skikkaVöldumDeijiTímabils] = useState("");
  const [seinniDagur, skikkaSeinniDagur] = useState("");
  const [veljaTíma, skikkaVeljaTíma] = useState(false);
  const [valinnTími, skikkaVöldumTíma] = useState([]);

  const erTími = valinnTími && valinnTími.length === 2;

  useEffect(() => {
    if (erTími) {
      //þannig aðeins er uppfært tíma þegar búið er að velja hann alveg
      const firriDagsetníng = new Date(valinnTími[0]);
      const seinniDagsetníng = new Date(valinnTími[1]);
      const erMorgundagur = firriDagsetníng.getDate() !== seinniDagsetníng.getDate();
      const firriTími = {klst: firriDagsetníng.getHours(), mín: firriDagsetníng.getMinutes()};
      const seinniTími = {klst: seinniDagsetníng.getHours(), mín: seinniDagsetníng.getMinutes(), morgundagur: erMorgundagur};

      skikkaTíma([firriTími, seinniTími]);
    } else {
      skikkaTíma([]);
    }
  }, [erTími])

  return (
    <>
      <h5 className="smeija-aðaltakki">{tegundTímabils ? "Tegund tímabils" : "Velja tegund tímabils"}</h5>
      {tegundTímabils ? (
        <>
          <p className="smeija-aðaltakki">{tegundTímabils}</p>
          <div className="smeija-takki">
            <TakkiAðgerð1 texti="Velja annað" aðgerð={() => {
              skikkaTegundTímabils("");
              skikkaVöldumDeijiTímabils("");
              skikkaDeijiTímabils(null);
              skikkaTíma([]);
              skikkaVöldumTíma([]);
              skikkaVeljaTíma(false);
            }}/>
          </div>
          {valinnDagurTímabils ? (
            <>
              <p className="smeija-aðaltakki">{valinnDagurTímabils}</p>
              <div className="smeija-takki">
                <TakkiAðgerð1 texti="Velja annan" aðgerð={() => {
                  skikkaVeljaDagTímabils(true);
                  skikkaVöldumTíma([]);
                  skikkaTíma([]);
                  skikkaVeljaTíma(false);
                }}/>
              </div>
              {erTími ? (
                <>
                  <p className="smeija-aðaltakki">{fáFráTilLesmál(new Date(valinnTími[0]), new Date(valinnTími[1]))}</p>
                  <div className="smeija-takki">
                    <TakkiAðgerð1 texti="Velja annan" aðgerð={() => {
                      skikkaVeljaTíma(true);
                      skikkaTíma([]);
                      skikkaVöldumTíma([]);
                    }}/>
                  </div>
                </>
              ) : (
                <>
                  <h5 className="smeija-aðaltakki">Velja tíma</h5>
                  <div className="smeija-takki">
                    <TakkiAðgerð1 texti="Velja tíma" aðgerð={() => skikkaVeljaTíma(true)}/>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <h5 className="smeija-aðaltakki">Velja dag tímabils</h5>
              <div className="smeija-takki">
                <TakkiAðgerð1 texti="Velja dag" aðgerð={() => skikkaVeljaDagTímabils(true)}/>
              </div>
            </>
          )}
        </>
      ) : (
        <div className="veljatfirir-tímabiltakkar">
          <TakkiAðgerð1 texti="Vikulega" aðgerð={() => skikkaTegundTímabils("Vikulega")}/>
          <TakkiAðgerð1 texti="Mánaðarlega" aðgerð={() => skikkaTegundTímabils("Mánaðarlega")}/>
        </div>
      )}
      <h5 className="smeija-aðaltakki">Velja hvenær fyrirætlun er í gildi</h5>
      <VeljaTímabil
        dagurFrá={dagurFrá}
        skikkaDagFrá={skikkaDagFrá}
        dagurTil={dagurTil}
        skikkaDagTil={skikkaDagTil}
      />
      {veljaDagTímabils && (
        <Smeija lokaSmeiju={skikkaVeljaDagTímabils}>
          {tegundTímabils === "Vikulega" && (
            <>
            <h5 className="smeija-firirsögn">Velja vikudag</h5>
            <div className="veljatfirir-tímabilsdagar umgjörð-lóðrétt">
              {vikudagar.map((vikudagur, staður) => {
                return <TakkiAðgerð1 key={staður} texti={vikudagur} aðgerð={() => {
                  skikkaVöldumDeijiTímabils(vikudagur);
                  skikkaSeinniDagur(vikudagar[(staður + 1) % 7]);
                  skikkaDeijiTímabils(staður + 1);
                  skikkaVeljaDagTímabils(false);
                }}/>
              })}
            </div>
            </>
          )}
          {tegundTímabils === "Mánaðarlega" && (
            <>
              <h5 className="smeija-firirsögn">Velja mánaðardag</h5>
              <div className="veljatfirir-tímabilsdagar umgjörð-lóðrétt">
                {fáMánaðardaga(skikkaVöldumDeijiTímabils, skikkaDeijiTímabils, skikkaVeljaDagTímabils, skikkaSeinniDagur)}
              </div>
            </>
          )}  
        </Smeija>
      )}
      {(veljaTíma && !erTími) && (
        <Smeija lokaSmeiju={skikkaVeljaTíma}>
          <VeljaTímasetníngar
            dagur={dagur}
            skikkaVöldumTíma={skikkaVöldumTíma}
            valinnTími={valinnTími}
            tímabilsdagar={[valinnDagurTímabils, seinniDagur]}
          />
        </Smeija>
      )}
    </>
  )
}

export default VeljaTímaFirirætlun;