import { useEffect, useRef, useState } from "react";
import "./firirætlun.css";
import "../../../../hlutir/almennirhlutar/listi/leppurtímaskrá/smeijur/leikurtímaskrá/leikurtímaskrá.css";
import "../../../árnaðarmaður/búatilótækan/búatilótækantíma/búatilótækantíma.css";
import miðlarabón from "../../../../miðlarabón";
import { fáMiðlaravillu } from "../../../../gjörníngar/ímsir";
import { fáDagLesmál, fáKlstMínLesmál, fáDagLesmálÞágu, vikudagarÞáguFleir } from "../../../../gjörníngar/fáTímaLesmál";
import Listi from "../../../../hlutir/almennirhlutar/listi/Listi.js";
import TakkiAðgerð1 from "../../../../hlutir/almennirhlutar/takkar/takkiAðgerð1/TakkiAðgerð1.js";
import { useNavigate } from "react-router-dom";
import VilluHleðurTakki from "../../../../hlutir/almennirhlutar/villaoghleður/villuhleðurtakki/VilluHleðurTakki.js";
import FlettíngarListiEfri from "../../../../hlutir/almennirhlutar/flettíngartakkar/FlettíngarListiEfri.js";
import Smeija from "../../../../hlutir/almennirhlutar/smeija/Smeija.js";
import Völlur from "../../../völlur/Völlur.js";
import EiginleikarFirirætlunar from "../eiginleikarfirirætlunar/EiginleikarFirirætlunar.js";
import TilkinníngarSmeija from "../../../../hlutir/almennirhlutar/tilkinníngarsmeija/TilkinníngarSmeija.js";
import { fáTextaTakka } from "../../../gutlaraleikur/miðjagutlaraleiks/hiitogþettagutlaraleikur/árnaðarbandsmeija/ÁrnaðarbandSmeija.js";
import Tímaval from "../../../../hlutir/almennirhlutar/tímaval/Tímaval.js";
import { sólarhríngur } from "../../../../almenngögn.js";
import SpjallÁrnaðarmaður from "../../../../hlutir/almennirhlutar/spjallárnaðarmaður/SpjallÁrnaðarmaður.js";


const Firirætlun = ({einkiFirirætlunar}) => {
  const [firirætlun, skikkaFirirætlun] = useState(null);
  const [hleður, skikkaHleður] = useState("Sækir firirætlun...");
  const [villa, skikkaVillu] = useState(null);

  const [sjáEiginleika, skikkaSjáEiginleika] = useState(false);

  const [svarUpptekni, skikkaSvariUpptekni] = useState(null);
  const [hleðurUpptekni, skikkaHleðurUpptekni] = useState(null);
  const [villaUpptekni, skikkaVilluUpptekni] = useState(null);

  const [sjáTímaskrár, skikkaSjáTímaskrár] = useState(true);
  const [breitt, skikkaBreitt] = useState(false);
  const [eiðaTímaskrá, skikkaEiðaTímaskrá] = useState(false);

  const [hleðurBúaTil, skikkaHleðurBúaTil] = useState(null);
  const [villaBúaTil, skikkaVilluBúaTil] = useState(null);

  const [sótturListi, skikkaSóttumLista] = useState([]);
  const [erNæsti, skikkaErNæsti] = useState(false);
  const [vísalisti, skikkaVísalista] = useState([[new Date(), 0]]);
  const [villaListi, skikkaVilluLista] = useState(null);

  const [bætaViðTímaskrá, skikkaBætaViðTímaskrá] = useState(false);
  const [tími, skikkaTíma] = useState([]);
  const [svarBæta, skikkaSvariBæta] = useState(null);
  const [hleðurBæta, skikkaHleðurBæta] = useState(null);
  const [villaBæta, skikkaVilluBæta] = useState(null);

  const [smeijavallar,skikkaSmeijuvallar] = useState(false);

  const [firirætlunEitt, skikkaFirirætlunEitt] = useState(false);

  const breitaUpptekni = useRef(null);


  const sendaÞángað = useNavigate();


  useEffect(() => {

    async function sækjaFirirætlun() {
      try {
        const svarMiðlara = await miðlarabón.get(`/firiraetlanir/saekja/${einkiFirirætlunar}`);
        breitaUpptekni.current = svarMiðlara.data.upptekni;
        skikkaFirirætlun(svarMiðlara.data);
        skikkaBreitt(false);
      } catch (villaBónar) {
        skikkaVillu(fáMiðlaravillu(villaBónar));
      }
      skikkaHleður(null);
    }

    if (!firirætlun) {
      sækjaFirirætlun();
    }

  }, [einkiFirirætlunar, breitt])

  const textiTakka = fáTextaTakka(breitaUpptekni.current);

  async function eigaViðUpptekni(textiAðgerðar) {
    let slóð;
    let hleðurSkilaboð;
    let breittUpptekni;
    if (textiAðgerðar === "Afturkalla bón") {
      slóð = "afturkallabon";
      hleðurSkilaboð = "Afturkallar...";
      breittUpptekni = "Skipaður";
    } else if (textiAðgerðar === "Afturkalla upptekni") {
      slóð = "afturkallaupptekni";
      hleðurSkilaboð = "Afturkallar...";
      breittUpptekni = "Skipaður";
    } else if (textiAðgerðar === "Senda uppteknarbón") {
      slóð = "sendauppteknarbon";
      hleðurSkilaboð = "Sendir...";
      breittUpptekni = "Uppteknarbón";
    } else {
      return;
    }

    skikkaHleðurUpptekni(hleðurSkilaboð);
    skikkaVilluUpptekni(null);

    try {
      const svarMiðlara = await miðlarabón.put(`firiraetlanir/${slóð}/${einkiFirirætlunar}`);
      breitaUpptekni.current = breittUpptekni;
      skikkaSvariUpptekni(svarMiðlara.data);
    } catch (villaBónar) {
      skikkaVilluUpptekni(fáMiðlaravillu(villaBónar))
    }
    skikkaHleðurUpptekni(null);

  }


  async function búaTilLeik(tímaskrá, skikkaSvariMiðlaraAðgerð, skikkaHleðurAðgerð, skikkaVilluAðgerð) {
    skikkaHleðurAðgerð("Býr til leik...");
    skikkaVilluAðgerð(null);

    try {
      const svar = await miðlarabón.post(`/firiraetlanir/buatilleik/${tímaskrá.einki}`);
      sendaÞángað(`/gutlaraleikur/${svar.data}`);
    } catch (villaBónar) {
      skikkaVilluAðgerð(fáMiðlaravillu(villaBónar));
      skikkaHleðurAðgerð(null);
    }
  }

  async function fargaTímaskrá(tímaskrá, skikkaSvariMiðlaraAðgerð, skikkaHleðurAðgerð, skikkaVilluAðgerð) {
    skikkaHleðurAðgerð("Eyðir tímaskrá...");
    skikkaVilluAðgerð(null);

    try {
      const svar = await miðlarabón.delete(`/firiraetlanir/fargatimaskra/${tímaskrá.einki}`);
      skikkaSvariMiðlaraAðgerð(svar.data);
    } catch (villaBónar) {
      skikkaVilluAðgerð(fáMiðlaravillu(villaBónar));
    }
    skikkaHleðurAðgerð(null);
  }

  async function búaTilLeikáStað() {
    skikkaHleðurBúaTil("Býr til leik...");
    skikkaVilluBúaTil(null);

    try {
      const svar = await miðlarabón.post(`/firiraetlanir/buatilleikstadur/${einkiFirirætlunar}`);
      sendaÞángað(`/gutlaraleikur/${svar.data}`);
    } catch (villaBónar) {
      skikkaVilluBúaTil(fáMiðlaravillu(villaBónar));
      skikkaHleðurBúaTil(null);
    }
  }

  async function bætaTímaskrá() {
    const frá = new Date(firirætlun.tímabil_frá).getTime();
    const til = new Date(firirætlun.tímabil_til).getTime() + sólarhríngur; //bæta við sólarhríng (því miðað er við lok dags)
    const tímifrá = tími[0];
    
    //athuga kvort er innan tímabils
    if (tímifrá < frá || tímifrá > til) {
      skikkaVilluBæta("Tíminn þarf að vera innan tímabils fyrirætlunar");
      return;
    }
    skikkaVilluBæta(null);
    skikkaHleðurBæta("Bætir tíma við...");
    try {
      const svar = await miðlarabón.post("firiraetlanir/baetavid/" + einkiFirirætlunar, {tími});
      skikkaFirirætlun(firriFirirætlun => {
        const uppfærðFirirætlun = {...firriFirirætlun};

        uppfærðFirirætlun.tímaskrár = [...firriFirirætlun.tímaskrár, {
          einki: svar.data.einkiTímaskráar, 
          tími_frá: new Date(tími[0]).toISOString(), 
          tími_til: new Date(tími[1]).toISOString(), 
          upptekni: svar.data.upptekni
        }];

        return uppfærðFirirætlun;
      })
      skikkaSvariBæta(svar.data.skilaboð);  
    } catch (villaBónar) {
      skikkaVilluBæta(fáMiðlaravillu(villaBónar));
    }
    skikkaHleðurBæta(null);
  }
  


  return (
    <>
      {hleður && <p className="leikurtímaskrá-firirsögn villaoghleður-hleður">{hleður}</p>}
      {villa && <p className="leikurtímaskrá-firirsögn villaoghleður-villa">{villa}</p>}
      {firirætlun && (
        <>
          <h3 className="smeija-firirsögn smellanlegur" onClick={() => {
            if (firirætlun.völlur) {
              skikkaSmeijuvallar(true);
            }
          }}>{firirætlun.völlur ? firirætlun.nafn : firirætlun.staður} {firirætlun.aukanafn} {firirætlun.stærð_undirvallar}</h3>
          {breitt && <p className="leikurtímaskrá-tími">ATHUGAÐU að tíma og/eða velli fyrirætlunar hefur verið breytt, upplýsíngar hér endurspegla gömlu fyrirætlunina, þú þarft að fara úr fyrirætluninni og svo aftur í hana til að sjá breytingarnar</p>}
          {(firirætlun.tegund_tímabils == 1) && (
            <>
              <p className="leikurtímaskrá-tími">Vikulega</p>
              <p className="leikurtímaskrá-tími">{vikudagarÞáguFleir[firirætlun.dagur - 1]}</p>
            </>
          )}
          {(firirætlun.tegund_tímabils == 2) && (
            <>
              <p className="leikurtímaskrá-tími">Mánaðarlega</p>
              <p className="leikurtímaskrá-tími">{firirætlun.dagur}. hvers mánaðar</p>
            </>
          )}
          <p className="leikurtímaskrá-tími">{fáKlstMínLesmál(firirætlun.klst_frá, firirætlun.mín_frá, firirætlun.klst_til, firirætlun.mín_til)}</p>
          <p className="leikurtímaskrá-gögn">{fáDagLesmál(new Date(firirætlun.tímabil_frá))}</p>
          <p className="leikurtímaskrá-tími">Til og með</p>
          <p className="leikurtímaskrá-gögn">{fáDagLesmálÞágu(new Date(firirætlun.tímabil_til))}</p>
          <div className="smeija-takki">
            <TakkiAðgerð1 texti="Eiginleikar fyrirætlunar" aðgerð={() => skikkaSjáEiginleika(true)}/>
          </div>
          {firirætlun.ní_skilaboð && 
            <p className="leikurtímaskrá-tími">Ný skilaboð!</p>
          }
          {firirætlun.völlur && <p className="leikurtímaskrá-tími">Upptekni: {breitaUpptekni.current}</p>}
          {(firirætlun.árnaðarmaður && firirætlun.erStefnir) && (
            <div className="smeija-aðaltakki">
              <VilluHleðurTakki
                svarMiðlara={svarUpptekni}
                hleður={hleðurUpptekni}
                villa={villaUpptekni}
                textiAðgerðar={textiTakka}
                aðgerð={() => eigaViðUpptekni(textiTakka)}
              />
            </div>
          )}
          {svarUpptekni && <p className="leikurtímaskrá-gögn">Þart að fara úr fyrirætlun og aftur í hana til að sjá upptekni tímaskráa breytast</p>}
          <div className="firirætlun-takkar smeija-aðaltakki">
            <TakkiAðgerð1 texti="Ætlunartímaskrár" aðgerð={() => skikkaSjáTímaskrár(true)} litur={sjáTímaskrár}/>
            <TakkiAðgerð1 texti="Leikir" aðgerð={() => skikkaSjáTímaskrár(false)} litur={!sjáTímaskrár}/>
          </div>
          {sjáTímaskrár ? (
            firirætlun.völlur ? (
              <>
                <div className="smeija-listi">
                  <Listi
                    tegund="firirtími"
                    breidd={3}
                    listi={firirætlun.tímaskrár}
                    textiAðgerðar={eiðaTímaskrá ? "Eyða tímaskrá" : "Búa til leik á tíma"}
                    aðgerð={eiðaTímaskrá ? fargaTímaskrá : búaTilLeik}
                  />
                </div>
                {bætaViðTímaskrá ? (
                  <>
                    <h3 className="smeija-aðaltakki">Bæta tímaskrá við firirætlun</h3>
                    <div className="búatilót-velja umgjörð-lóðrétt">
                      <Tímaval
                        tími={tími}
                        skikkaTíma={skikkaTíma}
                        einkiVallar={firirætlun.völlur}
                      />
                    </div>
                    <div className="smeija-aðaltakki">
                      <VilluHleðurTakki
                        textiAðgerðar="Bæta við tímaskrá"
                        aðgerð={bætaTímaskrá}
                        svarMiðlara={svarBæta}
                        hleður={hleðurBæta}
                        villa={villaBæta}
                      />
                    </div>
                  </>

                ) : (
                  <div className="smeija-takki">
                    {firirætlun.erStefnir && <TakkiAðgerð1 texti="Bæta tímaskrá við fyrirætlun" aðgerð={() => skikkaBætaViðTímaskrá(true)}/>}
                  </div>
                )}
                {firirætlun.erStefnir && (
                  <div className="smeija-takki">
                    <TakkiAðgerð1 texti={eiðaTímaskrá ? "Velja tímaskrá til að búa til leik" : "Velja tímaskrá til að eyða"} aðgerð={() => skikkaEiðaTímaskrá(firraGildi => !firraGildi)}/>
                  </div>
                )}
              </>
            ) : (
              firirætlun.staður && (
                <div className="smeija-aðaltakki">
                  <VilluHleðurTakki 
                    hleður={hleðurBúaTil}
                    villa={villaBúaTil}
                    textiAðgerðar="Búa til næsta leik" 
                    aðgerð={búaTilLeikáStað}
                  />
                </div>
              )
            )
          ) : (
            <div className="smeija-flettilisti">
              <FlettíngarListiEfri
                veffáng={`/firiraetlanir/saekjaleiki/${einkiFirirætlunar}?`} 
                tegundLista="leikur" 
                listi={sótturListi}
                breiddLista={3} 
                mestiFjöldiíLista={24}
                sækjaStrax={true}
                stærð={1}
                skikkaLista={skikkaSóttumLista}
                birjaListaVísir={vísalisti} 
                skikkaBirjaListaVísi={skikkaVísalista}
                erNæsti={erNæsti}
                skikkaErNæsti={skikkaErNæsti}
                villa={villaListi}
                skikkaVillu={skikkaVilluLista}
                tími={true}
              />
            </div>
          )}
          {(firirætlun.erStefnir) && (
            <SpjallÁrnaðarmaður
              sendaSlóð={`firiraetlanir/sendaskilabod/${einkiFirirætlunar}`}
              sækjaSlóð={`firiraetlanir/saekjaskilabod/${einkiFirirætlunar}?`}
              einkileiks={einkiFirirætlunar}
            />
          )}
        </>
      )}
      {sjáEiginleika && (
        <Smeija lokaSmeiju={skikkaSjáEiginleika}>
          <EiginleikarFirirætlunar 
            firirætlun={firirætlun} 
            skikkaFirirætlun={skikkaFirirætlun} 
            einkiFirirætlunar={einkiFirirætlunar}
            skikkaFirirætlunEitt={skikkaFirirætlunEitt}
            skikkaBreitt={skikkaBreitt}
            skikkaSjáEiginleika={skikkaSjáEiginleika}
          />
        </Smeija>
      )}
      {smeijavallar && (
        <Smeija lokaSmeiju={skikkaSmeijuvallar}>
          <Völlur einkivallar={firirætlun.völlur}/>
        </Smeija>
      )}
      {firirætlunEitt && (
        <TilkinníngarSmeija
          skilaboð="Fyrirætlun fargað"
          til="/"
        />
      )}
    </>
  )
}


export default Firirætlun;