import { useParams } from "react-router-dom";
import "../../almenncss/tölvuskeitissíður.css"
import "../../almenncss/grunncss.css";
import miðlarabón from "../../miðlarabón.js"
import { useState } from "react";
import TilkinníngarSmeija from "../../hlutir/almennirhlutar/tilkinníngarsmeija/TilkinníngarSmeija.js";
import VilluHleðurTakki from "../../hlutir/almennirhlutar/villaoghleður/villuhleðurtakki/VilluHleðurTakki.js";
import { fáMiðlaravillu } from "../../gjörníngar/ímsir.js";


const FallastáNetfángsbreitíngu = () => {
  const [skilaboðMiðlara, skikkaSkilaboðumMiðlara] = useState(null);
  const [hleður, skikkaHleður] = useState(null);
  const [villa, skikkaVillu] = useState(null);

  const {dulmal} = useParams();

  async function staðfestaNetfángsbreitíngu() {
    //senda á miðlara að gera gutlara virkan og eiða slembitölu
    skikkaSkilaboðumMiðlara(null);
    skikkaVillu(null);
    skikkaHleður("Staðfestir...");

    try {
      const svarMiðlara = await miðlarabón.put("/gutlaraskraning/stadfestanetfangsbreitingu/" + dulmal);
      if (svarMiðlara.status === 200) {
        skikkaSkilaboðumMiðlara(svarMiðlara.data);
      }
    } catch (villaMiðlara) {
      skikkaVillu(fáMiðlaravillu(villaMiðlara));
    }
    skikkaHleður(null);
  }


  return (
    <section className="almenn-grunnur tölvuskeitissíða-aðal">
      <p className="tölvuskeitissíða-lítilskilaboð">Athugaðu að vefslóðin gildir aðeins í 15 mínútur frá því að tölvupósturinn var sendur til þín</p>
      <div className="tölvuskeitissíða-stærritakki">
        <VilluHleðurTakki
          hleður={hleður}
          villa={villa}
          textiAðgerðar="Staðfesta netfangsbreytingu"
          aðgerð={staðfestaNetfángsbreitíngu}
        />
      </div>
      {skilaboðMiðlara && (
        <TilkinníngarSmeija
          skilaboð={skilaboðMiðlara}
          til="/"
        />
      )}
    </section>
  )
}

export default FallastáNetfángsbreitíngu;