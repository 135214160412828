import React, { useState } from "react"
import "./vallarbleðill.css";
import "../../../almenncss/grunncss.css";
import Smeija from "../smeija/Smeija";
import Völlur from "../../../síðurnar/völlur/Völlur";
import slóðminda from "../../../slóðminda";

const Vallarbleðill = ({ völlur }) => {
  const [vallarSmeija, skikkaVallarSmeiju] = useState(false);

  return (
    <section className="vallarbleðill-aðal umgjörð-lóðrétt-ómiðjað">
      <p onClick={() => skikkaVallarSmeiju(true)} className="vallarbleðill-nafnvallar">{völlur.nafn}</p>
      {völlur.aukanafn && <p onClick={() => skikkaVallarSmeiju(true)} className="vallarbleðill-aukanafn">{völlur.aukanafn} {völlur.stærð_undirvallar}</p>}
      <img onClick={() => skikkaVallarSmeiju(true)} className="vallarbleðill-myndafvelli" src={`${slóðminda}vallamindir/${völlur.aðalmind}`} alt="mynd af velli" />
      {vallarSmeija && (
        <Smeija lokaSmeiju={skikkaVallarSmeiju}>
          <Völlur einkivallar={völlur.einki}/>
        </Smeija>
      )}
    </section>
  )
}

export default Vallarbleðill;