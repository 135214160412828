import "./forsíðutakkar.css";
import TakkiAðgerð1 from "../../../../hlutir/almennirhlutar/takkar/takkiAðgerð1/TakkiAðgerð1";
import miðlarabón from "../../../../miðlarabón";
import { fáMiðlaravillu } from "../../../../gjörníngar/ímsir";


const Kappleikjalistar = ({
  skikkaLista,
  sjáKvaðaLista,
  skikkaSjáKvaðaLista,
  kappleikirMeðTíma,
  kappleikjabónirMeðTíma,
  hleður
}) => {
  
  async function memmaLeikMeðTíma(leikur, skikkaSvariMiðlaraAðgerð, skikkaHleðurAðgerð, skikkaVilluAðgerð) {
    skikkaHleðurAðgerð("Memmar...")
    if (!leikur.mittliðeinki) {
      skikkaHleðurAðgerð(null);
      skikkaVilluAðgerð("Ekkert lið til að memma með");
    }
    //tókst er svo það er vitað kvort það þarf að fjarlæja leik úr listanum sem hann er í
    let tókst = false;
    try {
      const svarMiðlara = await miðlarabón.put("/leikir/kappleikur/memmamedbon/" + leikur.einki + "/" + leikur.mittliðeinki);
      if (svarMiðlara.status === 200) {
        const listiKappleikir = kappleikirMeðTíma.current.listi;
        const leingdKappleikjalista = listiKappleikir.length;
        let fjarlæjaVillu = false;
        const nírListiKappleikir = [];

        //bæta nímemmuðum leik við lista af memmuðum leikjum
        if (leingdKappleikjalista > 0) {
          let leikBættVið = false;
          for (let i = 0; i < leingdKappleikjalista; i++) {
            if (!leikBættVið && listiKappleikir[i].tími_frá > leikur.tími_frá) {
              nírListiKappleikir.push(leikur);
              leikBættVið = true;
            }
            nírListiKappleikir.push(listiKappleikir[i]);
          }
        } else {
          nírListiKappleikir.push(leikur);
          fjarlæjaVillu = true;
        }
        
        kappleikirMeðTíma.current = {
          listi: nírListiKappleikir,
          erNæsti: kappleikirMeðTíma.current.erNæsti,
          birjaListaVísir: kappleikirMeðTíma.current.birjaListaVísir,
          villa: fjarlæjaVillu ? null : kappleikirMeðTíma.current.villa
        }  

        tókst = true;
        skikkaSvariMiðlaraAðgerð(svarMiðlara.data);
      }

    } catch (villaBónar) {
      skikkaVilluAðgerð(fáMiðlaravillu(villaBónar));
    }
    skikkaHleðurAðgerð(null);
    return tókst;
  }


  return (
    <>
      <div className="forsíðutakkar-leikjatakkar">
        <div className="forsíðutakkar-leikjatakkakassi-minni">
          <TakkiAðgerð1 
            texti="Næstu leikir" 
            aðgerð={() => {
              if (hleður) {
                return;
              }
              skikkaSjáKvaðaLista("næstukappleikir");
              skikkaLista({
                firirsögn: "Kappleikir á döfinni",
                veffáng: "leikir/kappleikur/saekja/medtima?",
                tegundLista: "kappleikur",
                breiddLista: 2,
                textiAðgerðarLista: null,
                aðgerðLista: null,
                leit: null,
                efri: kappleikirMeðTíma,
                tími: true
              })
            }} 
            einginnbakgrunnur={sjáKvaðaLista === "næstukappleikir"} 
          />
        </div>
        <div className="forsíðutakkar-leikjatakkakassi-minni">
          <TakkiAðgerð1 
            texti="Boð í leiki" 
            aðgerð={() => {
              if (hleður) {
                return;
              }
              skikkaSjáKvaðaLista("kappleikjabónir");
              skikkaLista({
                firirsögn: "Boð í kappleiki á döfinni",
                veffáng: "leikir/kappleikur/saekja/bonirmedtima?",
                tegundLista: "kappleikur",
                breiddLista: 2,
                textiAðgerðarLista: "Memma leik",
                aðgerðLista: memmaLeikMeðTíma,
                leit: null,
                efri: kappleikjabónirMeðTíma,
                tími: true
              })
            }} 
            einginnbakgrunnur={sjáKvaðaLista === "kappleikjabónir"}
          />
        </div>
      </div>
    </>
  )
}


export default Kappleikjalistar;