import { useEffect, useRef, useState } from "react";
import "../spjall/spjall.css";
import "./spjallárnaðarmaður.css";
import SpjallKassiÁrn from "./spjallkassiárn/SpjallKassiÁrn.js";
import VilluHleðurTakki from "../villaoghleður/villuhleðurtakki/VilluHleðurTakki";
import "../takkar/takki2/takki2.css";
import { fáMiðlaravillu } from "../../../gjörníngar/ímsir";
import miðlarabón from "../../../miðlarabón";
import "../../../almenncss/grunncss.css";


const SpjallÁrnaðarmaður = ({
  einkileiks, 
  einkitímaskráar, 
  breittNíSkilaboð, 
  tegundLeiks,
  sendaSlóð,
  sækjaSlóð
}) => {
  const [skilaboðin, skikkaSkilaboðunum] = useState([]);
  const [hleðurSkilaboðum, skikkaHleðurSkilaboðum] = useState("Sækir skilaboð...");
  const [villaSkilaboð, skikkaVillaSkilaboð] = useState(null);

  const [tímiElstuSkilaboða, skikkaTímaElstuSkilaboða] = useState("");
  const [villaSækjaFleiri, skikkaVilluSækjaFleiri] = useState(null);
  const [hleðurSækjaFleiri, skikkaHleðurSækjaFleiri] = useState(null);

  const [hleðurSendir, skikkaHleðurSendir] = useState(null);
  const [villaSendir, skikkaVilluSendir] = useState(null);

  const inntakspjalls = useRef();

  async function sendaSkilaboð() {
    const inntak = inntakspjalls.current.value;
    const rakaðInntak = inntak.trim();
    if (rakaðInntak === "") {
      inntakspjalls.current.value = "";
      return;
    }
    if (rakaðInntak.length > 1000) {
      skikkaSkilaboðunum(firriSkilaboð => {
        return [
          {
            gælunafn: "VILLA",
            skilaboð: "Þetta eru alltof löng skilaboð",
            erég: false,
            tími_skilaboða: new Date().toISOString()
          }, ...firriSkilaboð]
      })
      return;
    }
    skikkaHleðurSendir("Sendir...");

    const innihald = {skilaboð: rakaðInntak, tegund: tegundLeiks};

    try {
      await miðlarabón.post(sendaSlóð, innihald);
      skikkaSkilaboðunum(firriSkilaboð => {
        return [
          {
            skilaboð: rakaðInntak,
            gutlari: true,
            tími_skilaboða: new Date().toISOString()
          }, ...firriSkilaboð]
      });
    } catch (villaBónar) {
      skikkaVilluSendir(fáMiðlaravillu(villaBónar));
    }
    skikkaHleðurSendir(null);
    inntakspjalls.current.value = "";
  }

  useEffect(() => {
    const náíSkilaboð = async () => {
      skikkaHleðurSkilaboðum("Sækir skilaboð...");
      skikkaVillaSkilaboð(null);

      
      try {
        const miðlaraGögn = await miðlarabón.get(sækjaSlóð);
        if (miðlaraGögn.status === 200) {
          if (breittNíSkilaboð) {
            breittNíSkilaboð.current = false;
          }
          if (miðlaraGögn.data.length >= 50) {
            //þannig ef sótt er fleiri, þá er vitað á kvaða skilaboðum á að birja
            skikkaTímaElstuSkilaboða(miðlaraGögn.data[49].tími_skilaboða);
          }
          skikkaSkilaboðunum(miðlaraGögn.data);

        }
      } catch (villaMiðlara) {
        skikkaVillaSkilaboð(fáMiðlaravillu(villaMiðlara));
      }
      skikkaHleðurSkilaboðum(null);
    }

    náíSkilaboð();

  }, [einkitímaskráar, einkileiks])

  function sendinnSkilaboðTakki(e) {
    e.preventDefault();
    sendaSkilaboð();
  }

  async function náíFleiriSkilaboð() {
    skikkaHleðurSækjaFleiri("Sækir fleiri...");
    skikkaVilluSækjaFleiri(null);

    try {
      const miðlaraGögn = await miðlarabón.get(`${sækjaSlóð}staður=${tímiElstuSkilaboða}`);
      if (miðlaraGögn.status === 200) {
        if (miðlaraGögn.data.length >= 50) {
          skikkaTímaElstuSkilaboða(miðlaraGögn.data[49].tími_skilaboða);
        } else {
          skikkaTímaElstuSkilaboða(null);
        }
        skikkaSkilaboðunum(firriSkilaboð => [...firriSkilaboð, ...miðlaraGögn.data]);
      }
    } catch (villaMiðlara) {
      skikkaVilluSækjaFleiri(fáMiðlaravillu(villaMiðlara))
    }
    skikkaHleðurSækjaFleiri(null);
  }

  return (
    <section className="spjallárn-aðal">
      <div className="spjall-listiskilaboða">
        {skilaboðin.map((skilaboð) => { 
            //einki leiks gefur til kinna að gutlari sækir spjall (en ekki árnaðarmaður), nafnið þarf þá að sína nafn vallar
            const nafn = einkileiks ? `${skilaboð.nafn}${skilaboð.aukanafn ? ` ${skilaboð.aukanafn} ${skilaboð.stærð_undirvallar}` : ""}` : skilaboð.nafn;
            //ef gutlari sækir eru skilaboð frá gutlara hægra meigin, hjá árnaðarmanni er það ef ekkert nafn filgir skilaboðum
            const hægri = einkileiks ? skilaboð.gutlari : !skilaboð.nafn

            return (
              <SpjallKassiÁrn 
                key={skilaboð.tími_skilaboða}
                nafn={nafn} 
                lesmál={skilaboð.skilaboð} 
                hægri={hægri}
              />
            )
          }
        )}
        {villaSkilaboð && <div className="spjall-listiskilaboða-sækjameira villaoghleður-villa">{villaSkilaboð}</div>}
        {hleðurSkilaboðum && <div className="spjall-listiskilaboða-sækjameira villaoghleður-hleður">{hleðurSkilaboðum}</div>}
        {tímiElstuSkilaboða && (
          <div className="spjall-listiskilaboða-sækjameira">
            <VilluHleðurTakki
              hleður={hleðurSækjaFleiri}
              villa={villaSækjaFleiri}
              textiAðgerðar="Sækja fleiri skilaboð"
              aðgerð={náíFleiriSkilaboð}
            />
          </div>
        )}
      </div>
      <label className="spjallárn-inntakskassi">
        <textarea
          type="text"
          className="spjallárn-inntak"
          placeholder=""
          spellCheck="false"
          name="inntak"
          ref={inntakspjalls}
        />
        <div className="spjallárn-senda">
          <VilluHleðurTakki
            hleður={hleðurSendir}
            villa={villaSendir}
            textiAðgerðar="Senda"
            aðgerð={sendinnSkilaboðTakki}
          />
        </div>
      </label> 
    </section>
  )
}

export default SpjallÁrnaðarmaður;