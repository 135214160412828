import "../../../almenncss/grunncss.css";
import Smeija from "../smeija/Smeija";

const SpurnStærðValla = ({spurn, skikkaSpurn}) => {
  
  return (
    <>
      {spurn && (
        <Smeija lokaSmeiju={skikkaSpurn}>
          <p className="smeija-aðaltakki spurn-rammi">Stubblingur: Völlur u.þ.b. 8 metrar og undir, þar sem spilað er 1 gegn 1 eða 2 gegn 2</p>
          <p className="smeija-aðaltakki spurn-rammi">Dverglingur: Völlur u.þ.b. 8 til 20 metrar (dæmi: lítill skólavöllur)</p>
          <p className="smeija-aðaltakki spurn-rammi">Þokkalingur: Völlur u.þ.b. 20 til 28 metrar (dæmi: skóla malbiksvöllur eða inni skólavöllur)</p>
          <p className="smeija-aðaltakki spurn-rammi">Snotrungur: Völlur u.þ.b. 28 til 40 metrar (dæmi: battavöllur)</p>
          <p className="smeija-aðaltakki spurn-rammi">Drjúgungur: Völlur u.þ.b. 40 til 55 metrar (dæmi: fjórðungur af keppnisfótboltavelli)</p>
          <p className="smeija-aðaltakki spurn-rammi">Bosmungur: Völlur u.þ.b. 55 til 83 metrar (dæmi: helmingur keppnisfótboltavallar)</p>
          <p className="smeija-aðaltakki spurn-rammi">Fullstór: Völlur u.þ.b. 83 metrar og yfir (dæmi: keppnisfótboltavöllur)</p>
        </Smeija>
      )}
    </>
  )
}


export default SpurnStærðValla;
