import { useEffect, useState } from "react";
import miðlarabón from "../../../miðlarabón";
import "./liðasmeija.css";
import "../../../almenncss/grunncss.css"
import Takki2 from "../takkar/takki2/Takki2";
import FlettíngarListi from "../flettíngartakkar/FlettíngarListi";
import "../../../almenncss/villaoghleður.css";
import VilluHleðurTakki from "../villaoghleður/villuhleðurtakki/VilluHleðurTakki";
import { fáMiðlaravillu } from "../../../gjörníngar/ímsir";
import slóðminda from "../../../slóðminda";
import Smeija from "../smeija/Smeija";
import Gutlarasmeija from "../gutlarasmeija/Gutlarasmeija";


const Liðasmeija = ({ lið, erÁrnaðarmaður }) => {
  const [liðsgögn, skikkaLiðsgögnum] = useState(null);

  const [sækirLiðsgögn, skikkaSækirLiðsgögn] = useState("Sækir liðsgögn...");
  const [villaLiðsgögn, skikkaVillaLiðsgögn] = useState(null);

  const [svarMemmbón, skikkaSvarMemmbón] = useState(null);
  const [hleðurMemmbón, skikkaHleðurMemmbón] = useState(null);
  const [villaMemmbón, skikkaVillaMemmbón] = useState(null);

  const [sjáFirirliða, skikkaSjáFirirliða] = useState(false);
  
  useEffect(() => {
    const sækjaliðsgögn = async () => {
      const slóð = erÁrnaðarmaður ? "/arnadarmadur/falid/" : "/lid/fagognlids/";
      try {
        const svar = await miðlarabón.get(slóð + lið.lið.einki);
        if (svar.status === 200) {
          skikkaLiðsgögnum(svar.data);
        }
      } catch (villaMiðlara) {
        skikkaVillaLiðsgögn(fáMiðlaravillu(villaMiðlara));
      }
      skikkaSækirLiðsgögn(null);
    }
    if (!liðsgögn) {
      sækjaliðsgögn();
    }
  }, [lið.lið.einki])

  async function sendaMemmbón() {
    skikkaHleðurMemmbón("Sendir...");
    skikkaVillaMemmbón(null);
    skikkaSvarMemmbón(null)
    try {
      const svar = await miðlarabón.post("/lid/sendamemmbon/" + lið.lið.einki);
      if (svar.status === 200) {
        skikkaSvarMemmbón(svar.data);
      }
    } catch (villaMiðlara) {
      skikkaVillaMemmbón(fáMiðlaravillu(villaMiðlara));
    }
    skikkaHleðurMemmbón(null);
  }

  return (
    <section className="liðasmeija-liðsgögn-umgjörð" style={{ backgroundImage: liðsgögn && `linear-gradient(120deg, ${liðsgögn.öndveigislitur}, ${liðsgögn.öndveigislitur}, ${liðsgögn.filgilitur})` }}>
      {liðsgögn ? (
        <>
          <h3 className="liðasmeija-smeijafirirsögn">{lið.lið.nafn}</h3>
          <img className="liðasmeija-mindísmeiju" alt="skjaldarmerki liðs" src={`${slóðminda}lidamindir/${liðsgögn.skjaldamerki ? liðsgögn.skjaldamerki : "170809081376912242.png"}`} />
          <p className="liðasmeija-lísíngáliði">{liðsgögn.lísíng}</p>
          {liðsgögn.geturSentMemmbón && (
            <div className="liðasmeija-sendamemmbón-takki">
              <VilluHleðurTakki
                svarMiðlara={svarMemmbón}
                hleður={hleðurMemmbón}
                villa={villaMemmbón}
                textiAðgerðar="Senda beiðni um að fá að memma lið"
                aðgerð={sendaMemmbón}
              />
            </div>
          )}
          {liðsgögn.geturFariðáSíðuLiðs && (
            <div className="liðasmeija-sendamemmbón-takki">
              <Takki2 texti="Fara á síðu liðs" til={`/lid/${lið.lið.einki}`} />
            </div>
          )}
          {liðsgögn.einkifirirliða && (
            <p className="liðasmeija-lísíngáliði smellanlegur" onClick={() => skikkaSjáFirirliða(true)}>Fyrirliði: {liðsgögn.skírnarnafn}</p>
          )}
        </>
      ) : (
        <>
          <h3 className="liðasmeija-smeijafirirsögn">{lið.lið.nafn}</h3>
          {lið.lið.skjaldamerki && <img className="liðasmeija-mindísmeiju" alt="skjaldamerki liðs" src={`${slóðminda}lidamindir/${lið.lið.skjaldamerki ? lið.lið.skjaldamerki : "170809081376912242.png"}`} />}
          {sækirLiðsgögn && <p className="liðasmeija-lísíngáliði villaoghleður-hleður">{sækirLiðsgögn}</p>}
          {villaLiðsgögn && <p className="liðasmeija-lísíngáliði villaoghleður-villa">{villaLiðsgögn}</p>}
        </>
      )}
      {!erÁrnaðarmaður && <div className="liðasmeija-listiivirmeðlimi">
        <FlettíngarListi
          veffáng={`/lid/saekjagutlara/memmadir/${lið.lið.einki}?`}
          tegundLista="gutlarar"
          breiddLista={3}
          mestiFjöldiíLista={24}
          sækjaStrax={true}
          stærð={1}
        />
      </div>}
      {sjáFirirliða && (
        <Smeija lokaSmeiju={skikkaSjáFirirliða}>
          <Gutlarasmeija einkigutlara={liðsgögn.einkifirirliða} gutlari={{gælunafn: liðsgögn.skírnarnafn}}/>
        </Smeija>
      )}
    </section>
  )
}

export default Liðasmeija;