import React from "react"
import "./útfillíngartala.css"
import "../../../../almenncss/grunncss.css";


const ÚtfillíngarTala = ({ texti, gildi, breitaGildi }) => {

  function breitaTölu(stærð) {
    breitaGildi(firriTala => firriTala + stærð);
  }

  return (
    <label className="útfillíngartala-aðal">
      <p className="útfillíngartala-lesmál">{texti}</p> 
      <div className="útfillíngartala-inntak-kassi">
        <p className="útfillíngartala-skrániðurstöðu-ör almenn-óveljanlegurtexti" onClick={() => breitaTölu(-10)}>&equiv;</p>
        <p className="útfillíngartala-skrániðurstöðu-ör almenn-óveljanlegurtexti" onClick={() => breitaTölu(-1)}>&minus;</p>
        <p className="útfillíngartala-inntak">{gildi}</p>
        <p className="útfillíngartala-skrániðurstöðu-ör almenn-óveljanlegurtexti" onClick={() => breitaTölu(1)}>+</p>
        <p className="útfillíngartala-skrániðurstöðu-ör almenn-óveljanlegurtexti" onClick={() => breitaTölu(10)}>&oplus;</p>
      </div>
    </label>
  )
}

export default ÚtfillíngarTala;