import React, { useMemo, useRef, useState } from "react"
import "./aðalheim.css";
import "./Forsíðutakkar/forsíðutakkar.css";
import "../../../almenncss/grunncss.css";
import Takki2 from "../../../hlutir/almennirhlutar/takkar/takki2/Takki2";
import Takki1Aðgerð from "../../../hlutir/almennirhlutar/takkar/takkiAðgerð1/TakkiAðgerð1";
import Gutlaraleikjalistar from "./Forsíðutakkar/Gutlaraleikjalistar";
import FlettíngarListiMargir from "../../../hlutir/almennirhlutar/flettíngartakkar/FlettíngarlistiMargir";
import Kappleikjalistar from "./Forsíðutakkar/Kappleikjalistar";
import Liðalistar from "./Forsíðutakkar/Liðalistar.js";
import Takki1 from "../../../hlutir/almennirhlutar/takkar/takki1/Takki1.js";

const upphafslistiMeðTíma = {
  listi: [],
  erNæsti: false,
  birjaListaVísir: [[new Date(), 0]],
  villa: null
};
const upphafslistiÁnTíma = {
  listi: [],
  erNæsti: false,
  birjaListaVísir: [0],
  villa: null
}
//birjaListaVísir: [einkiLeiks, einkiLiðsTilAðMemmaMeð]
// const upphafslistiKappleikjabónaÁnTíma = {
//   listi: [],
//   erNæsti: false,
//   birjaListaVísir: [[0, 0]],
//   villa: null
// }
//birjaListaVísir: [tími, einkiLeiks, einkiLiðsTilAðMemmaMeð]
const upphafslistiKappleikjabónaMeðTíma = {
  listi: [],
  erNæsti: false,
  birjaListaVísir: [[new Date(), 0, 0]],
  villa: null
}


const AðalHeim = () => {
  const [sjáKvaðaLista, skikkaSjáKvaðaLista] = useState("næstugutlaraleikir");
  //svo ekki hægt er að skifta um lista þegar verið er að sækja
  const [sækjandi, skikkaSækjandi] = useState(false);

  //skilgreina alla mögulega lista
  const gutlaraleikirMeðTíma = useRef(upphafslistiMeðTíma);
  const gutlaraleikjabónirMeðTíma = useRef(upphafslistiMeðTíma);
  const kappleikirMeðTíma = useRef(upphafslistiMeðTíma);
  const kappleikjabónirMeðTíma = useRef(upphafslistiKappleikjabónaMeðTíma);
  const lið = useRef(upphafslistiÁnTíma);
  const liðabónir = useRef(upphafslistiÁnTíma);


  const [listi, skikkaLista] = useState({
    firirsögn: "Gutlaraleikir á döfinni",
    veffáng: "leikir/gutlaraleikur/saekja/medtima?",
    tegundLista: "leikur",
    breiddLista: 2,
    textiAðgerðarLista: null,
    aðgerðLista: null,
    leit: null,
    efri: gutlaraleikirMeðTíma,
    tími: true
  });

  const {miðjutakki, efstiTakki} = useMemo(() => {

    let miðjutakki;
    if (sjáKvaðaLista === "næstugutlaraleikir" || sjáKvaðaLista === "gutlaraleikjabónir") {
      miðjutakki = "Gutlaraleikir";
    }
    if (sjáKvaðaLista === "næstukappleikir" || sjáKvaðaLista === "kappleikjabónir") {
      miðjutakki = "Kappleikir";
    }
    if (sjáKvaðaLista === "lið" || sjáKvaðaLista === "liðabónir") {
      miðjutakki = "Lið"
    }
    let efstiTakki;
    if (miðjutakki === "Gutlaraleikir" || miðjutakki === "Kappleikir") {
      efstiTakki = "Leikir";
    }
    if (miðjutakki === "Lið") {
      efstiTakki = "Lið";
    }
    return {miðjutakki, efstiTakki};

  }, [sjáKvaðaLista])


  return (
    <section className="aðalheim-aðal">
      
      <div className="aðalheim-vinstri umgjörð-lóðrétt-ómiðjað">
        <div className="aðalheim-listaval umgjörð-lóðrétt-ómiðjað">

          <div className="forsíðutakkar-leikjatakkar">
            <div className="forsíðutakkar-leikjatakkakassi">
              <Takki1Aðgerð 
                texti="Leikir" 
                aðgerð={() => {
                  if (sækjandi) {
                    return;
                  }
                  skikkaSjáKvaðaLista("næstugutlaraleikir");
                  skikkaLista({
                    firirsögn: "Gutlaraleikir á döfinni",
                    veffáng: "leikir/gutlaraleikur/saekja/medtima?",
                    tegundLista: "leikur",
                    breiddLista: 2,
                    textiAðgerðarLista: null,
                    aðgerðLista: null,
                    leit: null,
                    efri: gutlaraleikirMeðTíma,
                    tími: true
                  })
                }} 
                einginnbakgrunnur={efstiTakki === "Leikir"} 
              />
            </div>
            <div className="forsíðutakkar-leikjatakkakassi">
              <Takki1Aðgerð 
                texti="Lið" 
                aðgerð={() => {
                  if (sækjandi) {
                    return;
                  }
                  skikkaSjáKvaðaLista("lið");
                  skikkaLista({
                    firirsögn: "Liðin mín",
                    veffáng: "lid/saekja/minlid?",
                    tegundLista: "lið",
                    breiddLista: 2,
                    textiAðgerðarLista: null,
                    aðgerðLista: null,
                    leit: null,
                    efri: lið,
                    tími: false
                  })
                }} 
                einginnbakgrunnur={efstiTakki === "Lið"}
              />
            </div>
          </div>
          {(efstiTakki === "Leikir") && <div className="forsíðutakkar-leikjatakkar">
            <div className="forsíðutakkar-leikjatakkakassi">
              <Takki1Aðgerð 
                texti="Gutlaraleikir" 
                aðgerð={() => {
                  if (sækjandi) {
                    return;
                  }
                  skikkaSjáKvaðaLista("næstugutlaraleikir");
                  skikkaLista({
                    firirsögn: "Gutlaraleikir á döfinni",
                    veffáng: "leikir/gutlaraleikur/saekja/medtima?",
                    tegundLista: "leikur",
                    breiddLista: 2,
                    textiAðgerðarLista: null,
                    aðgerðLista: null,
                    leit: null,
                    efri: gutlaraleikirMeðTíma,
                    tími: true
                  });
                }} 
                einginnbakgrunnur={miðjutakki === "Gutlaraleikir"} 
              />
            </div>
            <div className="forsíðutakkar-leikjatakkakassi">
              <Takki1Aðgerð 
                texti="Kappleikir" 
                aðgerð={() => {
                  if (sækjandi) {
                    return;
                  }
                  skikkaSjáKvaðaLista("næstukappleikir");
                  skikkaLista({
                    firirsögn: "Kappleikir á döfinni",
                    veffáng: "leikir/kappleikur/saekja/medtima?",
                    tegundLista: "kappleikur",
                    breiddLista: 2,
                    textiAðgerðarLista: null,
                    aðgerðLista: null,
                    leit: null,
                    efri: kappleikirMeðTíma,
                    tími: true
                  });
                }} 
                einginnbakgrunnur={miðjutakki === "Kappleikir"}
              />
            </div>
          </div>}
          {(miðjutakki === "Gutlaraleikir") && (
            <Gutlaraleikjalistar
              skikkaLista={skikkaLista}
              sjáKvaðaLista={sjáKvaðaLista}
              skikkaSjáKvaðaLista={skikkaSjáKvaðaLista}
              gutlaraleikirMeðTíma={gutlaraleikirMeðTíma}
              gutlaraleikjabónirMeðTíma={gutlaraleikjabónirMeðTíma}
              hleður={sækjandi}
            />
          )}
          {(miðjutakki === "Kappleikir") && (
            <Kappleikjalistar
              skikkaLista={skikkaLista}
              sjáKvaðaLista={sjáKvaðaLista}
              skikkaSjáKvaðaLista={skikkaSjáKvaðaLista}
              kappleikirMeðTíma={kappleikirMeðTíma}
              kappleikjabónirMeðTíma={kappleikjabónirMeðTíma}
              hleður={sækjandi}
            />
          )}
          {(efstiTakki === "Lið") && (
            <Liðalistar
              skikkaLista={skikkaLista}
              sjáKvaðaLista={sjáKvaðaLista}
              skikkaSjáKvaðaLista={skikkaSjáKvaðaLista}
              lið={lið}
              liðabónir={liðabónir}
              hleður={sækjandi}
            />
          )}
        </div>
        <div className="aðalheim-takkar umgjörð-lóðrétt-ómiðjað">
          <div className="aðalheim-takkakassi">
            <Takki2 texti="Búa til leik" til="/buatilnijanleik" />
          </div>
          <div className="aðalheim-takkakassi">
            <Takki1 texti="Mínir leikir" til="/minirleikir" />
          </div>
          <div className="aðalheim-takkakassi">
            <Takki2 texti="Búa til lið" til="/buatillid" />
          </div>
          <div className="aðalheim-takkakassi">
            <Takki1 texti="Mín lið" til="/minlid" />
          </div>
        </div>
      </div>
      <section className="aðalheim-leikir umgjörð-lóðrétt-ómiðjað">
        <h2 className="aðalheim-firirsögn-lista">{listi.firirsögn}</h2>
        {listi && <div className="aðalheim-umgjörð-listar">
          <FlettíngarListiMargir
            veffáng={listi.veffáng}
            tegundLista={listi.tegundLista}
            breiddLista={listi.breiddLista}
            textiAðgerðarLista={listi.textiAðgerðarLista}
            aðgerðLista={listi.aðgerðLista}
            mestiFjöldiíLista={24}
            leit={listi.leit}
            sækjaStrax={true}
            efri={listi.efri}
            tími={listi.tími}
            skikkaSækjandi={skikkaSækjandi}
          />
        </div>}
      </section>
    </section>
  )
}

export default AðalHeim;