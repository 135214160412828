import { useParams } from "react-router-dom";
import FlettíngarListiMargir from "../flettíngartakkar/FlettíngarlistiMargir";
import Liðasmeija from "../liðasmeija/Liðasmeija";
import Smeija from "../smeija/Smeija";
import TakkiAðgerð1 from "../takkar/takkiAðgerð1/TakkiAðgerð1";
import "./liðsmennmemmaðirsmeija.css";
import { useState } from "react";


const LiðsmennMemmaðirSmeija = ({lið}) => {
  const [sínaLiðsgögn, skikkaSínaLiðsgögn] = useState(false);

  const {einkileiks} = useParams();

  return (
    <>
      <h2 className="liðmemmaðir-firirsögn">{lið.lið.nafn}</h2>
      <div className="liðmemmaðir-sjáliðsgögn-takki">
        <TakkiAðgerð1 texti="Sjá meira um lið" aðgerð={() => skikkaSínaLiðsgögn(true)}/>
      </div>
      <div className="liðmemmaðir-umgjörð">
        <div className="liðmemmaðir-listiliðsmanna-umgjörð">
          <h4 className="liðmemmaðir-minnifirirsögn">Memmaðir</h4>
          <div className="liðmemmaðir-listiliðsmanna-listi">
            <FlettíngarListiMargir
              veffáng={`/leikir/kappleikur/saekjagutlara/${lið.slóð}/${einkileiks}?`}
              tegundLista="gutlarar"
              breiddLista={2}
              mestiFjöldiíLista={24}
              sækjaStrax={true}
              stærð={1}
              efri={lið.memmaðir}
            />
          </div>
        </div>
        <div className="liðmemmaðir-listiliðsmanna-umgjörð">
          <h4 className="liðmemmaðir-minnifirirsögn">Ekki búnir að svara</h4>
          <div className="liðmemmaðir-listiliðsmanna-listi">
            <FlettíngarListiMargir
              veffáng={`/leikir/kappleikur/saekjagutlara/${lið.slóð}bonir/${einkileiks}?`}
              tegundLista="gutlarar"
              breiddLista={2}
              mestiFjöldiíLista={24}
              sækjaStrax={true}
              stærð={1}
              efri={lið.bónir}
            />
          </div>
        </div>
      </div>
      {sínaLiðsgögn && (
        <Smeija lokaSmeiju={skikkaSínaLiðsgögn}>
          <Liðasmeija lið={lið}/>
        </Smeija>
      )}
    </>
  )
}

export default LiðsmennMemmaðirSmeija;