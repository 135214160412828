import "../../../almenncss/grunncss.css"
import "../../../almenncss/villaoghleður.css"

const SmeijaHleður = ({texti}) => {

  return (
    <section className="umgjörð-lárétt">
      <p className="smeija-aðaltakki villaoghleður-hleður">
        Sækir {texti}...
      </p>
    </section>
  )
}


export default SmeijaHleður;