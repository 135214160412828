
import "../búatilfirirætlun/búatilfirirætlun.css";
import "../../../../almenncss/grunncss.css";
import VallarVal from "../../../../hlutir/almennirhlutar/vallarval/VallarVal";
import { useEffect, useRef, useState } from "react";
import "../../../búatilníjanleik/hægribúatilníjanleik/veljaaðraeiginleikaleiks/veljaaðraeiginleikaleiks.css";
import ÚtfillíngarHak from "../../../../hlutir/almennirhlutar/útfillíngar/útfillíngarhak/ÚtfillíngarHak";
import VeljaTímaFirirætlun from "../../../../hlutir/almennirhlutar/veljatímafirirætlun/VeljaTímaFirirætlun";
import { fáMiðlaravillu } from "../../../../gjörníngar/ímsir";
import miðlarabón from "../../../../miðlarabón";
import { fáUppteknarbón } from "../../../búatilníjanleik/hægribúatilníjanleik/veljaaðraeiginleikaleiks/VeljaAðraEiginleikaLeiks";
import { sólarhríngur } from "../../../../almenngögn";
import SkapaðurFastur from "../../../../hlutir/almennirhlutar/skapaðurfastur/SkapaðurFastur";
import VilluHleðurTakki from "../../../../hlutir/almennirhlutar/villaoghleður/villuhleðurtakki/VilluHleðurTakki";


export function fáTegundTímabils(tegund) {
  //gildi tegunda í gagnagrunni
  if (tegund === "Vikulega") return 1;
  if (tegund === "Mánaðarlega") return 2;
  return null;
}

export function fáValdanTíma(tími, dagur) {
  const milliUpphafiDags = (dagur - 1) * sólarhríngur;
  return [tími[0] + milliUpphafiDags, tími[1] + milliUpphafiDags];
}

const BreitaFirirætlun = ({
  loka, 
  skikkaBreitt, 
  einkiFirirætlunar,
  skikkaFirirætlun
}) => {
  const [völlur, skikkaVelli] = useState(null);
  const [tími, skikkaTíma] = useState([]);
  const [staðurLeiks, skikkaStaðLeiks] = useState("");
  const [tegundTímabils, skikkaTegundTímabils] = useState("");

  const [dagurTímabils, skikkaDeijiTímabils] = useState(null);
  const [dagurFrá, skikkaDagFrá] = useState(null);
  const [dagurTil, skikkaDagTil] = useState(null);

  const [sendaUppteknarbón, skikkaSendaUppteknarbón] = useState(false);

  const [svarMiðlara, skikkaSvariMiðlara] = useState(null);
  const [feingnirTímar, skikkaFeingnirTímar] = useState(null);
  const [hleður, skikkaHleður] = useState(null);
  const [villa, skikkaVillu] = useState(null);

  const firirætlunBreitt = useRef(false);


  async function breitafirirætlun() {
    skikkaVillu(null);
    skikkaHleður("Breitir firirætlun...");

    
    if (staðurLeiks && staðurLeiks.length > 200) {
      skikkaVillu("Ég heirði að æskilegt væri að hafa staðarlísíngu aðeins stittri");
      skikkaHleður(null);
      return;
    }

    if (!dagurFrá || !dagurTil) {
      skikkaVillu("Þarft að velja kvenær firirætlunin er í gildi");
      skikkaHleður(null);
      return;
    }

    if (dagurFrá.getTime() >= dagurTil.getTime()) {
      skikkaVillu("Upphaf og lok tímabils koma ekki heim og saman");
      skikkaHleður(null);
      return;
    }

    const valinTegundTímabils = fáTegundTímabils(tegundTímabils);
    if (!valinTegundTímabils) {
      skikkaVillu("Þarft að velja tegund tímabils");
      skikkaHleður(null);
      return;
    }

    const valinSendaUppteknarbón = fáUppteknarbón(sendaUppteknarbón, völlur, tími);

    const gögn = {
      uppteknarbón: valinSendaUppteknarbón,
      valinnVöllur: völlur ? völlur.einki : null,
      staður: staðurLeiks,
      dagurFrá,
      dagurTil,
      valinTegundTímabils,
      dagurTímabils,
      tími
    }

    try {
      const svar = await miðlarabón.put("/firiraetlanir/breita/" + einkiFirirætlunar, gögn);
      const {búnirTil, uppteknir, tókstEkki} = svar.data;
      firirætlunBreitt.current = true;
      skikkaSvariMiðlara("Firirætlun hefur verið breitt, skrunaðu niður til að sjá tíma hennar");
      skikkaFeingnirTímar({búnirTil, uppteknir, tókstEkki});
    } catch (villaBónar) {
      skikkaVillu(fáMiðlaravillu(villaBónar));
    }
    skikkaHleður(null);
  }


  useEffect(() => {

    return () => {
      if (firirætlunBreitt.current) {
        skikkaBreitt(true);
        skikkaFirirætlun(null);
      }
    }

  }, [])

  return (
    <section className="grunn-smeija">
      <h3 className="smeija-firirsögn">Breita firirætlun</h3>
      <div className="búatilfirir-veljavöll umgjörð-lóðrétt">
        <VallarVal
          völlur={völlur}
          skikkaVelli={skikkaVelli}
          staðurLeiks={staðurLeiks}
          skikkaStaðLeiks={skikkaStaðLeiks}
        />
      </div>
      <VeljaTímaFirirætlun
        tegundTímabils={tegundTímabils}
        skikkaTegundTímabils={skikkaTegundTímabils}
        dagurFrá={dagurFrá}
        skikkaDagFrá={skikkaDagFrá}
        dagurTil={dagurTil}
        skikkaDagTil={skikkaDagTil}
        skikkaDeijiTímabils={skikkaDeijiTímabils} 
        skikkaTíma={skikkaTíma}     
      />
      {(völlur && völlur.árnaðarmaður && tími && tími.length === 2) && (
        <>
          {völlur.einkavöllur && <p className="veljaeiginleiks-velja">Verður að senda uppteknarbón á einkavöll</p>}
          <div className="veljaeiginleiks-kassi">
            <ÚtfillíngarHak 
              texti="Senda uppteknarbón" 
              gildi={(sendaUppteknarbón || völlur.einkavöllur)} 
              breitaGildi={skikkaSendaUppteknarbón}
              />
          </div>
        </>
      )}
      <div className="smeija-aðaltakki">
        <VilluHleðurTakki
          textiAðgerðar="Breita firirætlun"
          aðgerð={breitafirirætlun}
          svarMiðlara={svarMiðlara}
          hleður={hleður}
          villa={villa}
        />
      </div>
      {(svarMiðlara && feingnirTímar) && (
        <SkapaðurFastur
          loka={loka}
          feingnirTímar={feingnirTímar}
        />
      )}
    </section>
  )
}

export default BreitaFirirætlun;