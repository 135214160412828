import React, { useEffect, useRef, useState } from "react"
import fáDagsetnínguLesmál from "../../../../gjörníngar/fáDagsetnínguLesmál";
import miðlarabón from "../../../../miðlarabón";
import TakkiAðgerð1 from "../../takkar/takkiAðgerð1/TakkiAðgerð1";
import "./veljatímasetníngar.css"
import "../../../../almenncss/villaoghleður.css";
import "../../../../almenncss/grunncss.css";
import { fáMiðlaravillu } from "../../../../gjörníngar/ímsir";

const fáklsteðamín = (tala) => {
  if (tala === 0) {
    return "00";
  } else {
    return tala;
  }
}

function eigaViðSmell(dagur, klst, mín, stíll, skikkaVöldumTíma, næstidagur) {
  const smellturTími = new Date(dagur.getFullYear(), dagur.getMonth(), dagur.getDate(), klst, mín).getTime();
  if (stíll === "veljatíma-valinntími") {
    //ef ítt er á valinn tíma er afvalið
    skikkaVöldumTíma([]);
  } else if (stíll === "veljatíma-veljanleigir"){
    //valið seinni tímann
    skikkaVöldumTíma(firriValinnTími => [firriValinnTími[0], smellturTími]);
  } else if (stíll !== "veljatíma-upptekinn" && stíll !== "veljatíma-óveljanleigir" && !næstidagur) {
    skikkaVöldumTíma([smellturTími]);
  } 
}

function fáÚtlitEftirVal(dagur, valinnTími, aðalÚtlitslisti) {
  
  const dagurMilli = dagur.getTime();
  const leingdTímalotu = 600000; //10 mín
  const fjöldiLota = 216;
  const tímalotaTíma = (valinnTími[0] - dagurMilli) / leingdTímalotu;
  const hæstaMögulegaLota = tímalotaTíma + 144;
  const síðastaVeljanlegaLota = (hæstaMögulegaLota > 215) ? 215 : hæstaMögulegaLota;
  const útlitslisti = [];

  let fundinnUpptekinn = false;
  for (let i = 0; i < fjöldiLota; i++) {
    if (i < tímalotaTíma) {
      útlitslisti.push("veljatíma-óveljanleigir");
      continue;
    }
    if (fundinnUpptekinn) {
      útlitslisti.push("veljatíma-óveljanleigir");
      continue;
    }
    if (i > síðastaVeljanlegaLota) {
      útlitslisti.push("veljatíma-óveljanleigir");
      fundinnUpptekinn = true;
      continue;
    }
    if (i === tímalotaTíma) {
      útlitslisti.push("veljatíma-valinntími");
      continue;
    }
    if (aðalÚtlitslisti[i] === "veljatíma-upptekinn") {
      fundinnUpptekinn = true;
    }
    útlitslisti.push("veljatíma-veljanleigir");
  }

  return útlitslisti;
}


function fáTímaLotur(dagur, útlitlisti, valinnTími, skikkaVöldumTíma, morgundagur, tímabilsdagar) {
  const divTíma = [];
  let listiStíls;
  if (valinnTími.length === 1) {
    listiStíls = fáÚtlitEftirVal(dagur, valinnTími, útlitlisti);
  } else {
    listiStíls = útlitlisti;
  }
  for (let i = 0; i < 24; i++) {
    let litladiv = (
      <div className="umgjörð-lárétt-ómiðjað" key={i}>
        <div className={`veljatíma-tímabil ${listiStíls[i * 6 + 0]}`} onClick={() => eigaViðSmell(dagur, i, 0, listiStíls[i * 6 + 0], skikkaVöldumTíma)}>{`${fáklsteðamín(i)}:00`}</div>
        <div className={`veljatíma-tímabil ${listiStíls[i*6 + 1]}`} onClick={() => eigaViðSmell(dagur, i, 10, listiStíls[i * 6 + 1], skikkaVöldumTíma)}>{`${fáklsteðamín(i)}:10`}</div>
        <div className={`veljatíma-tímabil ${listiStíls[i*6 + 2]}`} onClick={() => eigaViðSmell(dagur, i, 20, listiStíls[i * 6 + 2], skikkaVöldumTíma)}>{`${fáklsteðamín(i)}:20`}</div>
        <div  className={`veljatíma-tímabil ${listiStíls[i*6 + 3]}`} onClick={() => eigaViðSmell(dagur, i, 30, listiStíls[i * 6 + 3], skikkaVöldumTíma)}>{`${fáklsteðamín(i)}:30`}</div>
        <div className={`veljatíma-tímabil ${listiStíls[i*6 + 4]}`} onClick={() => eigaViðSmell(dagur, i, 40, listiStíls[i * 6 + 4], skikkaVöldumTíma)}>{`${fáklsteðamín(i)}:40`}</div>
        <div className={`veljatíma-tímabil ${listiStíls[i*6 + 5]}`} onClick={() => eigaViðSmell(dagur, i, 50, listiStíls[i * 6 + 5], skikkaVöldumTíma)}>{`${fáklsteðamín(i)}:50`}</div>
      </div>
    );
    divTíma.push(litladiv);
  }
  divTíma.push(<p key={400000} className="veljatíma-næstidagur">{tímabilsdagar ? tímabilsdagar[1] : fáDagsetnínguLesmál(new Date(dagur.getTime() + 86400000))}</p>);
  // 144 = tímahólfSólarhríng
  for (let i = 0; i < 12; i++) {
    let litladiv = (
      <div className="umgjörð-lárétt-ómiðjað" key={i + 24}>
        <div className={`veljatíma-tímabil ${listiStíls[144+i*6 + 0]}`}  onClick={() => eigaViðSmell(morgundagur, i, 0, listiStíls[144+i*6 + 0], skikkaVöldumTíma, true)}>{`${fáklsteðamín(i)}:00`}</div>
        <div className={`veljatíma-tímabil ${listiStíls[144+i*6 + 1]}`} onClick={() => eigaViðSmell(morgundagur, i, 10, listiStíls[144+i*6 + 1], skikkaVöldumTíma, true)}>{`${fáklsteðamín(i)}:10`}</div>
        <div className={`veljatíma-tímabil ${listiStíls[144+i*6 + 2]}`} onClick={() => eigaViðSmell(morgundagur, i, 20, listiStíls[144+i*6 + 2], skikkaVöldumTíma, true)}>{`${fáklsteðamín(i)}:20`}</div>
        <div className={`veljatíma-tímabil ${listiStíls[144+i*6 + 3]}`} onClick={() => eigaViðSmell(morgundagur, i, 30, listiStíls[144+i*6 + 3], skikkaVöldumTíma, true)}>{`${fáklsteðamín(i)}:30`}</div>
        <div className={`veljatíma-tímabil ${listiStíls[144+i*6 + 4]}`} onClick={() => eigaViðSmell(morgundagur, i, 40, listiStíls[144+i*6 + 4], skikkaVöldumTíma, true)}>{`${fáklsteðamín(i)}:40`}</div>
        <div className={`veljatíma-tímabil ${listiStíls[144+i*6 + 5]}`} onClick={() => eigaViðSmell(morgundagur, i, 50, listiStíls[144+i*6 + 5], skikkaVöldumTíma, true)}>{`${fáklsteðamín(i)}:50`}</div>
      </div>
    );
    divTíma.push(litladiv);
  }
  return divTíma;
}

function setjaUppTíma(tímalisti, dagur) {
  const leingdTímalista = tímalisti.length;
  if (leingdTímalista < 1) {
    return;
  }
  const dagurMilli = dagur.getTime();
  const einnoghálfurSólarhríngur = 129600000;
  const lokTímana = dagurMilli + einnoghálfurSólarhríngur;
  const leingdTímalotu = 600000; //10 mín
  const síðastaLota = 216; //firir fjölda tíu mínútna lota á einum og hálfum sólarhríng

  const útlitslisti = [];

  for (let i = 0; i < leingdTímalista; i++) {
    const {tími_frá, tími_til, upptekni} = tímalisti[i];
    const tímiFráDagsetníng = new Date(tími_frá);
    const tímiTilDagsetníng = new Date(tími_til);
    const tímiFráMilli = tímiFráDagsetníng.getTime();
    const tímiTilMilli = tímiTilDagsetníng.getTime();
    const firstaLota = (tímiFráMilli < dagurMilli) ? 0 : (tímiFráMilli - dagurMilli) / leingdTímalotu;
    const seinniLota = (tímiTilMilli > lokTímana) ? síðastaLota : (tímiTilMilli - dagurMilli) / leingdTímalotu;

    const útlit = (upptekni === 2 || upptekni === 4) ? "veljatíma-upptekinn" : "veljatíma-skráður";

    //hafa bara < í stað að hafa -1 við seinniLota
    for (let i = firstaLota; i < seinniLota; i++) {
      const núverandi = útlitslisti[i];
      if (núverandi !== "veljatíma-upptekinn") {
        útlitslisti[i] = útlit;
      }
    }
  
  }

  return útlitslisti;

}


const VeljaTímasetníngar = ({
  dagur, 
  skikkaDeigi, 
  skikkaVöldumTíma, 
  valinnTími, 
  einkiVallar, 
  einkiTímaskráar,
  tímabilsdagar
}) => {
  const [hleður, skikkaHleður] = useState("Hleður...");
  const [villa, skikkaVillu] = useState(null);
  const útlitslisti = useRef([]);

  const morgundagur = new Date(dagur.getFullYear(), dagur.getMonth(), dagur.getDate() + 1);

  useEffect(() => {
    const náíTíma = async () => {
      skikkaHleður("Sækir tíma valins vallar...");

      const spurnTímaskrá = einkiTímaskráar ? `&timaskra=${einkiTímaskráar}` : "";
      
      try {
        const tímarFráMiðlara = await miðlarabón.get(`/vellir/timar/${einkiVallar}?dagur=${dagur.getTime()}${spurnTímaskrá}`);
        const tímalisti = tímarFráMiðlara.data;

        útlitslisti.current = setjaUppTíma(tímalisti, dagur);
        
      } catch (villaMiðlara) {
        if (villaMiðlara.response.status !== 404) {
          skikkaVillu(fáMiðlaravillu(villaMiðlara));
        }
      }
      skikkaHleður(null);
    }
    if (einkiVallar) {
      náíTíma()
    } else {
      skikkaHleður(null);
    }
  }, [dagur, einkiVallar]);


  return (
    <>
    <div className="veljatíma-umgjörð umgjörð-lóðrétt-ómiðjað">

      <p className="veljatíma-dagsetníng-lesmál">
        {tímabilsdagar ? tímabilsdagar[0] : fáDagsetnínguLesmál(dagur)}
      </p>
        {hleður ? (
          <p className="veljatíma-hleður villaoghleður-hleður">{hleður}</p>
        ) : (
          <div className="veljatíma-tímardags umgjörð-lóðrétt-ómiðjað">
            {villa && <p className="veljatíma-villa villaoghleður-villa">{villa}</p>}
            <p className="veljatíma-skilaboð"><span className="villaoghleður-hleður">Gulir reitir:</span> Skipaður leikur á þessum tíma</p>
            <p className="veljatíma-skilaboð"><span className="villaoghleður-villa">Rauðir reitir:</span> Árnaðarmaður vallar hefur gert tíma upptekinn</p>
            {fáTímaLotur(dagur, útlitslisti.current, valinnTími, skikkaVöldumTíma, morgundagur, tímabilsdagar)}
          </div>
        )}
      {!tímabilsdagar && (
        <div className="veljatíma-breitaumdag">
          <TakkiAðgerð1 texti="Breyta dagsetningu" aðgerð={() => { skikkaDeigi(null); skikkaVöldumTíma([]) }} />
        </div>
      )}
    </div>
    </>
  )
}

export default VeljaTímasetníngar;