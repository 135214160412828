import "../../../almenncss/grunncss.css";
import Smeija from "../smeija/Smeija";

const SpurnLiðLeiks = ({spurn, skikkaSpurn}) => {
  
  return (
    <>
      {spurn && (
        <Smeija lokaSmeiju={skikkaSpurn}>
          <p className="smeija-aðaltakki spurn-rammi">Lið leiks: Ef valið er lið leiks í gutlaraleik þá kemur leikurinn upp á síðu liðsins (undir gutlaraleikir) og allir liðsmenn geta memmað leikinn án þess að þeim var boðið.</p>
        </Smeija>
      )}
    </>
  )
}


export default SpurnLiðLeiks;