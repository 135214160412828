import React, { useState } from "react"
import Smeija from "../../smeija/Smeija";
import "./leppurgutlari.css";
import Gutlarasmeija from "../../gutlarasmeija/Gutlarasmeija";
import VilluHleðurTakki from "../../villaoghleður/villuhleðurtakki/VilluHleðurTakki";
import slóðminda from "../../../../slóðminda";

const LeppurGutlari = ({gutlari, textiAðgerðar, aðgerð}) => {
  const [obinGutlari, skikkaObnumGutlara] = useState(false);
  const [svarMiðlara, skikkaSvariMiðlara] = useState(null);
  const [hleður, skikkaHleður] = useState(null);
  const [villa, skikkaVillu] = useState(null);

  if (!gutlari.mind || gutlari.mind === "") {
    gutlari.mind = "171604359020013420.png";
  }
  return (
    <>
      <div className="leppurgutlari-máti">
        <div className="leppurgutlari-myndognafn" onClick={() => skikkaObnumGutlara(true)}>
          <img className="leppurgutlari-mynd-af-máta" alt="mynd af máta" src={`${slóðminda}gutlaramindir/${gutlari.mind}`} />
          <p className="leppurgutlari-nafn-máta">{gutlari.nafn ? gutlari.nafn : gutlari.gælunafn}</p>
        </div>
        {textiAðgerðar && (
          <div className="leppurgutlari-takkakassi">
            <VilluHleðurTakki
              svarMiðlara={svarMiðlara}
              villa={villa}
              hleður={hleður}
              aðgerð={() => aðgerð(gutlari, skikkaSvariMiðlara, skikkaHleður, skikkaVillu)}
              textiAðgerðar={textiAðgerðar}
            />
          </div>
        )}
      </div>
      {obinGutlari && (
        <Smeija lokaSmeiju={skikkaObnumGutlara}>
          <Gutlarasmeija gutlari={gutlari} einkigutlara={gutlari.einki}/>
        </Smeija>
      )}
    </>
  )
}

export default LeppurGutlari;