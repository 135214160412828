import TakkiAðgerð2 from "../../takkar/takkiAðgerð2/TakkiAðgerð2";
import "./villuhleðurtakki.css";
import "../../../../almenncss/villaoghleður.css";


const VilluHleðurTakki = ({
  svarMiðlara, 
  hleður, 
  villa, 
  aðgerð, 
  textiAðgerðar,
}) => {

  return (
    <div className="villuhleðurtakki-umgjörð">
      {villa && (
        <p className="villuhleðurtakki-texti villuhleðurtakki-villa villaoghleður-villa">{villa}</p>
      )}
      {!(svarMiðlara || hleður) && (
        <TakkiAðgerð2 texti={textiAðgerðar} aðgerð={aðgerð}/>
      )}
      {svarMiðlara && (
        <p className="villuhleðurtakki-texti villaoghleður-tókst">{svarMiðlara}</p>
      )}
      {hleður && (
        <p className="villuhleðurtakki-texti villaoghleður-hleður">{hleður}</p>
      )}
    </div>
  )
}


export default VilluHleðurTakki;