import "../../almenncss/tölvuskeitissíður.css"
import "../../almenncss/grunncss.css";
import "../../almenncss/villaoghleður.css";
import { useParams } from "react-router-dom";
import { useState } from "react";
import TakkiAðgerð2 from "../../hlutir/almennirhlutar/takkar/takkiAðgerð2/TakkiAðgerð2";
import ÚtfillíngarTexti from "../../hlutir/almennirhlutar/útfillíngar/útfillíngartexti/ÚtfillíngarTexti.js"
import miðlarabón from "../../miðlarabón";
import { fáMiðlaravillu } from "../../gjörníngar/ímsir.js";


const VeljaNíttNetfáng = () => {
  const [netfáng, skikkaNetfángi] = useState("");
  const [svarFráMiðlara, skikkaSvariFráMiðlara] = useState(null);
  const [villa, skikkaVillu] = useState(null);
  const [hleður, skikkaHleður] = useState(null);

  const {dulmal} = useParams();

  async function sendaNíttNetfáng() {

    skikkaSvariFráMiðlara(null);
    skikkaVillu(null);
    skikkaHleður("Sendir tölvupóst...");
    
    if (!netfáng) {
      skikkaVillu("Þarft að velja netfang");
      skikkaHleður(null);
      return;
    }
    
    try {
      const svarMiðlara = await miðlarabón.post("/gutlaraskraning/breitumnetfangsendanija/" + dulmal, {
        netfáng
      });
      if (svarMiðlara.status === 200) {
        skikkaSvariFráMiðlara(svarMiðlara.data);
      }
    } catch (villaBónar) {
      skikkaVillu(fáMiðlaravillu(villaBónar));
    }
    skikkaHleður(null);
  }


  return (
    <section className="almenn-grunnur tölvuskeitissíða-aðal">
      <h3 className="tölvuskeitissíða-firirsögn">Breyttu um netfang</h3>
      <p className="tölvuskeitissíða-lítilskilaboð">Athugaðu að vefslóðin gildir aðeins í 15 mínútur frá því að tölvupósturinn var sendur til þín</p>
      <div className="tölvuskeitissíða-inntak">
        <ÚtfillíngarTexti 
          texti="Nýtt netfang" 
          gildi={netfáng} 
          breitaGildi={skikkaNetfángi} 
          tegund="email"
        />
      </div>
      {(!(svarFráMiðlara || hleður) || villa) && (
        <div className="tölvuskeitissíða-minnitakki">
          <TakkiAðgerð2 texti="Senda póst á skráð netfang til að staðfesta netfangsbreytingu" aðgerð={sendaNíttNetfáng}/>
        </div>
      )}
      {villa && <p className="tölvuskeitissíða-skilaboðmiðlara villaoghleður-villa">{villa}</p>}
      {hleður && <p className="tölvuskeitissíða-skilaboðmiðlara villaoghleður-hleður">{hleður}</p>}
      {svarFráMiðlara && <p className="tölvuskeitissíða-skilaboðmiðlara villaoghleður-tókst">{svarFráMiðlara}</p>}
    </section>
  )
}

export default VeljaNíttNetfáng;