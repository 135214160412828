import { useEffect, useMemo, useRef, useState } from "react";
import TakkiAðgerð1 from "../takkar/takkiAðgerð1/TakkiAðgerð1";
import "./flettíngarlisti.css";
import Listi from "../listi/Listi";
import TakkiAðgerð2 from "../takkar/takkiAðgerð2/TakkiAðgerð2";
import ÚtfillíngarTexti from "../útfillíngar/útfillíngartexti/ÚtfillíngarTexti.js";
import "../../../almenncss/villaoghleður.css";
import sækjaFlettilista from "../../../gjörníngar/sækjaFlettilista.js";

const FlettíngarListi = ({
  veffáng, 
  tegundLista, 
  breiddLista, 
  textiAðgerðarLista,
  aðgerðLista,
  mestiFjöldiíLista,
  athugunLeitarorðs,
  leit,
  sækjaStrax,
  stærð,
  tími
}) => {
  const [listi, skikkaLista] = useState([]);
  const [birjaListaVísir, skikkaBirjaListaVísi] = useState([0]);
  const [erNæsti, skikkaErNæsti] = useState(false);
  const [villa, skikkaVillu] = useState(null);
  const [hleður, skikkaHleður] = useState(null);
  const leitarorð = useRef("");
  
  const takkarSínileigir = (birjaListaVísir.length > 1 || erNæsti);// && listi.length > 0;
  
  const {hæðListaCSS, aukastærð} = useMemo(() => {
    const aukastærð = stærð ? `-${stærð}` : "";
    
    let hæðListaCSS = "";
    if (takkarSínileigir && leit) {
      hæðListaCSS = "flettíngarlisti-listi-aukaleitogtakkar" + aukastærð;
    } else if (takkarSínileigir) {
      hæðListaCSS = "flettíngarlisti-listi-aukatakkar" + aukastærð;
    } else if (leit) {
      hæðListaCSS = "flettíngarlisti-listi-aukaleit" + aukastærð;
    }


    return {hæðListaCSS, aukastærð}
  }, [takkarSínileigir, stærð, leit])

  
  async function náíLista(notaLeitarorð, firri, næsti, vísalisti) {
    
    sækjaFlettilista(
      notaLeitarorð,
      firri,
      næsti,
      vísalisti,
      skikkaVillu,
      skikkaHleður,
      athugunLeitarorðs,
      erNæsti,
      veffáng,
      mestiFjöldiíLista,
      skikkaBirjaListaVísi,
      skikkaErNæsti,
      skikkaLista,
      tími
    )
    //athuga hér kvort veffángið er það sama
  }


  useEffect(() => {
    //ef það á að ná í lista strax í birjun keirir það hér
    if (!leit || sækjaStrax) {
      náíLista(leitarorð.current, false, false, [0]);
    }
  
  }, [veffáng])


  return (
    <section className="flettíngarlisti-aðal">
      {leit && (
        <>
          <div className={`flettíngarlisti-leit-umgjörð${aukastærð}`}>
            <ÚtfillíngarTexti 
              texti="" gildi={leitarorð.current} 
              breitaGildi={(gildi) => {
                leitarorð.current = gildi
              }}
              staðfestaTakki={() => {
                náíLista(leitarorð.current, false, false, [0]);
              }}
            />
          </div>
          <div className={`flettíngarlisti-leit-takki${aukastærð}`}>
            <TakkiAðgerð2 texti="Leita" aðgerð={() => {
              náíLista(leitarorð.current, false, false, [0]);
            }}/>
          </div>
        </>
      )}
      {(villa || hleður) ? (
        <>
          {villa && <p className="flettíngarlisti-skilaboðmiðlara villaoghleður-villa">{villa}</p>}
          {hleður && <p className="flettíngarlisti-skilaboðmiðlara villaoghleður-hleður">{hleður}</p>}
        </>
      ) : (
        <>
          <div className={`flettíngarlisti-listi ${hæðListaCSS}`}>
            <Listi 
              tegund={tegundLista} 
              breidd={breiddLista} 
              listi={listi} 
              textiAðgerðar={textiAðgerðarLista} 
              aðgerð={aðgerðLista}
            />
          </div>
          {takkarSínileigir && (
            <div className={`flettíngarlisti-takkar${aukastærð}`}>
              {((birjaListaVísir.length > 1 && !erNæsti) || birjaListaVísir.length > 2) && <TakkiAðgerð1 texti="Bakfletta" aðgerð={() => náíLista(leitarorð.current, true, false, birjaListaVísir)}/>}
              {erNæsti && <TakkiAðgerð1 texti="Fletta" aðgerð={() => náíLista(leitarorð.current, false, true, birjaListaVísir)}/>}
            </div>
          )}
        </>
      )}
    </section>
  )
}

export default FlettíngarListi;