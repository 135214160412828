import "./forsíðutakkar.css";
import TakkiAðgerð1 from "../../../../hlutir/almennirhlutar/takkar/takkiAðgerð1/TakkiAðgerð1";
import miðlarabón from "../../../../miðlarabón";
import { fáMiðlaravillu } from "../../../../gjörníngar/ímsir";


const Gutlaraleikjalistar = ({
  skikkaLista,
  sjáKvaðaLista,
  skikkaSjáKvaðaLista,
  gutlaraleikirMeðTíma,
  gutlaraleikjabónirMeðTíma,
  hleður
}) => {
    

  async function memmaLeikMeðTíma(leikur, skikkaSvariMiðlaraAðgerð, skikkaHleðurAðgerð, skikkaVilluAðgerð) {
    skikkaHleðurAðgerð("Memmar...");
    skikkaSvariMiðlaraAðgerð(null);
    skikkaVilluAðgerð(null);

    //tókst er svo það er vitað kvort það þarf að fjarlæja leik úr listanum sem hann er í
    let tókst = false;
    try {
      const svarMiðlara = await miðlarabón.put("/leikir/gutlaraleikur/memmamedbon/" + leikur.einki);
      if (svarMiðlara.status === 200) {
        const listiGutlaraleikir = gutlaraleikirMeðTíma.current.listi;
        const leingdGutlaraleikjalista = listiGutlaraleikir.length;

        //bæta nímemmuðum leik við lista af memmuðum leikjum
        if (leingdGutlaraleikjalista > 0) {
          const nírListiGutlaraleikir = [];
          let leikBættVið = false;
          for (let i = 0; i < leingdGutlaraleikjalista; i++) {
            if (!leikBættVið && listiGutlaraleikir[i].tími_frá > leikur.tími_frá) {
              nírListiGutlaraleikir.push(leikur);
              leikBættVið = true;
            }
            nírListiGutlaraleikir.push(listiGutlaraleikir[i]);
          }
          gutlaraleikirMeðTíma.current.listi = nírListiGutlaraleikir;
        } else {
          gutlaraleikirMeðTíma.current = {
            listi: [leikur],
            erNæsti: false,
            birjaListaVísir: [new Date()],
            villa: null
          }
        }

        tókst = true;
        skikkaSvariMiðlaraAðgerð(svarMiðlara.data);
      }

    } catch (villaBónar) {
      skikkaVilluAðgerð(fáMiðlaravillu(villaBónar));
    }
    skikkaHleðurAðgerð(null);
    return tókst;
  }


  return (
    <>
      <div className="forsíðutakkar-leikjatakkar">
        <div className="forsíðutakkar-leikjatakkakassi-minni">
          <TakkiAðgerð1 
            texti="Næstu leikir" 
            aðgerð={() => {
              if (hleður) {
                return;
              }
              skikkaSjáKvaðaLista("næstugutlaraleikir");
              skikkaLista({
                firirsögn: "Gutlaraleikir á döfinni",
                veffáng: "leikir/gutlaraleikur/saekja/medtima?",
                tegundLista: "leikur",
                breiddLista: 2,
                textiAðgerðarLista: null,
                aðgerðLista: null,
                leit: null,
                efri: gutlaraleikirMeðTíma,
                tími: true
              })
            }} 
            einginnbakgrunnur={sjáKvaðaLista === "næstugutlaraleikir"} 
          />
        </div>
        <div className="forsíðutakkar-leikjatakkakassi-minni">
          <TakkiAðgerð1 
            texti="Boð í leiki" 
            aðgerð={() => {
              if (hleður) {
                return;
              }
              skikkaSjáKvaðaLista("gutlaraleikjabónir");
              skikkaLista({
                firirsögn: "Boð í gutlaraleiki á döfinni",
                veffáng: "leikir/gutlaraleikur/saekja/bonirmedtima?",
                tegundLista: "leikur",
                breiddLista: 2,
                textiAðgerðarLista: "Memma leik",
                aðgerðLista: memmaLeikMeðTíma,
                leit: null,
                efri: gutlaraleikjabónirMeðTíma,
                tími: true
              })
            }} 
            einginnbakgrunnur={sjáKvaðaLista === "gutlaraleikjabónir"}
          />
        </div>
      </div>
    </>
  )
}


export default Gutlaraleikjalistar;