
export function jáEðaNei(tvígildi) {
  if (tvígildi) {
    return "Já"
  } else {
    return "Nei"    
  }
}


export function fáMiðlaravillu(villa) {
  if (villa.code === "ERR_NETWORK") {
    return "Engin nettenging líklegast";
  } else if (villa.response.data) {
    // if (villa.response.data === "Ekki skráður inn!")  {
    //   localStorage.removeItem("gutlari");
    // }
    return villa.response.data;
  } else {
    return "Villa í forriti";
  }
}

//samsvarandi einkar íþróttar í gagnasafni
export function fáÍþrótt(íþrótt) {
  if (!íþrótt) return null;
  if (íþrótt === "Knattspyrna") return 1;
  if (íþrótt === "Körfubolti") return 2;
  if (íþrótt === "Handbolti") return 3;
  if (íþrótt === "Blak") return 4;
  if (íþrótt === "Badmínton") return 5;
  if (íþrótt === "Sundbolti") return 6;
  if (íþrótt === "Íshokkí") return 7;
  if (íþrótt === "Rauðhokkí") return 8;
  if (íþrótt === "Ruðningur") return 9;
  if (íþrótt === "Brennó") return 10;
  if (íþrótt === "Tennis") return 11;
  if (íþrótt === "Dans") return 12;
  if (íþrótt === "Frisbígolf") return 13;
  if (íþrótt === "Þrautagolf") return 14;
  if (íþrótt === "Brettakúnst") return 15;
  if (íþrótt === "Klifur") return 16;
  if (íþrótt === "Þrekþraut") return 17;
  return null;
}

export function fáKinLeiks(kin) {
  if (kin === "Fljóð") return "Meyjaleikur \u2640";
  if (kin === "Gumi") return "Sveinaleikur \u2642";
  return null;
}

export function athugunLeitarorðs(leitarorð) {
  if (leitarorð.length < 1) {
    return "Þarft að slá inn leitarorð";
  }
  return null;
}