import { useState } from "react";
import { fáKlstMínLesmál, vikudagarÞáguFleir } from "../../../../gjörníngar/fáTímaLesmál";
import Smeija from "../../smeija/Smeija";
import Firirætlun from "../../../../síðurnar/lið/hægrilið/firirætlun/Firirætlun";


const LeppurFirirætlun = ({firirætlun}) => {

  const [smeija, skikkaSmeiju] = useState(null);

  const vallarnafn = firirætlun.nafn ? firirætlun.nafn : firirætlun.staður; 

  return (
    <>
      <div className="leppurleikur-leikur" onClick={() => skikkaSmeiju(true)}>
        <p className="leppurleikur-völlur-leiks">{vallarnafn}</p>
        {firirætlun.aukanafn && <p className="leppurleikur-dagsetníng-leiks">{firirætlun.aukanafn} {firirætlun.stærð_undirvallar}</p>}
        {(firirætlun.tegund_tímabils == 1) && (
          <>
            <p className="leppurleikur-tími-leiks">Vikulega</p>
            <p className="leppurleikur-tími-leiks">{vikudagarÞáguFleir[firirætlun.dagur - 1]}</p>
          </>
        )}
        {(firirætlun.tegund_tímabils == 2) && (
          <>
            <p className="leppurleikur-tími-leiks">Mánaðarlega</p>
            <p className="leppurleikur-tími-leiks">{firirætlun.dagur}. hvers mánaðar</p>
          </>
        )}
        <p className="leppurleikur-tími-leiks">{fáKlstMínLesmál(firirætlun.klst_frá, firirætlun.mín_frá, firirætlun.klst_til, firirætlun.mín_til)}</p>

      </div>
      {smeija && (
        <Smeija lokaSmeiju={skikkaSmeiju}>
          <Firirætlun einkiFirirætlunar={firirætlun.einki}/>
        </Smeija>
      )}
    </>
  )
}


export default LeppurFirirætlun;

