import { createContext, useEffect, useState } from "react";

export const ÁrnaðarmaðurSamheingi = createContext();

export const ÁrnaðarmaðurSamheingiUmgjörð = ({children}) => {
  const [árnaðarmaður, skikkaÁrnaðarmanni] = useState(
    JSON.parse(localStorage.getItem("arnadarmadur")) || {netfáng: null}
  );
  const [völlur, skikkaVelli] = useState(null);

  useEffect(() => {
    localStorage.setItem("arnadarmadur", JSON.stringify(árnaðarmaður)); 

  }, [árnaðarmaður.netfáng]);

  return (
    <ÁrnaðarmaðurSamheingi.Provider value={{árnaðarmaður, skikkaÁrnaðarmanni, völlur, skikkaVelli}}>
      {children}
    </ÁrnaðarmaðurSamheingi.Provider>
  )
}