import "../../../almenncss/grunncss.css";
import { fáDagLesmál, fáFráTilLesmál } from "../../../gjörníngar/fáTímaLesmál";
import TakkiAðgerð1 from "../takkar/takkiAðgerð1/TakkiAðgerð1";
import TakkiAðgerð2 from "../takkar/takkiAðgerð2/TakkiAðgerð2";
import "./skapaðurfastur.css";


function sínaTíma(tímar, litur) {
  return tímar.map(tími => {
    const firriTími = new Date(tími[0]);
    const seinniTími = new Date(tími[1]);
    return (
      <div key={tími[0]} className="smeija-lítilltakki umgjörð-lóðrétt">
        <p className={`smeija-lítilltakki skapaðurfastur-${litur}`}>{fáDagLesmál(firriTími)}</p>
        <p className={`smeija-lítilltakki skapaðurfastur-${litur}`}>{fáFráTilLesmál(firriTími, seinniTími)}</p>
      </div>
    )
  });
}

//Til að sína tímaskrár sem voru búnar til firir fastan tíma
const SkapaðurFastur = ({loka, skikkaSjáFastan, feingnirTímar, einkiTíma}) => {

  return (
    <>
      <div className="smeija-aðaltakki">
        <TakkiAðgerð2 texti="Flotter" aðgerð={() => loka(false)}/>
      </div>
      {skikkaSjáFastan && (
        <div className="smeija-aðaltakki">
          <TakkiAðgerð1 texti="Sjá fastan tíma" aðgerð={() => {loka(false); skikkaSjáFastan(einkiTíma)}}/>
        </div>
      )}
      {(feingnirTímar.búnirTil && feingnirTímar.búnirTil.length > 0) && (
        <>
          <p className="smeija-aðaltakki">Tímar búnir til</p>
          {sínaTíma(feingnirTímar.búnirTil, "grænn")}
        </>
      )}
      {(feingnirTímar.uppteknir && feingnirTímar.uppteknir.length > 0) && (
        <>
          <p className="smeija-aðaltakki">Tímar sem eru uppteknir</p>
          {sínaTíma(feingnirTímar.uppteknir, "rauður")}
        </>
      )}
      {(feingnirTímar.tókstEkki && feingnirTímar.tókstEkki.length > 0) && (
        <>
          <p className="smeija-aðaltakki">Tíma sem mistókst að búa til</p>
          {sínaTíma(feingnirTímar.tókstEkki, "rauður")}
        </>
      )}
    </>
  )
}


export default SkapaðurFastur;