import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { GutlariSamheingiUmgjörð } from './samheingi/gutlariSamheingi';
import { ÁrnaðarmaðurSamheingiUmgjörð } from "./samheingi/árnaðarmaðurSamheingi"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 <GutlariSamheingiUmgjörð>
  <ÁrnaðarmaðurSamheingiUmgjörð>
    <App />
  </ÁrnaðarmaðurSamheingiUmgjörð>
 </GutlariSamheingiUmgjörð>
);

// root.render(
//   <React.StrictMode>
//     <GutlariSamheingiUmgjörð>
//       <ÁrnaðarmaðurSamheingiUmgjörð>
//         <App />
//       </ÁrnaðarmaðurSamheingiUmgjörð>
//     </GutlariSamheingiUmgjörð>
//   </React.StrictMode>
// )

