import { useState } from "react";
import TakkiAðgerð1 from "../takkar/takkiAðgerð1/TakkiAðgerð1";
import Vallarbleðill from "../vallarbleðill/Vallarbleðill";
import TakkiAðgerð2 from "../takkar/takkiAðgerð2/TakkiAðgerð2";
import "../../../almenncss/grunncss.css";
import "../../../síðurnar/búatilníjanleik/vinstribúatilníjanleik/vinstribúatilníjanleik.css";
import Smeija from "../smeija/Smeija";
import VeljaVöll from "../veljavöll/VeljaVöll";



const VallarVal = ({
  völlur,
  skikkaVelli,
  staðurLeiks,
  skikkaStaðLeiks,
  tími

}) => {
  const [veljaVöllSmeija, skikkaVeljaVöllSmeiju] = useState(false);
  const [breitaVelli, skikkaBreitaVelli] = useState(false);


  return (
    <>
      {(völlur || staðurLeiks) ? (
        <>
          <div className="vinstribtnl-völlur-kassi">
            {völlur ? (
              <Vallarbleðill völlur={völlur} />
            ) : (
              <p className="vinstribtnl-staðurleiks umgjörð-lárétt">{staðurLeiks}</p> 
            )}
          </div>
          <div className="vinstribtnl-breitaum-takka-kassi">
            <TakkiAðgerð1 texti="Breyta" aðgerð={() => skikkaBreitaVelli(true)} />
          </div>
        </>
      ) : (
        <div className="vinstribtnl-takkakassi">
          <TakkiAðgerð1 texti="Velja Völl" aðgerð={() => skikkaVeljaVöllSmeiju(true)} />
        </div>
      )}
      {veljaVöllSmeija && (
        <Smeija lokaSmeiju={skikkaVeljaVöllSmeiju}>
          <VeljaVöll 
            skikkaVelli={skikkaVelli} 
            skikkaStaðLeiks={skikkaStaðLeiks} 
            tími={tími} 
            lokaSmeiju={skikkaVeljaVöllSmeiju} 
          />
        </Smeija>
      )}
      {breitaVelli && (
        <Smeija lokaSmeiju={skikkaBreitaVelli}>
          <div className="vinstribtnl-breita-umgjörð umgjörð-lóðrétt">
            <TakkiAðgerð2 texti="Breyta velli" aðgerð={() => {
              skikkaVeljaVöllSmeiju(true);
              skikkaBreitaVelli(false);
            }}/>
            <TakkiAðgerð2 texti="Eyða velli" aðgerð={() => {
              skikkaBreitaVelli(false);
              skikkaVelli(null);
              skikkaStaðLeiks("");
            }}/>
          </div>
        </Smeija>
      )}
    </>
  )
}

export default VallarVal;