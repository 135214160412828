import TakkiAðgerð2 from "../../../../../takkar/takkiAðgerð2/TakkiAðgerð2";
import "../../../../../../../almenncss/grunncss.css";
import "../../../../../../../almenncss/villaoghleður.css";
import "../leikurtímaskrá.css";
import { useState } from "react";
import miðlarabón from "../../../../../../../miðlarabón";
import { fáMiðlaravillu } from "../../../../../../../gjörníngar/ímsir";
import Smeija from "../../../../../smeija/Smeija";


export function LeikurTímaskráTakkar({
  upptekni, 
  einki, 
  skikkaBreittriUpptekni, 
  tímaskráFargað, 
  einkavöllur,
  tegundLeiks,
  fastur
}) {
  const [svar, skikkaSvari] = useState(null);
  const [hleður, skikkaHleður] = useState(null);
  const [villa, skikkaVillu] = useState(null);
  const [reinaðEiða, skikkaReinaðEiða] = useState(false);

  const eiðaTexti = fastur ? "Eyða velli úr fyrirætlun" : "Eyða velli úr leik";
  const slóð = fastur ? "fastur" : "timaskra";

  async function jánkaUppteknarbón() {
    skikkaHleður("Jánkar...");

    try {
      const svarMiðlara = await miðlarabón.put(`${slóð}/geraupptekinn/${einki}`);
      skikkaBreittriUpptekni(2);
      skikkaSvari(svarMiðlara.data);
    } catch (villaBónar) {
      if (villaBónar.response && villaBónar.response.data.fargað) {
        tímaskráFargað.current = true;
        villaBónar.response.data = villaBónar.response.data.skilaboð;
      }
      skikkaVillu(fáMiðlaravillu(villaBónar))
    }
    skikkaHleður(null);
  }

  async function sinjaUppteknarbón() {
    skikkaHleður("Sinjar...");

    try {
      const svarMiðlara = await miðlarabón.put(`${slóð}/sinjaupptekni/${einki}`, {tegundLeiks});
      if (einkavöllur) {
        tímaskráFargað.current = true;
      } else {
        skikkaBreittriUpptekni(3);
      }
      skikkaSvari(svarMiðlara.data);
    } catch (villaBónar) {
      skikkaVillu(fáMiðlaravillu(villaBónar))
    }
    skikkaHleður(null);
  }

  async function afturkallaUpptekni() {
    if (einkavöllur) {
      skikkaHleður("Eyðir velli...");
    } else {
      skikkaHleður("Afturkallar...");
    }

    try {
      const svarMiðlara = await miðlarabón.put(`${slóð}/sinjaupptekni/${einki}`, {tegundLeiks, upptekniFjarlægð: true});
      if (einkavöllur) {
        tímaskráFargað.current = true;
      } else {
        skikkaBreittriUpptekni(3);
      }
      skikkaSvari(svarMiðlara.data);
    } catch (villaBónar) {
      skikkaVillu(fáMiðlaravillu(villaBónar))
    }
    skikkaHleður(null);
  }

  async function geraLeikUpptekinn() {
    skikkaHleður("Gerir upptekinn...");

    try {
      const svarMiðlara = await miðlarabón.put(`${slóð}/geraupptekinn/${einki}`);
      skikkaBreittriUpptekni(2);
      skikkaSvari(svarMiðlara.data);
    } catch (villaBónar) {
      if (villaBónar.response && villaBónar.response.data.fargað) {
        tímaskráFargað.current = true;
        villaBónar.response.data = villaBónar.response.data.skilaboð;
      }
      skikkaVillu(fáMiðlaravillu(villaBónar));
    }
    skikkaHleður(null);
  }

  //1 = uppteknarbón; 2 = upptekinn; 3 = skipaður; 4 = ótækur
  let sína = <></>;
  if (upptekni === 1) {
    if (einkavöllur) {
      sína = (
        <div className="leikurtímaskrá-takkar-umgjörð">
          <TakkiAðgerð2 texti="Jánka uppteknarbón" aðgerð={jánkaUppteknarbón}/>
          <TakkiAðgerð2 texti={eiðaTexti} aðgerð={() => skikkaReinaðEiða(true)}/>
        </div>
      )
    } else {
      sína = (
        <div className="leikurtímaskrá-takkar-umgjörð">
          <TakkiAðgerð2 texti="Jánka uppteknarbón" aðgerð={jánkaUppteknarbón}/>
          <TakkiAðgerð2 texti="Sinja uppteknarbón" aðgerð={sinjaUppteknarbón}/>
        </div>
      )
    }
  } else if (upptekni === 2) {
    if (einkavöllur) {
      sína = <TakkiAðgerð2 texti={eiðaTexti} aðgerð={() => skikkaReinaðEiða(true)}/>
    } else {
      sína = <TakkiAðgerð2 texti="Afturkalla upptekni" aðgerð={afturkallaUpptekni}/>
    }
  } else if (upptekni === 3) {
    sína = <TakkiAðgerð2 texti={fastur ? "Gera fyrirætlun upptekna" : "Gera leik upptekinn"} aðgerð={geraLeikUpptekinn}/>
  }


  return (
    <div className="leikurtímaskrá-takkar umgjörð-lóðrétt-ómiðjað">
      {villa && (
        <p className="villaoghleður-villa">{villa}</p>
      )}
      {!(svar || hleður) && (
        <>
          {sína}
          {reinaðEiða && (
            <Smeija lokaSmeiju={skikkaReinaðEiða}>
              <div className="smeija-takki">
                <TakkiAðgerð2 
                  texti="Eyða" 
                  aðgerð={() => {
                    if (upptekni === 1) {
                      sinjaUppteknarbón();
                    } else {
                      afturkallaUpptekni();
                    }
                  }}
                />
              </div>
              <div className="smeija-takki">
                <TakkiAðgerð2 
                  texti="Nei alls ekki"
                  aðgerð={() => skikkaReinaðEiða(false)}
                />
              </div>
            </Smeija>
          )}
        </>
      )}
      {svar && (
        <p className="villaoghleður-tókst">{svar}</p>
      )}
      {hleður && (
        <p className="villaoghleður-hleður">{hleður}</p>
      )}
    </div>
  )
}

export default LeikurTímaskráTakkar;