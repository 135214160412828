import React, { useState } from "react"
import TakkiAðgerð1 from "../../hlutir/almennirhlutar/takkar/takkiAðgerð1/TakkiAðgerð1";
import "./innskráníng.css"
import SkráInn from "./skráinn/SkráInn";
import SmíðaGutlara from "./smíðagutlara/SmíðaGutlara";
import Takki1 from "../../hlutir/almennirhlutar/takkar/takki1/Takki1";


const Innskráníng = () => {
  const [smíðagutlara, skikkaSmíðagutlara] = useState(false);  

  return (
      <main className="innskráníng-umgjörð umgjörð-lóðrétt-ómiðjað">
        {smíðagutlara ? (
          <> 
            <div className="innskráníng-umgjörð-takkar umgjörð-lárétt-ómiðjað">
              <div className="innskráníng-umgjörð-takki">
                <TakkiAðgerð1 texti="Skrá inn" aðgerð={() => skikkaSmíðagutlara(false)}/>
              </div>
            </div>
            <SmíðaGutlara />
          </>
        ) : (
          <>
            <div className="innskráníng-umgjörð-takkar umgjörð-lárétt-ómiðjað">
              <div className="innskráníng-umgjörð-takki">
                <TakkiAðgerð1 texti="Smíða gutlara" aðgerð={() => skikkaSmíðagutlara(true)}/>
              </div>
              <div className="innskráníng-umgjörð-takki">
                <Takki1 texti="Árnaðarmaður" til="/arnadarmadurinnskraning"/>
              </div>
            </div>
            <SkráInn/>
          </>
        )}
      </main>
  )


}

export default Innskráníng;