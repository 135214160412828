import "./veljalið.css";
import FlettíngarListi from "../flettíngartakkar/FlettíngarListi";

const VeljaLið = ({veljaLiðAðgerð}) => {

  return (
    <>
      <p className="veljalið-firirsögn-smeiju">Veldu lið leiks</p>
      <div className="veljalið-listi-ivir-lið">
        <FlettíngarListi
          veffáng="lid/saekja/minlid?"
          tegundLista="liðgögn"
          breiddLista={3}
          textiAðgerðarLista="Velja lið"
          aðgerðLista={veljaLiðAðgerð}
          mestiFjöldiíLista={24}
          leit={true}
          sækjaStrax={true}
          stærð={1}
        />
      </div>
    </>
  )
}

export default VeljaLið;