import React, { useState } from "react"
import { Link, useParams } from "react-router-dom";
import "../leppuralmennleikur/leppuralmennleikur.css";
import VilluHleðurTakki from "../../villaoghleður/villuhleðurtakki/VilluHleðurTakki";
import { bætaNúlliEfÞarf, mánuðir, vikudagar } from "../../../../gjörníngar/fáTímaLesmál";



const LeppurKappleikur = ({ leikur, textiAðgerðar, aðgerð }) => {
  const [svarMiðlara, skikkaSvariMiðlara] = useState("");
  const [hleður, skikkaHleður] = useState(null);
  const [villa, skikkaVillu] = useState(null);

  const tímiFrá = new Date(leikur.tími_frá);
  const tímiTil = new Date(leikur.tími_til);

  const einkiliðsÞarSemKappleikslistiEr = useParams().einkilids;
  const einkiliðsSlóð = einkiliðsÞarSemKappleikslistiEr ? `/${einkiliðsÞarSemKappleikslistiEr}` : "";

  if (textiAðgerðar === "Memma leik" && leikur.mittlið) {
    textiAðgerðar = "Memma leik með " + leikur.mittlið;
  } 

  let vallarnafn;
  if (leikur.nafn) {
    vallarnafn = leikur.nafn;
  } else if (leikur.staður) {
    vallarnafn = leikur.staður;
  } else {
    vallarnafn = "Óákveðinn völlur";
  }

  return (
    <div className="leppurleikur-leikur">
      <Link to={`/kappleikur/${leikur.einki}${einkiliðsSlóð}`} className="leppurleikur-ítáleik">
        <p className="leppurleikur-völlur-leiks">{vallarnafn}</p>
        {leikur.aukanafn && <p className="leppurleikur-dagsetníng-leiks">{leikur.aukanafn} {leikur.stærð_undirvallar}</p>}
        {(leikur.tími_frá && leikur.tími_til) ? (
          <>
            <p className="leppurleikur-dagsetníng-leiks">{`${vikudagar[tímiFrá.getDay()]} ${tímiFrá.getDate()}.${mánuðir[tímiFrá.getMonth()]}`}</p>
            <p className="leppurleikur-tími-leiks">{`${tímiFrá.getHours()}:${bætaNúlliEfÞarf(tímiFrá.getMinutes())} til ${tímiTil.getHours()}:${bætaNúlliEfÞarf(tímiTil.getMinutes())}`}</p>
          </>
        ) : (
          <p className="leppurleikur-tími-leiks">Óákveðinn tími</p>
        )}
        <hr className="leppurleikur-strik"/>
        <p className="leppurleikur-tími-leiks">{leikur.heimalið}</p>
        <p className="leppurleikur-ámóti">á móti</p>
        <p className="leppurleikur-tími-leiks">{leikur.mótherjar}</p>
      </Link>
      {textiAðgerðar && (
        <div className="leppurleikur-takkakassi">
          <VilluHleðurTakki
            svarMiðlara={svarMiðlara}
            villa={villa}
            hleður={hleður}
            aðgerð={() => aðgerð(leikur, skikkaSvariMiðlara, skikkaHleður, skikkaVillu)}
            textiAðgerðar={textiAðgerðar}
          />
        </div>
      )}
    </div>
  )
}

export default LeppurKappleikur;