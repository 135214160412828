import { useState } from "react";
import TakkiAðgerð1 from "../takkar/takkiAðgerð1/TakkiAðgerð1";
import TakkiAðgerð2 from "../takkar/takkiAðgerð2/TakkiAðgerð2";
import "../../../almenncss/grunncss.css";
import "../../../síðurnar/búatilníjanleik/vinstribúatilníjanleik/vinstribúatilníjanleik.css";
import fáTímaLesmál from "../../../gjörníngar/fáTímaLesmál";
import Smeija from "../smeija/Smeija";
import VeljaTíma from "../veljatíma/VeljaTíma";


const Tímaval = ({tími, skikkaTíma, einkiVallar}) => {
  const [veljaTímaSmeija, skikkaVeljaTímaSmeiju] = useState(false);
  const [breitaTíma, skikkaBreitaTíma] = useState(false);

  let dagsetníngLeiks, tímiLeiks;
  if (tími.length === 2) {
    [dagsetníngLeiks, tímiLeiks] = fáTímaLesmál(tími[0], tími[1]);
  }

  return (
    <>
      {(tími.length === 2) ? (
        <>
          <p className="vinstribtnl-tími-dagsetníng">{dagsetníngLeiks}</p>
          <p className="vinstribtnl-tími-tími">{tímiLeiks}</p>
          <div className="vinstribtnl-breitaum-takka-kassi">
            <TakkiAðgerð1 texti="Breyta" aðgerð={() => skikkaBreitaTíma(true)} />
          </div>
        </>
      ) : (
        <div className="vinstribtnl-takkakassi">
          <TakkiAðgerð1 texti="Velja tíma" aðgerð={() => skikkaVeljaTímaSmeiju(true)} />
        </div>
      )}
      {veljaTímaSmeija && (
        <Smeija lokaSmeiju={skikkaVeljaTímaSmeiju}>
          <VeljaTíma 
            lokaSmeiju={skikkaVeljaTímaSmeiju} 
            skikkaTíma={skikkaTíma} 
            einkiVallar={einkiVallar} 
          />
        </Smeija>
      )}
      {breitaTíma && (
        <Smeija lokaSmeiju={skikkaBreitaTíma}>
          <div className="vinstribtnl-breita-umgjörð umgjörð-lóðrétt">
            <TakkiAðgerð2 texti="Breyta um tíma" aðgerð={() => {
              skikkaVeljaTímaSmeiju(true);
              skikkaBreitaTíma(false);
            }}/>
            <TakkiAðgerð2 texti="Eyða tíma" aðgerð={() => {
              skikkaBreitaTíma(false);
              skikkaTíma([]);
            }}/>
          </div>
        </Smeija>
      )}
    </>

  )
}

export default Tímaval;