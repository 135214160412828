import {io} from "socket.io-client";


//const slóðSpjalls = "ws://192.168.0.104:8000";
//netþjónn
const slóðSpjalls = "wss://mannsgaman.is";

//transports er svo það er bara notað websocket, ekki eikvað annað varaforrit líka
export const skeitagaung = io.connect(slóðSpjalls, {
  withCredentials: true, 
  transports: ["websocket"],
  autoConnect: false
});


skeitagaung.on("connect_error", (villa) => {
  if (villa.message === "JsonWebTokenError: jwt malformed") {
    skeitagaung.villa = "Auðkenning mistókst";
    return;
  }
  if (villa.message === "websocket error") {
    skeitagaung.villa = "Miðlari hefur líklega endurræst sig og þess vegna misst teingingu við þig, endurnýjaðu síðuna";
    return;
  }
  if (villa.message === "JsonWebTokenError: invalid token") {
    skeitagaung.villa = "Auðkenning mistókst, líklega miðlara að kenna frekar en þér";
    return;
  }
  if (villa.message === "timeout") {
    skeitagaung.villa = "Spjalltenging rann út, endurnýjaðu síðuna til að endurtengja";
    return;
  }
  //bæta við villu firir timeout
  skeitagaung.villa = villa.message;
});


skeitagaung.on("connect", () => {
  skeitagaung.villa = null;
})
