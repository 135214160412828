import React, { useState } from "react"
import TakkiAðgerð2 from "../takkar/takkiAðgerð2/TakkiAðgerð2";
import ÚtfillíngarTexti from "../../almennirhlutar/útfillíngar/útfillíngartexti/ÚtfillíngarTexti"
import "./veljavöll.css"
import TakkiAðgerð1 from "../takkar/takkiAðgerð1/TakkiAðgerð1";
import LeitVeljaVöll from "./leitveljavöll/LeitVeljaVöll.js";
import FlettíngarListiEfri from "../flettíngartakkar/FlettíngarListiEfri.js";
import "../../../almenncss/grunncss.css";
import { fáÍþrótt } from "../../../gjörníngar/ímsir.js";


function fáTöluUndirlags(undirlag) {
  if (!undirlag) return null;
  if (undirlag === "Gras") return 1
  if (undirlag === "Gervigras") return 2
  if (undirlag === "Malbik") return 3
  if (undirlag === "Möl") return 4
  if (undirlag === "Fjalir") return 5
  if (undirlag === "Gólfdúkur") return 6
  if (undirlag === "Vatn") return 7
  if (undirlag === "Annað") return 8
  if (undirlag === "Rauðdregill") return 9
  if (undirlag === "Vallargrindur") return 10
  if (undirlag === "Sandur") return 11
  if (undirlag === "Pallar") return 12
  return null;
}

function fáTöluTegundar(tegund) {
  if (!tegund) return null;
  if (tegund === "Hverfisvöllur") return 1
  if (tegund === "Skólavöllur") return 2
  if (tegund === "Annað") return 3
  if (tegund === "Félagsvöllur") return 4
  if (tegund === "Einkavöllur") return 5
  return null;
}

function fáUmkverviVallar(útiinni) {
  if (útiinni === "Úti") return true;
  if (útiinni === "Inni") return false;
  return null;
}

function fáStærðVallar(stærð) {
  if (!stærð) return null;
  if (stærð === "Stubblingur") return 1
  if (stærð === "Dverglingur") return 2
  if (stærð === "Þokkalingur") return 3
  if (stærð === "Snotrungur") return 4
  if (stærð === "Drjúgungur") return 5
  if (stærð === "Bosmungur") return 6
  if (stærð === "Fullstór") return 7
  return null;
}


const VeljaVöll = ({ skikkaVelli, skikkaStaðLeiks, tími, tímaskrá, lokaSmeiju, vallasíða }) => {
  
  const [sérvalinnStaður, skikkaSérvöldumStað] = useState("");
  const [sérvelja, skikkaSérvelja] = useState(false);
  //svo ekki er sótt níja velli þegar farið er í sérvelja stað og svo aftur í lista
  const [sækjaStrax, skikkaSækjaStrax] = useState(true);
  const [veffáng, skikkaVeffángi] = useState(`/vellir/saekja/${(tími && tími.length) ? `medtima?tímifrá=${tími[0]}&tímitil=${tími[1]}&` : "antima?"}`);

  const [listi, skikkaLista] = useState([]);
  const [birjaListaVísir, skikkaBirjaListaVísi] = useState([0]);
  const [erNæsti, skikkaErNæsti] = useState(null);
  const [villa, skikkaVillu] = useState(null);
  

  const breitaSérvöldumStað = (staður) => {
    const mestaLeingdáNafniStaðs = 199;
    skikkaSérvöldumStað(firriStaður => {
      if (firriStaður.length >= mestaLeingdáNafniStaðs && staður.length > firriStaður.length) {
        return firriStaður;
      }
      return staður;
    })
  }

  function velja(völlur) {
    if (!vallasíða) {
      if (skikkaStaðLeiks) {
        skikkaStaðLeiks("");
      }
      skikkaVelli(völlur); 
      lokaSmeiju(false); 
    }
  }

  async function sækjaVelli(leitargildi) {
    const spurnarundirlag = leitargildi.undirlag ? "undirlag=" + fáTöluUndirlags(leitargildi.undirlag) + "&" : "";
    const spurnartegund = leitargildi.tegund ? "tegund=" + fáTöluTegundar(leitargildi.tegund) + "&" : "";
    const spurnareinka = leitargildi.einkavöllur ? "einkavöllur=" + leitargildi.einkavöllur + "&" : "";
    const spurnarumkvervi = leitargildi.inniúti ? "umkvervi=" + fáUmkverviVallar(leitargildi.inniúti) + "&" : "";
    const spurnarstærð = leitargildi.stærð ? "stærð=" + fáStærðVallar(leitargildi.stærð) + "&" : "";
    const spurnaríþrótt = leitargildi.íþrótt ? "íþrótt=" + fáÍþrótt(leitargildi.íþrótt) + "&" : "";
    const spurnarþorp = leitargildi.þorp ? "þorp=" + leitargildi.þorp + "&" : "";
    const spurnarundirvellir = leitargildi.undirvellir ? "undirvellir=" + leitargildi.undirvellir + "&" : "";
    const spurnartímaskrá = tímaskrá ? "tímaskrá=" + tímaskrá + "&" : "";
    let spurnarnafn = "";
    let spurnartími = "";
    if (leitargildi.leitarorð) {
      spurnarnafn = "nafn=" + leitargildi.leitarorð + "&";
    }
    if (tími && tími.length === 2) {
      spurnartími = "tímifrá=" + tími[0] + "&tímitil=" + tími[1] + "&";
    }
   
    const reiknaðVeffáng = `/vellir/saekja/${leitargildi.meðEðaÁnTíma}?${spurnartími}${spurnarnafn}${spurnarundirlag}${spurnartegund}${spurnareinka}${spurnarumkvervi}${spurnarstærð}${spurnaríþrótt}${spurnarþorp}${spurnarundirvellir}${spurnartímaskrá}`;
    
    skikkaVeffángi(reiknaðVeffáng);
    skikkaSérvelja(false);
    skikkaSækjaStrax(true);
  }

  return (
    <section className="umgjörð-lárétt-ómiðjað veljavöll-aðal">
      <LeitVeljaVöll
        sækjaVelli={sækjaVelli}
        tími={tími}
      />
      {sérvelja ? (
        <form className="veljavöll-sérveljaútfillíng-umgjörð umgjörð-lóðrétt">
          {!vallasíða && <div className="veljavöll-sérvelja-kassi">
            <TakkiAðgerð1 texti="Leita af velli" aðgerð={() => {
              skikkaSérvelja(false);
              skikkaSækjaStrax(false);
            }} />
          </div>}
          <div className="veljavöll-sérvelja-nafn">
            <ÚtfillíngarTexti 
              texti="Skrifaðu heiti staðar leiks" 
              gildi={sérvalinnStaður} 
              breitaGildi={breitaSérvöldumStað} 
              staðfestaTakki={() => {
                skikkaStaðLeiks(sérvalinnStaður);
                skikkaVelli(null);
                lokaSmeiju(false);
              }}
            />
          </div>
          <div className="veljavöll-leitafvellival-kassi">
            <TakkiAðgerð2 texti="Velja" aðgerð={(v) => {
              v.preventDefault();
              skikkaStaðLeiks(sérvalinnStaður);
              skikkaVelli(null);
              lokaSmeiju(false);
            }} />
          </div>
        </form>
        ) : (
        <div className="veljavöll-firirsögnoglisti umgjörð-lóðrétt-ómiðjað">
          {/* <div className="veljavöll-faraíkort-kassi">
            <TakkiAðgerð1 texti="Fara í kort" aðgerð={() => console.log("fara í kort, og þá til að birja með, sína nágrenni, gróft kort, bara að sjá kvað nágrenninn eru og ekkert mikið nákvæmara")} />
          </div> */}
          {skikkaStaðLeiks && <div className="veljavöll-sérvelja-kassi">
            <TakkiAðgerð1 texti="Sérvelja völl" aðgerð={() => {
              skikkaSérvelja(true);
              skikkaSækjaStrax(false);
            }} />
          </div>}
          <p className="veljavöll-firirsögn">{vallasíða ? "Vellir" : "Velja Völl"}</p>
          <div className="veljavöll-listiafvöllum-kassi">
            <FlettíngarListiEfri
              veffáng={veffáng}
              tegundLista="vellir"
              breiddLista={3}
              textiAðgerðarLista={vallasíða ? null : "Velja völl"}
              aðgerðLista={velja}
              mestiFjöldiíLista={15}
              sækjaStrax={sækjaStrax}
              stærð={1}
              listi={listi}
              skikkaLista={skikkaLista}
              birjaListaVísir={birjaListaVísir}
              skikkaBirjaListaVísi={skikkaBirjaListaVísi}
              erNæsti={erNæsti}
              skikkaErNæsti={skikkaErNæsti}
              villa={villa}
              skikkaVillu={skikkaVillu}
            />
          </div>
        </div>
      )}
    </section>
  )
}

export default VeljaVöll;