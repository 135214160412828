import { createContext, useState } from "react";


export const BúatilleikSamheingi = createContext();

export const BúatilleikSamheingiUmgjörð = ({children}) => {
  const [völlur, skikkaVelli] = useState(null);
  const [tími, skikkaTíma] = useState([]);
  const [gutlarar, skikkaGutlurum] = useState([]);
  const [tegundLeiks, skikkaTegundLeiks] = useState("");
  const [heimalið, skikkaHeimaliði] = useState(null);
  const [kapplið, skikkaKappliði] = useState(null);
  const [staðurLeiks, skikkaStaðLeiks] = useState("");
  
  return (
    <BúatilleikSamheingi.Provider value={{
      völlur, skikkaVelli,
      tími, skikkaTíma,
      gutlarar, skikkaGutlurum,
      tegundLeiks, skikkaTegundLeiks,
      heimalið, skikkaHeimaliði,
      kapplið, skikkaKappliði,
      staðurLeiks, skikkaStaðLeiks,
    }}>
      {children}
    </BúatilleikSamheingi.Provider>
  )
}

