import React from "react"
import "./útfillíngarhak.css"
import "../../../../almenncss/grunncss.css"

const ÚtfillíngarHak = ({texti, gildi, breitaGildi, birtaSmeiju}) => {
  return (
    <label className="hahúh-aðal">
      <p className="hahúh-lesmál">{texti} {birtaSmeiju && (<span onClick={() => birtaSmeiju(true)} className="spurn smellanlegur">?</span>)}</p> 
      <input
        type="checkbox"
        name="inntak"
        className="hahúh-inntak"
        checked={gildi}
        onChange={() => { breitaGildi(!gildi) }}
      />
      <div className="hahúh-hak"></div>
    </label>
  )
}

export default ÚtfillíngarHak;