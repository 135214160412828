import { useState } from "react";
import { fáKinLeiks, fáMiðlaravillu, jáEðaNei } from "../../../../gjörníngar/ímsir";
import Gutlarasmeija from "../../../../hlutir/almennirhlutar/gutlarasmeija/Gutlarasmeija";
import TakkiAðgerð1 from "../../../../hlutir/almennirhlutar/takkar/takkiAðgerð1/TakkiAðgerð1";
import TakkiAðgerð2 from "../../../../hlutir/almennirhlutar/takkar/takkiAðgerð2/TakkiAðgerð2";
import "../../../gutlaraleikur/miðjagutlaraleiks/hiitogþettagutlaraleikur/hittogþettagutlaraleikur.css";
import "../../../gutlaraleikur/miðjagutlaraleiks/ivirgefaleik/ivirgefaleik.css"
import Smeija from "../../../../hlutir/almennirhlutar/smeija/Smeija";
import BreitaEiginleikumFirir from "../breitaeiginleikumfirir/BreitaEiginleikumFirir";
import VilluHleðurTakki from "../../../../hlutir/almennirhlutar/villaoghleður/villuhleðurtakki/VilluHleðurTakki";
import miðlarabón from "../../../../miðlarabón";
import BreitaFirirætlun from "../breitafirirætlun/BreitaFirirætlun.js"

const EiginleikarFirirætlunar = ({
  firirætlun, 
  skikkaFirirætlun,
  einkiFirirætlunar,
  skikkaFirirætlunEitt,
  skikkaBreitt,
  skikkaSjáEiginleika
}) => {

  const [breitaVali, skikkaBreitaVali] = useState(false);
  const [breitaVelliTíma, skikkaBreitaVelliTíma] = useState(false);
  const [eiðaFirirætlunSmeija, skikkaEiðaFirirætlunSmeija] = useState(false);
  const [sínaLeikstefni, skikkaSínaLeikstefni] = useState(false);

  const [villaEiða, skikkaVilluEiða] = useState(null);
  const [hleðurEiða, skikkaHleðurEiða] = useState(null);


 async function eiðaFirirætlun() {
    skikkaVilluEiða(null);
    skikkaHleðurEiða("Verið að eyða fyrirætlun...");
    try {
      const eittFirirætlun = await miðlarabón.delete(`/firiraetlanir/eida/${einkiFirirætlunar}`);
      if (eittFirirætlun.status === 200) {
        skikkaFirirætlunEitt(true);
      }
    } catch (villaBónar) {
      skikkaVilluEiða(fáMiðlaravillu(villaBónar));
    }
    skikkaHleðurEiða(null);
  }


  return (
    <section className="hittogþettaGL-aðal umgjörð-lóðrétt-ómiðjað">
      <h2 className="hittogþettaGL-firirsögn">Eiginleikar fyrirætlunar</h2>
      <div className="hittogþettaGL-umgjörð-eiginleika">
        <div className="hittogþettaGL-kassi-eiginleika">
          <p className="hittogþettaGL-nafn-eiginleika">Aðgengi:</p>
          <p className="hittogþettaGL-eiginleiki">{firirætlun.aðgeingi}</p>
        </div>
        <div className="hittogþettaGL-kassi-eiginleika">
          <p className="hittogþettaGL-nafn-eiginleika">Aðrir mega bjóða:</p>
          <p className="hittogþettaGL-eiginleiki">{jáEðaNei(firirætlun.aðrir_meiga_bjóða)}</p>
        </div>
        <div className="hittogþettaGL-kassi-eiginleika">
          <p className="hittogþettaGL-nafn-eiginleika">Mesti fjöldi gutlara:</p>
          <p className="hittogþettaGL-eiginleiki">{firirætlun.mesti_fjöldi_gutlara}</p>
        </div>
        {(firirætlun.hæsti_aldur || firirætlun.erStefnir) && <div className="hittogþettaGL-kassi-eiginleika">
          <p className="hittogþettaGL-nafn-eiginleika">Hæsti aldur:</p>
          <p className="hittogþettaGL-eiginleiki">{firirætlun.hæsti_aldur}</p>
        </div>}
        {(firirætlun.lægsti_aldur || firirætlun.erStefnir) && <div className="hittogþettaGL-kassi-eiginleika">
          <p className="hittogþettaGL-nafn-eiginleika">Lægsti aldur:</p>
          <p className="hittogþettaGL-eiginleiki">{firirætlun.lægsti_aldur}</p>
        </div>}
        {(firirætlun.kin || firirætlun.erStefnir) && <div className="hittogþettaGL-kassi-eiginleika">
          <p className="hittogþettaGL-nafn-eiginleika">Kyn:</p>
          <p className="hittogþettaGL-eiginleiki">{fáKinLeiks(firirætlun.kin)}</p>
        </div>}
        <div className="hittogþettaGL-kassi-eiginleika">
          <p className="hittogþettaGL-nafn-eiginleika">Forrit skiftir í lið:</p>
          <p className="hittogþettaGL-eiginleiki">{jáEðaNei(firirætlun.skifta_í_lið)}</p>
        </div>
          <div className="hittogþettaGL-kassi-eiginleika">
            <p className="hittogþettaGL-nafn-eiginleika">Ætlunarstefnir:</p> 
            {firirætlun.erStefnir ? 
              <p className="hittogþettaGL-eiginleiki">Ég</p>
            :
              (
                <div className="hittogþettaGL-ervöllurlaus">
                  <TakkiAðgerð1 texti="Sjá ætlunarstefni" aðgerð={() => skikkaSínaLeikstefni(true)}/>
                </div>
              )
            }
          </div>
          <div className="hittogþettaGL-kassi-eiginleika">
            <p className="hittogþettaGL-nafn-eiginleika">Leiksmæli:</p>
            <p className="hittogþettaGL-eiginleiki">{firirætlun.athugasemd}</p>
          </div>
          <div className="hittogþettaGL-kassi-eiginleika">
            <p className="hittogþettaGL-nafn-eiginleika">Leikháttur fyrirætlunar:</p>
            <p className="hittogþettaGL-eiginleiki">{firirætlun.leikháttur}</p>
          </div>   
          <div className="hittogþettaGL-kassi-eiginleika">
            <p className="hittogþettaGL-nafn-eiginleika">Gera saman eftir leik:</p>
            <p className="hittogþettaGL-eiginleiki">{firirætlun.gera_saman_eftir_leik}</p>
          </div> 
          {firirætlun.erStefnir && (
            <div className="hittogþettaGL-neðritakkar">
              <div className="hittogþettaGL-neðritakki">
                <TakkiAðgerð2 texti="Breyta vali" aðgerð={() => skikkaBreitaVali(true)}/>
              </div>
              <div className="hittogþettaGL-neðritakki">
                <TakkiAðgerð2 texti="Breyta velli og/eða tíma" aðgerð={() => skikkaBreitaVelliTíma(true)}/>
              </div>
              <div className="hittogþettaGL-neðritakki">
                <TakkiAðgerð1 texti="Eyða fyrirætlun" aðgerð={() => skikkaEiðaFirirætlunSmeija(true)}/>
              </div>
            </div>
          )} 
      </div>
      {sínaLeikstefni && (
        <Smeija lokaSmeiju={skikkaSínaLeikstefni}>
          <Gutlarasmeija 
            einkigutlara={firirætlun.ætlunarstefnir}
          />
        </Smeija>
      )}
      {breitaVali && (
        <Smeija lokaSmeiju={skikkaBreitaVali}>
          <BreitaEiginleikumFirir
            firirætlun={firirætlun} 
            skikkaFirirætlun={skikkaFirirætlun} 
            einkiFirirætlunar={einkiFirirætlunar}
            skikkaBreitaVali={skikkaBreitaVali}
          />
        </Smeija>
      )}
      {breitaVelliTíma && (
        <Smeija lokaSmeiju={skikkaBreitaVelliTíma}>
          <BreitaFirirætlun
            skikkaBreitt={skikkaBreitt}
            loka={skikkaSjáEiginleika}
            einkiFirirætlunar={einkiFirirætlunar}
            skikkaFirirætlun={skikkaFirirætlun}
          />
        </Smeija>
      )}
      {eiðaFirirætlunSmeija && (
        <Smeija lokaSmeiju={skikkaEiðaFirirætlunSmeija}>
          <div className="ivirgefaleik-umgjörð umgjörð-lóðrétt-ómiðjað"> 
            <div className="ivirgefaleik-bóntakki">
              <VilluHleðurTakki
                villa={villaEiða}
                hleður={hleðurEiða}
                aðgerð={eiðaFirirætlun}
                textiAðgerðar="EIÐA FIRIRÆTLUN"
              />
            </div>
            {!hleðurEiða && (
              <div className="ivirgefaleik-bóntakki">
                <TakkiAðgerð2 
                  texti="Neih djööö, ég ætla ekkert að fara að eiða þessari firirætlun, ertu frá þér" 
                  aðgerð={() => skikkaEiðaFirirætlunSmeija(false)}
                />
              </div>
            )}
          </div>
        </Smeija>
      )}
    </section>

  )
}


export default EiginleikarFirirætlunar;