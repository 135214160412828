import { useEffect, useState } from "react";
import Takki1 from "../../hlutir/almennirhlutar/takkar/takki1/Takki1";
import "../opnirleikir/opnirleikir.css";
import "../../almenncss/villaoghleður.css";
import "../../almenncss/grunncss.css";
import FlettíngarListiEfri from "../../hlutir/almennirhlutar/flettíngartakkar/FlettíngarListiEfri.js";
import { fáMiðlaravillu } from "../../gjörníngar/ímsir.js";
import miðlarabón from "../../miðlarabón.js";
import LiðVal from "./liðval/LiðVal.js";


function fáStöðu(staða) {
  if (staða === "Memmaður") return 1;
  if (staða === "Boðinn") return 2;
  if (staða === "Sent memmbón") return 5;
  if (staða === "Firirliði") return 0;
  if (staða === "Synjað boði") return 3;
  return 1;
}

const MínLið = () => {
  
  const [lið, skikkaLiðum] = useState([]);
  const [erNæsti, skikkaErNæsti] = useState(null);
  const [vísalisti, skikkaVísalista] = useState([0]);
  const [villa, skikkaVillu] = useState(null);

  const [vefslóð, skikkaVefslóð] = useState("");
  const [memmaAðgerð, skikkaMemmaAðgerð] = useState(false);

  const mestiFjöldiíLista = 24;


  async function sækjaLið(
    leitarorð,
    staða,
  ) {

    let valinStaða = fáStöðu(staða);

    const slóð = `/lid/saekja/leit?staða=${valinStaða}&nafn=${leitarorð}&`;
  
    const hafaMemmaðgerð = valinStaða === 2 || valinStaða === 3;

    skikkaVefslóð(slóð); 
    skikkaMemmaAðgerð(hafaMemmaðgerð);
  } 


  async function memmaLið(liðAðgerð, skikkaSvariMiðlaraAðgerð, skikkaHleðurAðgerð, skikkaVilluAðgerð) {
    skikkaHleðurAðgerð("Memmar...");
    skikkaSvariMiðlaraAðgerð(null);
    skikkaVilluAðgerð(null);
    try {
      const svar = await miðlarabón.put("/lid/memmamedbon/" + liðAðgerð.einki);
      if (svar.status === 200) {
        skikkaSvariMiðlaraAðgerð(svar.data);
      }
    } catch (villaBónar) {
      skikkaVilluAðgerð(fáMiðlaravillu(villaBónar));
    }
    skikkaHleðurAðgerð(null);
  }


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])



  return (
    <section className="opnirleikir-aðal">
      <div className="almenn-heimtakki">
        <Takki1 texti="Heim" til="/"/>
      </div>
      <h1 className="opnirleikir-firirsögn">Mín lið</h1> 
      <div className="opnirleikir-umgjörð">
        <LiðVal
          sækjaLið={sækjaLið}
        />
        <div className="opnirleikir-listarafleikjum">
          <div className="opnirleikir-listi-umgjörð">
            <div className="opnirleikir-stærrilisti2">
              <FlettíngarListiEfri
                veffáng={vefslóð}
                tegundLista="lið"
                breiddLista={2}
                textiAðgerðarLista={memmaAðgerð ? "Memma lið" : null}
                aðgerðLista={memmaLið}
                mestiFjöldiíLista={mestiFjöldiíLista}
                stærð={1}
                listi={lið}
                skikkaLista={skikkaLiðum}
                birjaListaVísir={vísalisti} 
                skikkaBirjaListaVísi={skikkaVísalista}
                erNæsti={erNæsti}
                skikkaErNæsti={skikkaErNæsti}
                villa={villa}
                skikkaVillu={skikkaVillu}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MínLið;