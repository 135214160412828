import TakkiAðgerð2 from "../../../hlutir/almennirhlutar/takkar/takkiAðgerð2/TakkiAðgerð2";
import ÚtfillíngarMöguleikar from "../../../hlutir/almennirhlutar/útfillíngar/útfillíngarmöguleikar/ÚtfillíngarMöguleikar";
import ÚtfillíngarTexti from "../../../hlutir/almennirhlutar/útfillíngar/útfillíngartexti/ÚtfillíngarTexti.js";
import Smeija from "../../../hlutir/almennirhlutar/smeija/Smeija";
import "../../../hlutir/almennirhlutar/veljatíma/veljatímasetníngar/veljatímasetníngar.css";
import "../../opnirleikir/opnirleikirval/opnirleikirval.css";
import "../../../almenncss/grunncss.css";
import { useState } from "react";


const LiðVal = ({
  sækjaLið
}) => {

  const [leitarorð, skikkaLeitarorði] = useState("");
  const [staða, skikkaStöðu] = useState("Memmaður");

  const [spurn, skikkaSpurn] = useState(false);

  return (
    <>
      <div className="opnirleikir-valkostir">
        <h3 className="opnirleikir-leita-firirsögn">Leit</h3>
        <div className="opnirleikir-valkostir-listi">
          
          <div className="opnirleikir-valkostur">
            <div className="opnirleikir-útfillíng">
              <ÚtfillíngarTexti 
                texti="Nafn liðs" 
                gildi={leitarorð} 
                breitaGildi={skikkaLeitarorði} 
                staðfestaTakki={() => {
                  sækjaLið(
                    leitarorð,
                    staða
                  );
                }} />
            </div>
          </div>
          <div className="opnirleikir-valkostur">
            <div className="opnirleikir-útfillíng">
              <ÚtfillíngarMöguleikar 
                texti="Staða" 
                gildi={staða} 
                breitaGildi={skikkaStöðu} 
                möguleikar={["Memmaður", "Boðinn", "Sent memmbón", "Firirliði", "Synjað boði"]}
                ósleppanlegt={true}
                birtaSmeiju={skikkaSpurn}
              />
            </div>
          </div>
          
        </div>
        <div className="opnirleikir-leita">
          <TakkiAðgerð2 texti="Leita" aðgerð={() => {
            sækjaLið(
              leitarorð,
              staða,
            )
          }}
          />
        </div>
      </div>
      
      {spurn && (
        <Smeija lokaSmeiju={skikkaSpurn}>
          <p className="smeija-aðaltakki spurn-rammi">Memmaður: Lið sem þú ert meðlimur í</p>
          <p className="smeija-aðaltakki spurn-rammi">Sent memmbón: Lið sem þú hefur sent á beiðni um að fá að vera hluti af liði</p>
        </Smeija>
      )}
    </>
  )
}


export default LiðVal;