import React, { useEffect, useState } from "react";
import "../leikjanautar.css";
import miðlarabón from "../../../miðlarabón";
import { fáMiðlaravillu } from "../../../gjörníngar/ímsir";
import FlettíngarListiEfri from "../../../hlutir/almennirhlutar/flettíngartakkar/FlettíngarListiEfri";

const MínirLeikjanautar = ({níjirLeikjanautar}) => {
  const [leikjanautar, skikkaLeikjanautum] = useState([]);
  const [erNæsti, skikkaErNæsti] = useState(false);
  const [vísalisti, skikkaVísalista] = useState([0])
  const [villa, skikkaVillu] = useState(null);

  async function slítaLeikjanautabandi(gutlari, skikkaSvariMiðlara, skikkaHleður, skikkaVillu) {
    skikkaHleður("Slítir...");
    skikkaSvariMiðlara(null);
    skikkaVillu(null);
    
    try {
      const svar = await miðlarabón.delete("/leikjanautar/slitaleikjanautabandi/" + gutlari.einki);
      if (svar.status === 200) {
        skikkaSvariMiðlara(svar.data);
      }
    } catch (villaBónar) {
      skikkaVillu(fáMiðlaravillu(villaBónar));
    }
    skikkaHleður(null);
  }

  useEffect(() => {
    if (níjirLeikjanautar.length > 0) {
      skikkaLeikjanautum(firriLeikjanautar => [...firriLeikjanautar, níjirLeikjanautar[níjirLeikjanautar.length - 1]])
    }
  }, [níjirLeikjanautar.length])

  return (
    <div className="leikjanautar-listi">
      <h3 className="leikjanautar-listi-firirsögn">Mínir leikjanautar</h3>
      <div className="leikjanautar-listi-listi">
        <FlettíngarListiEfri
          veffáng="leikjanautar/minirleikjanautar?" 
          tegundLista="gutlarar" 
          breiddLista={3} 
          textiAðgerðarLista="Slíta böndum" 
          aðgerðLista={slítaLeikjanautabandi}
          mestiFjöldiíLista={24}
          leit={true}
          sækjaStrax={true}
          listi={leikjanautar}
          skikkaLista={skikkaLeikjanautum}
          erNæsti={erNæsti}
          skikkaErNæsti={skikkaErNæsti}
          birjaListaVísir={vísalisti}
          skikkaBirjaListaVísi={skikkaVísalista}
          villa={villa}
          skikkaVillu={skikkaVillu}
        />
      </div>
    </div>
  )
}

export default MínirLeikjanautar;