import React from "react"
import "./spjallkassiárn.css"
import "../../spjall/spjallkassi/spjallkassi.css";

const SpjallKassi = ({nafn, lesmál, hægri}) => {
  if (hægri) {
    return (
      <div className="spjallkassi-spjallkassi spjallkassi-hægri">
        <p className="spjallkassiárn-nafnspjallanda">Ek</p>
        <p className="spjallkassiárn-lesmál">{lesmál}</p>
      </div>
    )
  } else {
    return (
      <>
        <div className="spjallkassi-spjallkassi spjallkassi-vinstri">
          <p className="spjallkassiárn-nafnspjallanda">{nafn}</p>
          <p className="spjallkassiárn-lesmál">{lesmál}</p>
        </div>
      </>
    )
  }
}

export default SpjallKassi;