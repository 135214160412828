//upphafsástand firir lista flettíngarlista

export const upphafslisti = {
  listi: [],
  erNæsti: false,
  birjaListaVísir: [0],
  villa: null
}

export const upphafslistiMeðTíma = {
  listi: [],
  erNæsti: false,
  birjaListaVísir: [[new Date(), 0]],
  villa: null
};

export const sólarhríngur = 86400000;

export const íþróttir = [
  "Knattspyrna", 
  "Körfubolti", 
  "Blak", 
  "Handbolti", 
  "Badmínton", 
  "Sundbolti", 
  "Íshokkí", 
  "Rauðhokkí", 
  "Ruðningur", 
  "Brennó", 
  "Tennis", 
  "Dans",
  "Frisbígolf",
  "Þrautagolf",
  "Brettakúnst",
  "Klifur",
  "Þrekþraut"
]