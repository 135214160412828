import React from "react"
import "./heim.css"
import HægriHægriHeim from "./hægrihægriheim/HægriHægriHeim";
import AðalHeim from "./aðalheim/AðalHeim";

const Heim = () => {

  return (
    <main className="heim-aðal"> 
      <AðalHeim/>
      <HægriHægriHeim/>
    </main>
  )
}

export default Heim;