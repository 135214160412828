import "../../../almenncss/grunncss.css";
import Smeija from "../smeija/Smeija";

const SpurnUppteknarbón = ({spurn, skikkaSpurn}) => {
  
  return (
    <>
      {spurn && (
        <Smeija lokaSmeiju={skikkaSpurn}>
          <p className="smeija-aðaltakki spurn-rammi">Ef hakað er hér við þá er sent beiðni á árnaðarmann vallar um að gera völlinn upptekinn fyrir tíma leiks, ef hann samþykkir geta engir aðrir skipulagt leik á sama tíma á þessum velli</p>
        </Smeija>
      )}
    </>
  )
}


export default SpurnUppteknarbón;