import Takki1 from "../../hlutir/almennirhlutar/takkar/takki1/Takki1";
import VeljaVöll from "../../hlutir/almennirhlutar/veljavöll/VeljaVöll";
import "./vellir.css";
import "../../almenncss/grunncss.css";
import { useEffect } from "react";

const Vellir = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <section className="almenn-grunnur">
      <div className="vellir-heimtakki">
        <Takki1 texti="Heim" til="/"/>
      </div>
      <VeljaVöll vallasíða={true}/>
    </section>
  )
}

export default Vellir;