import React, { useState } from "react"
import { Link } from "react-router-dom"
import Smeija from "../../smeija/Smeija"
import "./leppurlið.css"
import Liðasmeija from "../../liðasmeija/Liðasmeija"
import VilluHleðurTakki from "../../villaoghleður/villuhleðurtakki/VilluHleðurTakki"
import LiðsmennMemmaðirSmeija from "../../liðsmennmemmaðirsmeija/LiðsmennMemmaðirSmeija"
import slóðminda from "../../../../slóðminda"


function fáSmeiju(tegund, lið, erÁrnaðarmaður) {
  if (tegund === "liðsgögn") {
    return <Liðasmeija lið={{lið}} erÁrnaðarmaður={erÁrnaðarmaður}/>
  }
  if (tegund === "liðsmenníleik") {
    return <LiðsmennMemmaðirSmeija lið={lið}/>
  }
}


const LeppurLið = ({ lið, textiAðgerðar, aðgerð, smeija }) => {
  const [svarMiðlara, skikkaSvariMiðlara] = useState(null);
  const [sínaSmeiju, skikkaSínaSmeiju] = useState(false); 
  const [hleður, skikkaHleður] = useState(null);
  const [villa, skikkaVillu] = useState(null);

  let skjaldamerki = "170809081376912242.png";
  if (lið.skjaldamerki) {
    skjaldamerki = lið.skjaldamerki;
  }

  const mindognafn = (
    <>
      <img className="leppurlið-mynd-af-liði" alt="mynd liðs" src={`${slóðminda}lidamindir/${skjaldamerki}`} />
      <p className="leppurlið-nafn-liðs">{lið.nafn}</p>
    </>
  )

  return (
    <>
      <div key={lið.einki} className="leppurlið-lið">
        {smeija ? (
          <div className="leppurlið-myndognafn" onClick={() => {
            skikkaSínaSmeiju(true); 
          }}>
            {mindognafn}
          </div>
        ) : (
          <Link to={`/lid/${lið.einki}`} className="leppurlið-myndognafn">
            {mindognafn}
          </Link>
        )}
        {textiAðgerðar && (
          <div className="leppurlið-takkakassi">
            <VilluHleðurTakki
              svarMiðlara={svarMiðlara}
              villa={villa}
              hleður={hleður}
              aðgerð={() => aðgerð(lið, skikkaSvariMiðlara, skikkaHleður, skikkaVillu)}
              textiAðgerðar={textiAðgerðar}
            />
          </div>
        )}
      </div>
      {sínaSmeiju && (
        <Smeija lokaSmeiju={skikkaSínaSmeiju}>
          {/* síðasta inntakið er svo ég veit kvort hann er árnaðarmaður */}
          {fáSmeiju(smeija, lið, textiAðgerðar === "Velja þetta lið")}
        </Smeija>
      )}
    </>
  )
}

export default LeppurLið;