import { Suspense, lazy, useContext, useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from "react-router-dom";
import { skeitagaung } from "./skeitagaung.js";
import Vellir from "./síðurnar/vellir/Vellir.js";
import Heima from "./síðurnar/heim/Heim.js";
//import BúaTilNíjanLeik from "./síðurnar/búatilníjanleik/BúaTilNíjanLeik.js";
//import BúaTilLið from "./síðurnar/búatillið/BúaTilLið.js";
//import Lið from "./síðurnar/lið/Lið"
//import Stillíngar from "./síðurnar/stillíngar/Stillíngar.js";
import Innskráníng from "./síðurnar/innskráníng/Innskráníng.js";
import { GutlariSamheingi } from "./samheingi/gutlariSamheingi.js";
import { ÁrnaðarmaðurSamheingi } from "./samheingi/árnaðarmaðurSamheingi.js";
import Leikjanautar from "./síðurnar/leikjanautar/Leikjanautar.js";
//import Gutlaraleikur from "./síðurnar/gutlaraleikur/Gutlaraleikur.js";
import { BúatilliðSamheingiUmgjörð } from "./samheingi/búatilliðSamheingi.js";
//import Kappleikur from "./síðurnar/kappleikur/Kappleikur.js";
import { BúatilleikSamheingiUmgjörð } from "./samheingi/búatilleikSamheingi.js";
import OpnirLeikir from "./síðurnar/opnirleikir/OpnirLeikir.js";
import StaðfestaGutlarasmíð from "./síðurnar/staðfestagutlarasmíð/StaðfestaGutlarasmíð.js";
import GleimtLikilorð from "./síðurnar/gleimtlikilorð/GleimtLikilorð.js";
import VeljaNíttNetfáng from "./síðurnar/veljaníttnetfáng/VeljaNíttNetfáng.js";
import FallastáNetfángsbreitíngu from "./síðurnar/fallastánetfángsbreitíngu/FallastáNetfángsbreitíngu.js";
//import EiðaLeikjum from "./síðurnar/eiðaleikjum/EiðaLeikjum.js";
import Varasíða from "./síðurnar/varasíða/Varasíða.js";
//import Um from "./síðurnar/um/Um.js";
//import Hugmindir from "./síðurnar/hugmindir/Hugmindir.js";
//import Orðaforði from "./síðurnar/orðaforði/Orðaforði.js";
import ÁrnaðarInnskráníng from "./síðurnar/árnaðarinnskráníng/ÁrnaðarInnskráníng.js";
import Hleður from "./síðurnar/hleður/Hleður.js";
import MínirLeikir from "./síðurnar/mínirleikir/MínirLeikir.js";
import MínLið from "./síðurnar/mínlið/MínLið.js";
import SvaraBoði from "./síðurnar/svaraboði/SvaraBoði.js";
//const Leikjanautar = lazy(() => import("./síðurnar/leikjanautar/Leikjanautar.js"));
//const OpnirLeikir = lazy(() => import("./síðurnar/opnirleikir/OpnirLeikir.js"));
const BúaTilNíjanLeik = lazy(() => import("./síðurnar/búatilníjanleik/BúaTilNíjanLeik.js"));
const BúaTilLið = lazy(() => import("./síðurnar/búatillið/BúaTilLið.js"));
const Lið = lazy(() => import("./síðurnar/lið/Lið"));
const Stillíngar = lazy(() => import("./síðurnar/stillíngar/Stillíngar.js"));
//const Vellir = lazy(() => import("./síðurnar/vellir/Vellir.js"));
const Gutlaraleikur = lazy(() => import("./síðurnar/gutlaraleikur/Gutlaraleikur.js"));
const Kappleikur = lazy(() => import("./síðurnar/kappleikur/Kappleikur.js"));
const Árnaðarmaður = lazy(() => import("./síðurnar/árnaðarmaður/Árnaðarmaður.js"));
const Orðaforði = lazy(() => import("./síðurnar/orðaforði/Orðaforði.js"));
const Um = lazy(() => import("./síðurnar/um/Um.js"));
const Hugmindir = lazy(() => import("./síðurnar/hugmindir/Hugmindir.js"));
//import Árnaðarmaður from "./síðurnar/árnaðarmaður/Árnaðarmaður.js";


function App() {
  const {gutlari} = useContext(GutlariSamheingi);
  const {árnaðarmaður} = useContext(ÁrnaðarmaðurSamheingi);

  const SkjölduðSlóð = ({children}) => {
    if (!gutlari.gutlaranafn) {
      return <Navigate to="/innskraning"/>
    }

    return children;
  }

  const SkjölduðSlóðÁrnaðarmaður = ({children}) => {
    if (!árnaðarmaður.netfáng) {
      return <Navigate to="/arnadarmadurinnskraning"/>
    }

    return children;
  }

  useEffect(() => {
    if (gutlari.gutlaranafn && !skeitagaung.connected) {
      skeitagaung.villa = null;
      skeitagaung.connect();
    }

    return () => {
      if (skeitagaung.connected) {
        skeitagaung.disconnect();
        skeitagaung.villa = null;
      }
    }
  }, [gutlari.gutlaranafn])


  const beinir = createBrowserRouter([
    {
      path: "/",
      element: (
        <SkjölduðSlóð>
          <Heima />
        </SkjölduðSlóð>
      )
    },
    {
      path: "/buatilnijanleik",
      element: (
        <SkjölduðSlóð>
          <BúatilleikSamheingiUmgjörð>
            <BúaTilNíjanLeik />            
          </BúatilleikSamheingiUmgjörð>
        </SkjölduðSlóð>
      )
    },
    {
      path: "/lid/:einkilids",
      element: (
        <SkjölduðSlóð>
          <Lið />
        </SkjölduðSlóð>
      )
    },
    {
      path: "/gutlaraleikur/:einkileiks",
      element: (
        <SkjölduðSlóð>
          <Gutlaraleikur/>
        </SkjölduðSlóð>
      ),
      children: [
        {
          path: ":einkilids",
          element: (
            <SkjölduðSlóð>
              <Gutlaraleikur/>
            </SkjölduðSlóð>
          )
        }
      ]
    },
    {
      path: "/kappleikur/:einkileiks",
      element: (
        <SkjölduðSlóð>
          <Kappleikur/>
        </SkjölduðSlóð>
      ),
      children: [
        {
          path: ":einkilids",
          element: (
            <SkjölduðSlóð>
              <Kappleikur/>
            </SkjölduðSlóð>
          )
        }
      ]
    },
    {
      path: "/buatillid",
      element: (
        <SkjölduðSlóð>
          <BúatilliðSamheingiUmgjörð>
            <BúaTilLið />
          </BúatilliðSamheingiUmgjörð>
        </SkjölduðSlóð>
      )
    },
    {
      path: "/minirleikir",
      element: (
        <SkjölduðSlóð>
          <MínirLeikir />
        </SkjölduðSlóð>
      )
    },
    {
      path: "/minlid",
      element: (
        <SkjölduðSlóð>
          <MínLið/>
        </SkjölduðSlóð>
      )
    },
    {
      path: "/stillingar",
      element: (
        <SkjölduðSlóð>
          <Stillíngar />
        </SkjölduðSlóð>
      )
    },
    {
      path: "/leikjanautar",
      element: (
        <SkjölduðSlóð>
          <Leikjanautar />
        </SkjölduðSlóð>
      )
    },
    {
      path: "/sjaleik/:einkileiks/:likill",
      element: <Gutlaraleikur/>
    },
    {
      path: "/svarabodi/:einkileiks/:likill",
      element: <SvaraBoði/>
    },
    {
      path: "/innskraning/:slembitala",
      element: <StaðfestaGutlarasmíð/>
    },
    {
      path: "/innskraning",
      element: <Innskráníng />
    },
    {
      path: "/vellir",
      element: <Vellir />
    },
    {
      path: "/opnirleikir",
      element: <OpnirLeikir/>
    },
    {
      path: "/gleimtlikilord/:adgangur/:dulmal",
      element: <GleimtLikilorð/>
    },
    {
      path: "/veljanittnetfang/:dulmal",
      element: <VeljaNíttNetfáng/>
    },
    {
      path: "/fallastanetfangsbreitingu/:dulmal",
      element: <FallastáNetfángsbreitíngu/>
    },
    // {
    //   path: "/eidaleikjum",
    //   element: <EiðaLeikjum/>
    // },
    {
      path: "/um",
      element: <Um/>
    },
    {
      path: "/hugmindir",
      element: <Hugmindir/>
    },
    {
      path: "/ordafordi",
      element: <Orðaforði/>
    },
    {
      path: "/arnadarmadurinnskraning",
      element: <ÁrnaðarInnskráníng/>
    },
    {
      path: "/arnadarmadur",
      element: (
        <SkjölduðSlóðÁrnaðarmaður>
          <Árnaðarmaður/>
        </SkjölduðSlóðÁrnaðarmaður>
      )
    },
    {
      path: "/*",
      element: <Varasíða/>
    }
  ]);


  return (

    <div>
      <Suspense fallback={<Hleður/>}>
        <RouterProvider router={beinir} />
      </Suspense>
    </div>
    
    );
  }
  
  export default App;
  