import { useEffect, useState } from "react";
import "./gutlarasmeija.css";
import miðlarabón from "../../../miðlarabón";
import "../../../almenncss/villaoghleður.css";
import "../../../almenncss/grunncss.css";
import VilluHleðurTakki from "../villaoghleður/villuhleðurtakki/VilluHleðurTakki";
import { fáMiðlaravillu } from "../../../gjörníngar/ímsir";
import slóðminda from "../../../slóðminda";


const Gutlarasmeija = ({gutlari, einkigutlara}) => {
  const [gutlaragögn, skikkaGutlaragögnum] = useState(null);
  const [hleður, skikkaHleður] = useState(einkigutlara ? "Sækir meiri upplýsingar..." : null);
  const [villa, skikkaVillu] = useState(null);

  const [bónSvar, skikkaBónSvar] = useState(null);
  const [bónHleður, skikkaBónHleður] = useState(null);
  const [bónVilla, skikkaBónVilla] = useState(null);

  useEffect(() => {
    async function náíGutlaragögn() {
      try {
        const miðlaragögn = await miðlarabón.get("/leikjanautar/fagutlara/" + einkigutlara);
        if (miðlaragögn.status === 200) {
          const gögn = miðlaragögn.data;
          let ástand = null;
          if (gögn.erég) {
            if (gögn.mind) {
              ástand = "Öldungis tilkomumikil þessi mynd!";
            } else {
              ástand = "Mæli með að bæta við sætri mynd";
            }
          } else if (gögn.ástand === "Jánkað") {
            ástand = "Þið eruð hinir mestu leikjanautar"; 
          } else if (gögn.ástand === "Ósvarað") {
            ástand = "Leikjanautabón er ósvöruð";
          } else if (gögn.ástand === "Sinjað") {
            ástand = "Leikjanautabóninni var sinjað";
          } else if (gögn.ástand === "árnaðarband") {
            ástand = "Mektargutlari þessi";
          }
          gögn.ástandSkilaboð = ástand;
          skikkaGutlaragögnum(gögn);
        }
      } catch (villaMiðlara) {
        skikkaVillu(fáMiðlaravillu(villaMiðlara));
      }
      skikkaHleður(null);
    }
    
    if (einkigutlara) {
      náíGutlaragögn();
    }
  }, [einkigutlara])

  async function sendaLeikjanautabón() {
    skikkaBónHleður("Sendir leikjanautabón...");
    skikkaBónSvar(null);
    skikkaBónVilla(null);
    
    try {
      const svar = await miðlarabón.post("/leikjanautar/sendaleikjanautabon/" + einkigutlara);
      if (svar.status === 200) {
        skikkaBónSvar(svar.data);
      }
    } catch (villaMiðlara) {
      skikkaBónVilla(fáMiðlaravillu(villaMiðlara));
    }
    skikkaBónHleður(null);
  }
  
  return (
    <section className="gutlarasmeija-aðal umgjörð-lóðrétt-ómiðjað">
      {gutlaragögn ? (
        <>
          <p className="gutlarasmeija-nafn">{gutlaragögn.skírnarnafn && gutlaragögn.skírnarnafn} {gutlaragögn.eftirnafn && gutlaragögn.eftirnafn}</p>
          <p className="gutlarasmeija-gælunafn">{gutlaragögn.gælunafn && gutlaragögn.gælunafn}</p>
          <p className="gutlarasmeija-gælunafn">{`Gutlaranafn: ${gutlaragögn.gutlaranafn && gutlaragögn.gutlaranafn}`}</p>
          {gutlaragögn.ástandSkilaboð ? (
            <p className="gutlarasmeija-leikjanautaskilaboð">{gutlaragögn.ástandSkilaboð}</p>
          ) : (
            <div className="gutlarasmeija-gælunafn">
              <VilluHleðurTakki
                svarMiðlara={bónSvar}
                hleður={bónHleður}
                villa={bónVilla}
                aðgerð={sendaLeikjanautabón}
                textiAðgerðar="Senda Leikjanautabón"
              />
            </div>
          )}
          <img src={`${slóðminda}gutlaramindir/${gutlaragögn.mind ? gutlaragögn.mind : "171604359020013420.png"}`} className="gutlarasmeija-mindafgutlara" alt="mynd af gutlara"/>
        </>
      ) : (
        <>
          {gutlari && gutlari.gælunafn && <p className="gutlarasmeija-gælunafn">{gutlari.gælunafn}</p>}
          {gutlari && gutlari.nafn && (
            <>
              <p className="gutlarasmeija-gælunafn">{gutlari.nafn}</p>
              <p className="gutlarasmeija-leikjanautaskilaboð">Sérskráður í leik</p>
            </>
          )}
          {villa && <p className="gutlarasmeija-gælunafn villaoghleður-villa">{villa}</p>}
          {hleður && <p className="gutlarasmeija-gælunafn villaoghleður-hleður">{hleður}</p>}
          {(gutlari && !gutlari.einginmind) && <img src={`${slóðminda}gutlaramindir/${gutlari.mind ? gutlari.mind : "171604359020013420.png"}`} className="gutlarasmeija-mindafgutlara" alt="mynd af gutlara"/>}
        </>
      )}
    </section>
  )
}

export default Gutlarasmeija;