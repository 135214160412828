import React from "react"
import { Link } from "react-router-dom";
import "./takki1.css"

const Takki1 = ({ texti, til}) => {
  

  return (
    <Link to={til} className="takki1">{texti}</Link>
  )
}

export default Takki1;