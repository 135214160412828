import bónarhjálp from "axios";
import slóðmiðlara from "./slóðmiðlara";

const miðlarabón = bónarhjálp.create({
  baseURL: `${slóðmiðlara}/midlarasjomli/`,
  withCredentials: true
}); 


export default miðlarabón;
