import { useContext, useEffect, useRef, useState } from "react";
import {ÁrnaðarmaðurSamheingi} from "../../../../../../samheingi/árnaðarmaðurSamheingi.js";
import { fáMiðlaravillu } from "../../../../../../gjörníngar/ímsir";
import miðlarabón from "../../../../../../miðlarabón.js";
import "../leikurtímaskrá/leikurtímaskrá.css";
import "../../../../../../almenncss/grunncss.css";
import "../../../../../../almenncss/villaoghleður.css";
import { fáDagLesmál, fáDagLesmálÞágu, fáFráTilLesmál } from "../../../../../../gjörníngar/fáTímaLesmál.js";
import TakkiAðgerð1 from "../../../../takkar/takkiAðgerð1/TakkiAðgerð1.js";
import VilluHleðurTakki from "../../../../villaoghleður/villuhleðurtakki/VilluHleðurTakki.js";
import { sólarhríngur } from "../../../../../../almenngögn.js";
import Smeija from "../../../../smeija/Smeija.js";
import ÓtækurFasturSmeija from "../../../../smeijur/ótækurfastursmeija/ÓtækurFasturSmeija.js"
import { fáUpptekni } from "../../LeppurTímaskrá.js";
import VeljaGutlara from "../../../../veljagutlara/VeljaGutlara.js";



const ÓtækurTímaskrá = ({tímaskrá, skikkaTímaskrá}) => {
  const [nafnVallar, skikkaNafniVallar] = useState(null);
  const [hleður, skikkaHleður] = useState(null);
  const [villa, skikkaVillu] = useState(null);
  const [svarTakki, skikkaSvarTakki] = useState(null);
  const [hleðurTakki, skikkaHleðurTakki] = useState(null);
  const [villaTakki, skikkaVillaTakki] = useState(null);
  const [sjáFastan, skikkaSjáFastan] = useState(false);
  
  const [veljaGutlara, skikkaVeljaGutlara] = useState(false);
  
  //const [sjáGutlara, skikkaSjáGutlara] = useState(false);
  const tímaskráFargað = useRef(false);



  const {völlur} = useContext(ÁrnaðarmaðurSamheingi);

  const tímiFrá = new Date(tímaskrá.tími_frá);
  const tímiTil = new Date(tímaskrá.tími_til);

  const leingd = tímiTil.getTime() - tímiFrá.getTime()
  const erTímabil = leingd > sólarhríngur;
  const erSólarhríngur = leingd === sólarhríngur;
  const upptekni = fáUpptekni(tímaskrá.upptekni);

  async function eiðaÓtækumTíma() {
    skikkaHleðurTakki("Eiðir ótækum tíma");
    const einkiTímaskráar = tímaskrá.aðaltími ? tímaskrá.aðaltími : tímaskrá.einki;
    try {
      const svarMiðlara = await miðlarabón.delete(`timaskra/eidatimaskra/${einkiTímaskráar}`);
      tímaskráFargað.current = true;
      skikkaSvarTakki(svarMiðlara.data);
    } catch (villaBónar) {
      skikkaVillaTakki(fáMiðlaravillu(villaBónar));
    }
    skikkaHleðurTakki(null);
  }

  useEffect(() => {

    async function náíGögnTímaskráar() {
      skikkaHleður("Sækir völl tíma")
      try {
        const náíGögn = await miðlarabón.get(`timaskra/favoll/${tímaskrá.aðaltími}`);
        skikkaNafniVallar(náíGögn.data);
      } catch (villaBónar) {
        skikkaVillu(fáMiðlaravillu(villaBónar));
      }
      skikkaHleður(null);
    }

    if (!nafnVallar && tímaskrá.aðaltími && !tímaskrá.fargað) {
      náíGögnTímaskráar();
    }

    //uppfæra á leppi ef breitt var einkverju
    return () => {
      if (tímaskráFargað.current) {
        skikkaTímaskrá(firriTímaskrá => {
          const uppfærðTímaskrá = {...firriTímaskrá};
          uppfærðTímaskrá.fargað = tímaskráFargað.current;
          return uppfærðTímaskrá;
        })
      }
    }

  }, [tímaskrá.einki])

  return (
    <section className="leikurtímaskrá-aðal umgjörð-lóðrétt-ómiðjað">
      {nafnVallar ? (
        <h3 className="smeija-firirsögn">{nafnVallar.nafn} {nafnVallar.aukanafn} {nafnVallar.stærð_undirvallar}</h3>
      ) : (
        <h3 className="leikurtímaskrá-firirsögn">{völlur.nafn} {völlur.aukanafn} {völlur.stærð_undirvallar}</h3>  
      )}
      {hleður && <p className="leikurtímaskrá-firirsögn villaoghleður-hleður">{hleður}</p>}
      {villa && <p className="leikurtímaskrá-firirsögn villaoghleður-villa">{villa}</p>}
      {tímaskrá.fargað  && <p className="leikurtímaskrá-firirsögn villaoghleður-villa">Tímaskrá hefur verið fargað</p>}
      {erTímabil && (
        <>
          <p className="leikurtímaskrá-tími">{fáDagLesmál(tímiFrá)}</p>
          <p className="leikurtímaskrá-tími">Til og með</p>
          <p className="leikurtímaskrá-tími">{fáDagLesmálÞágu(new Date(tímiTil.getTime() - 1))}</p>
        </>
      )}
      {erSólarhríngur && (
        <>
          <p className="leikurtímaskrá-tími">{fáDagLesmál(tímiFrá)}</p>
          <p className="leikurtímaskrá-tími">Allan sólarhrínginn</p>
        </>
      )}
      {(!erTímabil && !erSólarhríngur) && (
        <>
          <p className="leikurtímaskrá-tími">{fáDagLesmál(tímiFrá)}</p>
          <p className="leikurtímaskrá-tími">{fáFráTilLesmál(tímiFrá, tímiTil)}</p>
        </>
      )}
      <p className="leikurtímaskrá-gögn">{upptekni}</p>
      {!tímaskrá.fargað && (
        <div className="leikurtímaskrá-takkar umgjörð-lóðrétt-ómiðjað">
          <VilluHleðurTakki
            svarMiðlara={svarTakki}
            hleður={hleðurTakki}
            villa={villaTakki}
            textiAðgerðar={(upptekni === "Ótækur") ? "Eyða ótækum tíma" : "Eyða tímaskrá fasts tíma"}
            aðgerð={eiðaÓtækumTíma}
          />
        </div>
      )}
      {/* sjá til þess að ekki er hægt að búa til leik úr aukatíma, bara þegar það er aðaltími */}
      {(upptekni === "Ótækur" && !tímaskrá.aðaltími && !erTímabil) && (
        <div className="leikurtímaskrá-gögn">
          <TakkiAðgerð1 texti="Búa til gutlaraleik úr ótækum tíma" aðgerð={() => skikkaVeljaGutlara(true)}/>
        </div>
      )}
      {tímaskrá.áætlun && (
        <div className="leikurtímaskrá-gögn">
          <TakkiAðgerð1 texti="Fara í fastan tíma" aðgerð={() => skikkaSjáFastan(true)}/>
        </div>
      )}
      {sjáFastan && (
        <Smeija lokaSmeiju={skikkaSjáFastan}>
          <ÓtækurFasturSmeija einkiFastsTíma={tímaskrá.áætlun}/>
        </Smeija>
      )}
      {veljaGutlara && (
        <Smeija lokaSmeiju={skikkaVeljaGutlara}>
          <VeljaGutlara skikkaTímaskrá={skikkaTímaskrá} einkiTímaskráar={tímaskrá.einki}/>
        </Smeija>
      )}
    </section>
  )

}

export default ÓtækurTímaskrá;