import { createContext, useState } from "react";


export const BúatilliðSamheingi = createContext();

export const BúatilliðSamheingiUmgjörð = ({children}) => {
  const [nafnliðs, skikkaNafniLiðs] = useState("");
  const [leikjanautar, skikkaLeikjanautum] = useState([]);
  const [skjaldamerki, skikkaSkjaldamerki] = useState("");
  const [litir, skikkaLitum] = useState(["#5499d2", "#1e3468"]);
  const [liturValinn, skikkaLiturValinn] = useState(false);
  const [annað, skikkaÖðru] = useState({
    lísíng: "",
    aðgeingi: "Sérlið"
  });




  return (
    <BúatilliðSamheingi.Provider value={{
      nafnliðs, skikkaNafniLiðs,
      leikjanautar, skikkaLeikjanautum,
      skjaldamerki, skikkaSkjaldamerki,
      litir, skikkaLitum,
      liturValinn, skikkaLiturValinn,
      annað, skikkaÖðru
    }}>
      {children}
    </BúatilliðSamheingi.Provider>
  )
}
