import { useEffect, useState } from "react";
import Takki1 from "../../hlutir/almennirhlutar/takkar/takki1/Takki1";
import "../opnirleikir/opnirleikir.css";
import "../../almenncss/villaoghleður.css";
import "../../almenncss/grunncss.css";
import FlettíngarListiEfri from "../../hlutir/almennirhlutar/flettíngartakkar/FlettíngarListiEfri.js";
import { fáMiðlaravillu, fáÍþrótt } from "../../gjörníngar/ímsir.js";
import LeikirVal from "./leikirval/LeikirVal.js";
import miðlarabón from "../../miðlarabón.js";



const upphafsvísalisti = [[new Date(), 0]]


function fáVísalista(ótímasettur, raðaEftirLiði) {
  if (ótímasettur) {
    if (raðaEftirLiði) {
      return [[0,0]];
    }
    return [0];
  }

  if (raðaEftirLiði) {
    return [[new Date(), 0, 0]];
  }
  return [[new Date(), 0]];

}

function fáStöðu(staða) {
  if (staða === "Memmaður") return 1;
  if (staða === "Boðinn") return 2;
  if (staða === "Sent memmbón") return 5;
  if (staða === "Geiri") return 0;
  if (staða === "Synjað boði") return 3;
  return 1;
}

const MínirLeikir = () => {
  
  const [leikir, skikkaLeikjum] = useState([]);
  const [erNæsti, skikkaErNæsti] = useState(null);
  const [vísalisti, skikkaVísalista] = useState(upphafsvísalisti);
  const [villa, skikkaVillu] = useState(null);

  const [vefslóð, skikkaVefslóð] = useState("");
  const [meðTíma, skikkaMeðTíma] = useState(false);
  const [meðMittLið, skikkaMeðMittLið] = useState(false);
  const [erGutlaraleikur, skikkaErGutlaraleikur] = useState(true);
  const [memmaAðgerð, skikkaMemmaAðgerð] = useState(false);

  const mestiFjöldiíLista = 24;


  async function sækjaLeiki(
    tegundLeiks,
    staða,
    búnir,
    ótímasettir,
    leikháttur, 
    efriMörkTíma, 
    neðriMörkTíma,
    þorp,
    völlur
  ) {


    if (efriMörkTíma && neðriMörkTíma && efriMörkTíma < neðriMörkTíma) {
      skikkaVillu("Ekkert vit í völdu tímabili");
      return;
    }


    let valinLeikháttur = fáÍþrótt(leikháttur);

    let valinStaða = fáStöðu(staða);

    let valiðBúnir = búnir;
    if (valinStaða === 2 || valinStaða === 3) {
      búnir = false;
    }

    const slóð = `/leikir/gutlaraleikur/saekja/minir?tegund=${tegundLeiks}&staða=${valinStaða}&búnir=${valiðBúnir}&otimasettir=${ótímasettir}&leikháttur=${valinLeikháttur}&efritími=${efriMörkTíma}&neðritími=${neðriMörkTíma}${þorp ? `&þorp=${þorp.gildi}` : ""}${völlur ? `&völlur=${völlur.einki}` : ""}&`;

    
    const erKappleikur = tegundLeiks === "Kappleikur";
    const raðaEftirLiði = erKappleikur && valinStaða && !valiðBúnir;
    const hafaMemmaðgerð = valinStaða === 2 || valinStaða === 3;

    skikkaVefslóð(slóð); 
    //bæta við að athugað er kvort er kappleikur og án geira og þá er vísalisti öðruvísi og öðruvísi ef það á við og líka án tíma
    //til að seija flettíngarlista kvort raðað sé eftir tíma
    skikkaVísalista(fáVísalista(ótímasettir, raðaEftirLiði));
    skikkaMeðTíma(!ótímasettir);
    skikkaMeðMittLið(raðaEftirLiði);
    skikkaErGutlaraleikur(!erKappleikur);
    skikkaMemmaAðgerð(hafaMemmaðgerð);
  }

  async function memmaLeik(leikur, skikkaSvariMiðlaraAðgerð, skikkaHleðurAðgerð, skikkaVilluAðgerð) {
    skikkaHleðurAðgerð("Memmar...");
    skikkaSvariMiðlaraAðgerð(null);
    skikkaVilluAðgerð(null);

    let memmslóð;
    if (erGutlaraleikur) {
      memmslóð = "/leikir/gutlaraleikur/memmamedbon/" + leikur.einki;
    } else {
      if (!leikur.mittliðeinki) {
        skikkaHleðurAðgerð(null);
        skikkaVilluAðgerð("Ekkert lið til að memma með");
      }
      memmslóð = "/leikir/kappleikur/memmamedbon/" + leikur.einki + "/" + leikur.mittliðeinki
    }

    try {
      const svarMiðlara = await miðlarabón.put(memmslóð);
      if (svarMiðlara.status === 200) {
        skikkaSvariMiðlaraAðgerð(svarMiðlara.data);
      }

    } catch (villaBónar) {
      skikkaVilluAðgerð(fáMiðlaravillu(villaBónar));
    }
    skikkaHleðurAðgerð(null);
  }  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])


  return (
    <section className="opnirleikir-aðal">
      <div className="almenn-heimtakki">
        <Takki1 texti="Heim" til="/"/>
      </div>
      <h1 className="opnirleikir-firirsögn">Mínir leikir</h1> 
      <div className="opnirleikir-umgjörð">
        <LeikirVal
          sækjaLeiki={sækjaLeiki}
        />
        <div className="opnirleikir-listarafleikjum">
          <div className="opnirleikir-listi-umgjörð">
            <div className="opnirleikir-stærrilisti2">
              <FlettíngarListiEfri
                veffáng={vefslóð}
                tegundLista={erGutlaraleikur ? "leikur" : "kappleikur"}
                breiddLista={3}
                textiAðgerðarLista={memmaAðgerð ? "Memma leik" : null}
                aðgerðLista={memmaLeik}
                mestiFjöldiíLista={mestiFjöldiíLista}
                stærð={1}
                listi={leikir}
                skikkaLista={skikkaLeikjum}
                birjaListaVísir={vísalisti} 
                skikkaBirjaListaVísi={skikkaVísalista}
                erNæsti={erNæsti}
                skikkaErNæsti={skikkaErNæsti}
                villa={villa}
                skikkaVillu={skikkaVillu}
                tími={meðTíma}
                liðsröðun={meðMittLið}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MínirLeikir;