
import { useState } from "react";
import { athugunLeitarorðs, fáMiðlaravillu } from "../../../gjörníngar/ímsir";
import FlettíngarListi from "../flettíngartakkar/FlettíngarListi";
import "./veljagutlara.css";
import "../../../almenncss/grunncss.css";
import "../../../síðurnar/gutlaraleikur/miðjagutlaraleiks/ivirgefaleik/ivirgefaleik.css";
import slóðminda from "../../../slóðminda";
import TakkiAðgerð2 from "../takkar/takkiAðgerð2/TakkiAðgerð2";
import VilluHleðurTakki from "../villaoghleður/villuhleðurtakki/VilluHleðurTakki";
import miðlarabón from "../../../miðlarabón";

const VeljaGutlara = ({einkiTímaskráar, skikkaTímaskrá}) => {

  const [valinnGutlari, skikkaVöldumGutlara] = useState(null);

  const [hleður, skikkaHleður] = useState(null);
  const [villa, skikkaVillu] = useState(null);

  //búa til leik eða firirætlun úr tímaskrá/föstum tíma
  async function búaTil() {
    skikkaVillu(null);
    skikkaHleður("Býr til leik...");
    try {
      await miðlarabón.post("timaskra/buatilleik", {einkiTímaskráar, einkiGutlara: valinnGutlari.einki});
      skikkaTímaskrá(firriTímaskrá => {
        const uppfærðTímaskrá = {...firriTímaskrá};
        uppfærðTímaskrá.upptekni = 2;
        uppfærðTímaskrá.tegund_leiks = 1;
        return uppfærðTímaskrá;
      })
    } catch (villaBónar) {
      skikkaVillu(fáMiðlaravillu(villaBónar))
      skikkaHleður(null);
    }
  }

  return (
    <>
      <h3 className="smeija-aðaltakki">Velja leikstefni leiks</h3>
      {valinnGutlari ? (
        <>
          <p className="ivirgefaleik-valinnleikstefnir-titill">Valinn leikstefnir</p>
          <div className="ivirgefaleik-leiksstefnisskjöldur">
            <img src={`${slóðminda}gutlaramindir/${valinnGutlari.mind ? valinnGutlari.mind : "171604359020013420.png"}`} className="ivirgefaleik-leiksstefnismind" alt="nýr geiri leiks"/>
            <p className="ivirgefaleik-leiksstefnisnafn">{valinnGutlari.gælunafn}</p>
            <TakkiAðgerð2 texti="Vil ekki velja þennan gutlara" aðgerð={() => skikkaVöldumGutlara(null)}/>
          </div>
          <div className="smeija-takki">
            <VilluHleðurTakki
              hleður={hleður}
              villa={villa}
              aðgerð={búaTil}
              textiAðgerðar="Búa til leik úr ótækum tíma"
            />
          </div>
        </>
      ) : (
        <div className="veljagutlara-listi">
          <FlettíngarListi 
            veffáng="leikjanautar/leitafleikjanautum?" 
            tegundLista="gutlarar" 
            breiddLista={3} 
            textiAðgerðarLista="Velja"
            aðgerðLista={(gutlari) => skikkaVöldumGutlara(gutlari)} 
            mestiFjöldiíLista={24}
            athugunLeitarorðs={athugunLeitarorðs} 
            leit={true}
          />
        </div>
      )}
    </>
  )
}

export default VeljaGutlara;