import React, { useContext, useEffect, useState } from "react"
import "./skráinn.css"
import "../../../almenncss/grunncss.css";
import ÚtfillingarTexti from "../../../hlutir/almennirhlutar/útfillíngar/útfillíngartexti/ÚtfillíngarTexti.js";
import { GutlariSamheingi } from "../../../samheingi/gutlariSamheingi";
import { ÁrnaðarmaðurSamheingi } from "../../../samheingi/árnaðarmaðurSamheingi.js";
import { useNavigate } from "react-router-dom";
import TakkiAðgerð1 from "../../../hlutir/almennirhlutar/takkar/takkiAðgerð1/TakkiAðgerð1.js";
import miðlarabón from "../../../miðlarabón";
import VilluHleðurTakki from "../../../hlutir/almennirhlutar/villaoghleður/villuhleðurtakki/VilluHleðurTakki";
import { fáMiðlaravillu } from "../../../gjörníngar/ímsir.js";

const SkráInn = () => {
  const [einkennisnafn, skikkaEinkennisnafni] = useState("");
  const [likilorð, skikkaLikilorði] = useState("");
  const [villa, skikkaVillu] = useState(null);
  const [hleður, skikkaHleður] = useState(null);
  const [fillaInnGleimtLikilorð, skikkaFillaInnGleimtLikilorð] = useState(false);
  const [gleimtLikilorðNetfáng, skikkaGleimtLikilorðNetfáng] = useState("");
  const [gleimtSvarMiðlara, skikkaGleimtSvarMiðlara] = useState(null);
  const [gleimtHleður, skikkaGleimtHleður] = useState(null);
  const [gleimtVilla, skikkaGleimtVillu] = useState(null);

  const { gutlari, skikkaGutlara } = useContext(GutlariSamheingi);
  const { skikkaÁrnaðarmanni } = useContext(ÁrnaðarmaðurSamheingi);
  const sendaÞángað = useNavigate();
  
  async function skráInn() {
    skikkaVillu(null);
    skikkaHleður("Innskráir...");
    const lágstafaeinkennisnafn = einkennisnafn.toLowerCase();
    try {
      const svar = await miðlarabón.post("gutlaraskraning/innskraning", {
        einkennisnafn: lágstafaeinkennisnafn,
        likilorð: likilorð
      });
      skikkaÁrnaðarmanni({netfáng: null});
      skikkaGutlara(svar.data);
    } catch (villaMiðlara) {
      skikkaVillu(fáMiðlaravillu(villaMiðlara));
      skikkaHleður(null);
    }
  }

  async function gleimtLikilorð() {
    skikkaGleimtHleður("Sendir...");
    skikkaGleimtSvarMiðlara(null);
    skikkaGleimtVillu(null);
    const gleiminsNetfáng = gleimtLikilorðNetfáng.trim();
    if (gleiminsNetfáng.length < 3) {
      skikkaGleimtVillu("Netfangið er alltof stutt");
      skikkaGleimtHleður(null);
      return;
    }
    try {
      const svarMiðlara = await miðlarabón.post("/gutlaraskraning/gleimtlikilordsendapost", {netfáng: gleiminsNetfáng});
      if (svarMiðlara.status === 200) {
        skikkaGleimtSvarMiðlara(svarMiðlara.data);
      }
    } catch (villaBónar) {
      skikkaGleimtVillu(fáMiðlaravillu(villaBónar));
    }
    skikkaGleimtHleður(null);
  }

  useEffect(() => {
    if (gutlari.gutlaranafn) {
      sendaÞángað("/");
    }
  }, [gutlari.gutlaranafn])

  return (
    <section className="umgjörð-lóðrétt skráinn-aðal">
      <h2 className="skráinn-firirsögn">Skrá inn</h2>
      <p className="skráinn-gleimtlikilorð-senda">
        {/* Líklegt er að skilgreiníng nokkurra orða á síðunni er þér óljós. Ég mæli þess vegna með, eftir innskráníngu, að fara á orðaforðasíðuna og kinnast aðeins þeim ókunnugu orðum. */}
        Ef þú hefur aldrei komið hingað áður, þá mæli ég með því að ýta á takkann uppi þar sem stendur "Smíða gutlara"
      </p>
      <form className="skráinn-inntök">
        <div className="skráinn-inntak-lesmál">
          <ÚtfillingarTexti 
            texti="Gutlaranafn eða netfang" 
            gildi={einkennisnafn} 
            breitaGildi={skikkaEinkennisnafni} 
            tegund="email"
            staðfestaTakki={skráInn}
          />
        </div>
        <div className="skráinn-inntak-lesmál">
          <ÚtfillingarTexti 
            texti="Lykilorð" 
            gildi={likilorð} 
            breitaGildi={skikkaLikilorði} 
            tegund="password"
            staðfestaTakki={skráInn}
          />
        </div>
        <div className="umgjörð-lóðrétt skráinn-staðfestaogvilla">
          <div className="skráinn-staðfestaogvilla-takki">
            <VilluHleðurTakki
              villa={villa}
              hleður={hleður}
              aðgerð={skráInn}
              textiAðgerðar="Skrá inn!"
            />
          </div>
          {fillaInnGleimtLikilorð ? (
            <>
              <div className="skráinn-inntak-lesmál">
                <ÚtfillingarTexti 
                  texti="Skráðu netfang þitt til að endurheimta lykilorð þitt" 
                  gildi={gleimtLikilorðNetfáng} 
                  breitaGildi={skikkaGleimtLikilorðNetfáng} 
                  tegund="email"
                  staðfestaTakki={gleimtLikilorð}
                />
              </div>
              <div className="skráinn-gleimtlikilorð-senda">
                <VilluHleðurTakki
                  svarMiðlara={gleimtSvarMiðlara}
                  hleður={gleimtHleður}
                  villa={gleimtVilla}
                  aðgerð={gleimtLikilorð}
                  textiAðgerðar="Senda tölvupóst til að endurheimta lykilorð"
                />
              </div>
            </>
          ) : (
            <div className="skráinn-gleimtlikilorð">
              <TakkiAðgerð1 texti="sjáðu til, ég gleymdi eiginlega lykilorðinu mínu... " aðgerð={() => skikkaFillaInnGleimtLikilorð(true)}/>
            </div>
          )}
        </div>
      </form>
    </section>
  )
}

export default SkráInn;