import { useContext, useEffect, useState } from "react";
import "../../../almenncss/grunncss.css";
import "../../../almenncss/villaoghleður.css";
import Listi from "../listi/Listi";
import "./tímaskrárfastur.css";
import "../../../síðurnar/árnaðarmaður/búatilótækan/búatilótækantíma/búatilótækantíma.css";
import miðlarabón from "../../../miðlarabón";
import { fáMiðlaravillu } from "../../../gjörníngar/ímsir";
import TakkiAðgerð1 from "../takkar/takkiAðgerð1/TakkiAðgerð1";
import {ÁrnaðarmaðurSamheingi} from "../../../samheingi/árnaðarmaðurSamheingi"
import Tímaval from "../tímaval/Tímaval";
import VilluHleðurTakki from "../villaoghleður/villuhleðurtakki/VilluHleðurTakki";
import { sólarhríngur } from "../../../almenngögn";
import VilluHleðurTakki2 from "../villaoghleður/villuhleðurtakki/VilluHleðurTakki2";


const TímaskrárFastur = ({fasturTími}) => {
  const [tímaskrár, skikkaTímaskrám] = useState([]);
  const [hleður, skikkaHleður] = useState(null);
  const [villa, skikkaVillu] = useState(null);

  const [bætaViðTímaskrá, skikkaBætaViðTímaskrá] = useState(false);
  const [tími, skikkaTíma] = useState([]);

  const [svarBæta, skikkaSvariBæta] = useState(null);
  const [hleðurBæta, skikkaHleðurBæta] = useState(null);
  const [villaBæta, skikkaVilluBæta] = useState(null);

  const [svarSamsama, skikkaSvariSamsama] = useState(null);
  const [hleðurSamsama, skikkaHleðurSamsama] = useState(null);
  const [villaSamsama, skikkaVilluSamsama] = useState(null);

  const {völlur} = useContext(ÁrnaðarmaðurSamheingi);

  useEffect(() => {
    async function náíTímaskráar() {
      const einki = fasturTími.aðaltími ? fasturTími.aðaltími : fasturTími.einki;
      skikkaHleður("Sækir Tímaskrár...");
      try {
        const náíGögn = await miðlarabón.get(`fastur/fatimaskrarfaststima/${einki}`);
        skikkaTímaskrám(náíGögn.data);
      } catch (villaBónar) {
        skikkaVillu(fáMiðlaravillu(villaBónar));
      }
      skikkaHleður(null);
    }

    if (!tímaskrár || tímaskrár.length < 1) {
      náíTímaskráar();
    }
  }, [])

  // function sjáTímaskrárSemVantar(tímaskrár, tegundTímabils, klstfrá, mínfrá, klsttil, míntil, tímabil_frá, tímabil_til) {
  // }

  async function bætaTímaskrá() {
    //athuga kvort er innan tímabils
    const frá = new Date(fasturTími.tímabil_frá).getTime();
    const til = new Date(fasturTími.tímabil_til).getTime() + sólarhríngur; //bæta við sólarhríng
    const tímifrá = tími[0];

    if (tímifrá < frá || tímifrá > til) {
      skikkaVilluBæta("Tíminn þarf að vera innan tímabils fasts tíma");
      return;
    }
    skikkaVilluBæta(null);
    skikkaHleðurBæta("Bætir tíma við...");
    const einkiFasts = fasturTími.aðaltími ? fasturTími.aðaltími : fasturTími.einki;
    try {
      const svar = await miðlarabón.post("fastur/baetavidfastan/" + einkiFasts, {tími});
      skikkaSvariBæta(svar.data);  
    } catch (villaBónar) {
      skikkaVilluBæta(fáMiðlaravillu(villaBónar));
    }
    skikkaHleðurBæta(null);
  }


  //væri reindar líka hægt að nota þetta ef einkver tímaskrá er ekki sú sama og hinar
  async function geraÓuppteknarUppteknar() {
    skikkaVilluSamsama(null);
    skikkaHleðurSamsama("Samsamar óuppteknum tímaskrám...");
    const einkiFasts = fasturTími.aðaltími ? fasturTími.aðaltími : fasturTími.einki;
    try {
      const svar = await miðlarabón.put("fastur/samsamatima/" + einkiFasts);
      skikkaSvariSamsama(svar.data);
    } catch (villaBónar) {
      skikkaVilluSamsama(fáMiðlaravillu(villaBónar));
    }
    skikkaHleðurSamsama(null);
  }

  return (
    <section className="umgjörð-lóðrétt">
      <h4 className="smeija-aðaltakki">{fasturTími.upptekni === 4 ? "Tímaskrár" : "Ætlunartímaskrár"}</h4>
      {hleður && <p className="smeija-takki villaoghleður-hleður">{hleður}</p>}
      {villa && <p className="smeija-takki villaoghleður-villa">{villa}</p>}
      <div className="smeija-listi">
        <Listi
          tegund="tímaskrá"
          breidd={3}
          listi={tímaskrár}
        />
      </div>
      {bætaViðTímaskrá ? (
        <>
          <h3 className="smeija-aðaltakki">Bæta tímaskrá við fastan tíma</h3>
          <div className="búatilót-velja umgjörð-lóðrétt">
            <Tímaval
              tími={tími}
              skikkaTíma={skikkaTíma}
              einkiVallar={völlur.einki}
            />
          </div>
          {(fasturTími.upptekni === 4 || fasturTími.upptekni === 2) && (
            <p className="smeija-lítilltakki">Athugaðu að skipaðir tímar og uppteknarbónir á þessum tíma eyðast</p>
          )}
          <div className="smeija-aðaltakki">
            <VilluHleðurTakki
              textiAðgerðar="Bæta við tímaskrá"
              aðgerð={bætaTímaskrá}
              svarMiðlara={svarBæta}
              hleður={hleðurBæta}
              villa={villaBæta}
            />
          </div>
        </>

      ) : (
        <>
          <div className="smeija-takki">
            <TakkiAðgerð1 texti="Bæta tímaskrá við fastan tíma" aðgerð={() => skikkaBætaViðTímaskrá(true)}/>
          </div>
          {fasturTími.upptekni === 2 && (
            <>
              <div className="smeija-takki">
                <VilluHleðurTakki2
                  textiAðgerðar="Gera óuppteknar tímaskrár uppteknar"
                  aðgerð={geraÓuppteknarUppteknar}
                  svarMiðlara={svarSamsama}
                  hleður={hleðurSamsama}
                  villa={villaSamsama}
                />
              </div>
              <p className="smeija-lítilltakki">(^ Takki notaður ef ætlunarstefnir bætir við tímaskrá, þá þarft þú að samþykkja upptekni þeirra)</p>
            </>
          )}
        </>
      )}
    </section>
  )
}

export default TímaskrárFastur;