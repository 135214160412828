import React, { useState } from "react"
import StaðfestaTíma from "./staðfestatíma/StaðfestaTíma";
import VeljaDag from "./veljadag/VeljaDag";
import VeljaTímasetníngar from "./veljatímasetníngar/VeljaTímasetníngar";

const VeljaTíma = ({ lokaSmeiju, skikkaTíma, einkiVallar, einkiTímaskráar, völlurVallarsíðu }) => {
  const [tímiíHluta, skikkaTímaíHluta] = useState([]);
  const [dagur, skikkaDeigi] = useState(null);
  
  let hluti;
  if (dagur && tímiíHluta.length > 1) {
    hluti = <StaðfestaTíma skikkaTíma={skikkaTíma} tímiHluta={tímiíHluta} breitaTímaHluta={skikkaTímaíHluta} lokaSmeiju={lokaSmeiju} völlurVallarsíðu={völlurVallarsíðu} />
  } else if (dagur) {
    hluti = <VeljaTímasetníngar dagur={dagur} skikkaDeigi={skikkaDeigi} skikkaVöldumTíma={skikkaTímaíHluta} valinnTími={tímiíHluta} einkiVallar={einkiVallar} einkiTímaskráar={einkiTímaskráar}/>
  } else {
    hluti = <VeljaDag skikkaDeigi={skikkaDeigi}/>
  }

  return (
    <>
      {hluti}
    </>
  )
}

export default VeljaTíma;