import React from "react";
import "../leikjanautar.css";
import FlettíngarListi from "../../../hlutir/almennirhlutar/flettíngartakkar/FlettíngarListi";
import miðlarabón from "../../../miðlarabón";
import { athugunLeitarorðs, fáMiðlaravillu } from "../../../gjörníngar/ímsir";

const NíjirLeikjanautar = ({níjir}) => {


  async function sendaLeikjanautabón(gutlari, skikkaSvariMiðlara, skikkaHleður, skikkaVillu) {
    skikkaHleður("Sendir leikjanautabón...");
    skikkaSvariMiðlara(null);
    skikkaVillu(null);
    
    try {
      const svar = await miðlarabón.post("/leikjanautar/sendaleikjanautabon/" + gutlari.einki);
      if (svar.status === 200) {
        skikkaSvariMiðlara(svar.data);
      }
    } catch (villaBónar) {
      skikkaVillu(fáMiðlaravillu(villaBónar));
    }
    skikkaHleður(null);
  }

  return (
    <div className="leikjanautar-listi" ref={níjir}>
      <h3 className="leikjanautar-listi-firirsögn">Leita af nýjum leikjanautum</h3>
      <div className="leikjanautar-listi-listi">
        <FlettíngarListi 
          veffáng="leikjanautar/leitafleikjanautum?" 
          tegundLista="gutlarar" 
          breiddLista={3} 
          textiAðgerðarLista="Senda bandsbón"
          aðgerðLista={sendaLeikjanautabón} 
          mestiFjöldiíLista={24}
          athugunLeitarorðs={athugunLeitarorðs} 
          leit={true}
        />
      </div>
    </div>
  )
}

export default NíjirLeikjanautar;