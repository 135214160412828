import { useRef, useState } from "react";
import "../../../gutlaraleikur/miðjagutlaraleiks/breitaeiginleikumleiks/breitaeiginleikumleiks.css"
import "../../../../almenncss/grunncss.css";
import "../../../gutlaraleikur/miðjagutlaraleiks/ivirgefaleik/ivirgefaleik.css"
import { useParams } from "react-router-dom";
import TakkiAðgerð1 from "../../../../hlutir/almennirhlutar/takkar/takkiAðgerð1/TakkiAðgerð1";
import ÚtfillíngarMöguleikar from "../../../../hlutir/almennirhlutar/útfillíngar/útfillíngarmöguleikar/ÚtfillíngarMöguleikar";
import ÚtfillíngarHak from "../../../../hlutir/almennirhlutar/útfillíngar/útfillíngarhak/ÚtfillíngarHak";
import ÚtfillíngarTala from "../../../../hlutir/almennirhlutar/útfillíngar/útfillíngartala/ÚtfillíngarTala";
import ÚtfillíngarTexti from "../../../../hlutir/almennirhlutar/útfillíngar/útfillíngartexti/ÚtfillíngarTexti";
import { fáKinLeiks, fáMiðlaravillu, fáÍþrótt, jáEðaNei } from "../../../../gjörníngar/ímsir";
import TakkiAðgerð2 from "../../../../hlutir/almennirhlutar/takkar/takkiAðgerð2/TakkiAðgerð2";
import FlettíngarListiMargir from "../../../../hlutir/almennirhlutar/flettíngartakkar/FlettíngarlistiMargir";
import { upphafslisti, íþróttir } from "../../../../almenngögn";
import Smeija from "../../../../hlutir/almennirhlutar/smeija/Smeija";
import VilluHleðurTakki from "../../../../hlutir/almennirhlutar/villaoghleður/villuhleðurtakki/VilluHleðurTakki";
import miðlarabón from "../../../../miðlarabón";
import slóðminda from "../../../../slóðminda";
import { fáKin } from "../../../búatilníjanleik/hægribúatilníjanleik/veljaaðraeiginleikaleiks/VeljaAðraEiginleikaLeiks";




function fáEiginleika(eiginleikar, firirætlun) {
  //nota alltErSama svo ég sendi ekki á miðlara ef það eru eingar breitíngar
  let alltErSama = true;
  //ætlunarstefnir*
  const gamliÆtlunarstefnir = firirætlun.ætlunarstefnir;
  let valinnLeikstefnir = eiginleikar.leikstefnir ? eiginleikar.leikstefnir.einki : null;
  if (!valinnLeikstefnir) {
    valinnLeikstefnir = gamliÆtlunarstefnir;
  }
  if (valinnLeikstefnir !== gamliÆtlunarstefnir) {
    alltErSama = false;
  }

  //íþrótt
  const íþrótt = eiginleikar.íþrótt;
  const gamlaÍþrótt = firirætlun.leikháttur;
  let valinÍþrótt = null;
  if (íþrótt !== gamlaÍþrótt) {
    alltErSama = false;
  }
  valinÍþrótt = fáÍþrótt(íþrótt);

  //leiksmæli
  const gömluLeiksmælin = firirætlun.athugasemd;
  const valinLeiksmæli = eiginleikar.leiksmæli;
  if (valinLeiksmæli !== gömluLeiksmælin) {
    alltErSama = false;
  }
  if (valinLeiksmæli && valinLeiksmæli.length > 100) {
    return {villa: "Alltof löng leiksmæli.."}
  }

  //gera saman
  const gamlaGeraSaman = firirætlun.gera_saman_eftir_leik;
  const valiðGeraSaman = eiginleikar.gerasaman;
  if (valiðGeraSaman !== gamlaGeraSaman) {
    alltErSama = false;
  }
  if (valiðGeraSaman && valiðGeraSaman.length > 1000) {
    return {villa: "Alltof langur texti í gera saman eftir leik.."}
  }
  

  //aðgeingi
  const gamlaAðgeingi = firirætlun.aðgeingi
  const aðgeingi = eiginleikar.aðgeingi;
  let valiðAðgeingi = 1; //sama og "Luktur leikur" (gildi hans í gagnasafni)
  if (aðgeingi !== gamlaAðgeingi) {
    alltErSama = false;
  }
  //gildi þeirra í gagnasafni
  if (aðgeingi === "Opinn leikur") valiðAðgeingi = 2; 
  if (aðgeingi === "Bláopinn leikur") valiðAðgeingi = 3;

  //aðrirmeigabjóða
  const gamlaAðrirmeigabjóða = firirætlun.aðrir_meiga_bjóða;
  const valiðAðrirMeigaBjóða = eiginleikar.aðrirmeigabjóða;
  if (valiðAðrirMeigaBjóða !== gamlaAðrirmeigabjóða) {
    alltErSama = false;
  }

  //mestifjöldigutlara
  const gamlaMestifjöldigutlara = firirætlun.mesti_fjöldi_gutlara;
  const valinnMestiFjöldiGutlara = eiginleikar.mestifjöldigutlara;
  if (valinnMestiFjöldiGutlara !== gamlaMestifjöldigutlara) {
    alltErSama = false;
  }
  
  if (valinnMestiFjöldiGutlara < 2) {
    return {villa: "Mesti fjöldi gutlara er alltof lágur"};
  }

  //hæsti og lægsti aldur
  const gamliHæstiAldur = firirætlun.hæsti_aldur;
  const gamliLægstiAldur = firirætlun.lægsti_aldur;
  let valinnHæstiAldur = eiginleikar.hæstialdur;
  let valinnLægstiAldur = eiginleikar.lægstialdur;
  if (!eiginleikar.veljaaldur) {
    valinnHæstiAldur = null;
    valinnLægstiAldur = null;
  } else {
    if (valinnHæstiAldur < valinnLægstiAldur) {
      return {villa: "Hæsti aldur er lærri en lægsti..."};
    }
    if (valinnLægstiAldur < 0) {
      return {villa: "Lægsti aldur ætti ekki að vera mínustala"};
    }
  }
  if (valinnHæstiAldur !== gamliHæstiAldur || valinnLægstiAldur !== gamliLægstiAldur) {
    alltErSama = false;
  }

  //kin
  let gamlaKin = null;
  //gildi kina í gagnasafni
  if (firirætlun.kin === "Fljóð") gamlaKin = 2;
  if (firirætlun.kin === "Gumi") gamlaKin = 1;
  
  const valiðKin = fáKin(eiginleikar.kin, eiginleikar.veljakin);
  
  if (valiðKin !== gamlaKin) {
    alltErSama = false;
  }
  
  //forritskiftirílið
  const gamlaForritskiftirílið = firirætlun.skifta_í_lið;
  const valiðForritSkiftiríLið = eiginleikar.forritskiftirílið;
  if (valiðForritSkiftiríLið !== gamlaForritskiftirílið) {
    alltErSama = false;
  }


  const valdirEiginleikar = {
    valinnÆtlunarstefnir: valinnLeikstefnir, 
    valinÍþrótt, 
    valinLeiksmæli,
    valiðAðgeingi, 
    valiðAðrirMeigaBjóða, 
    valinnMestiFjöldiGutlara,
    valinnHæstiAldur, 
    valinnLægstiAldur, 
    valiðKin,
    valiðForritSkiftiríLið,
    valiðGeraSaman
  };

  if (alltErSama) {
    return {villa: "Engin breyting"}
  }
  
  return valdirEiginleikar;
}


const BreitaEiginleikumFirir = ({
  firirætlun, 
  skikkaFirirætlun,
  skikkaBreitaVali, 
  einkiFirirætlunar
}) => {

  const [breittAðgeingi , skikkaBreittuAðgeingi] = useState(firirætlun.aðgeingi || "Luktur leikur");
  const [breittAðrirmeigabjóða , skikkaBreittuAðrirmeigabjóða] = useState(true && firirætlun.aðrir_meiga_bjóða);
  const [breitturMestifjöldigutlara , skikkaBreittumMestifjöldigutlara] = useState(firirætlun.mesti_fjöldi_gutlara || 0);
  const [breitturHæstialdur , skikkaBreittumHæstaaldri] = useState((firirætlun.hæsti_aldur == null) ? 80 : firirætlun.hæsti_aldur);
  const [breitturLægstialdur , skikkaBreittumLægstaaldri] = useState((firirætlun.lægsti_aldur == null) ? 5 : firirætlun.lægsti_aldur);
  const [breittKin, skikkaBreittuKini] = useState(fáKinLeiks(firirætlun.kin));
  const [breittForritskiftirílið, skikkaBreittuForritskiftirílið] = useState(firirætlun.skifta_í_lið || false);
  
  const [breitturLeikstefnir , skikkaBreittumLeikstefni] = useState(null);
  const [breittÍþrótt , skikkaBreittriÍþrótt] = useState(firirætlun.leikháttur);
  const breittLeiksmæli = useRef(firirætlun.athugasemd ? firirætlun.athugasemd : "");
  const breittGeraSaman = useRef(firirætlun.gera_saman_eftir_leik ? firirætlun.gera_saman_eftir_leik : "");

  //veljaÆtlunarstefni*
  const [veljaLeikstefni, skikkaVeljaLeikstefni] = useState(false);
  const [veljaAldur, skikkaVeljaAldur] = useState(!(!firirætlun.hæsti_aldur && firirætlun.hæsti_aldur !== 0));
  const [veljaKin, skikkaVeljaKin] = useState(firirætlun.kin && true);

  const [breitaEiginleikumSvar, skikkaBreitaEiginleikumSvar] = useState(null);
  const [breitaEiginleikumHleður, skikkaBreitaEiginleikumHleður] = useState(null);
  const [breitaEiginleikumVilla, skikkaBreitaEiginleikumVilla] = useState(null);

  const memmaðir = useRef(upphafslisti);
  const einkiliðs = useParams().einkilids;

  //leiksmælum*
  const breitaLeiksmæli = (breitt) => {
    breittLeiksmæli.current = breitt;
  }

  function breitaGeraSaman(breitt) {
    breittGeraSaman.current = breitt;
  }

  async function breitaEiginleikum(eiginleikar) {
    skikkaBreitaEiginleikumHleður("Breytir...");
    skikkaBreitaEiginleikumVilla(null);
    skikkaBreitaEiginleikumSvar(null);

    const breittirEiginleikar = fáEiginleika(eiginleikar, firirætlun)
    if (breittirEiginleikar.villa) {
      skikkaBreitaEiginleikumHleður(null);
      skikkaBreitaEiginleikumVilla(breittirEiginleikar.villa);
      return;
    }
    
    try {
      const gögnMiðlara = await miðlarabón.put(`/firiraetlanir/breitaeiginleikum/${einkiFirirætlunar}`, breittirEiginleikar);
      if (gögnMiðlara.status === 200) {
        //uppfæra firirætlun í biðlara
        skikkaFirirætlun(firriFirirætlun => {
          const firriÆtlunarstefnir = firriFirirætlun.ætlunarstefnir;
          const uppfærðurFirirætlun = {...firriFirirætlun};

          uppfærðurFirirætlun.leikháttur = eiginleikar.íþrótt;
          uppfærðurFirirætlun.athugasemd = eiginleikar.leiksmæli;
          uppfærðurFirirætlun.gera_saman_eftir_leik = eiginleikar.gerasaman;
          
          if (eiginleikar.leikstefnir) {
            uppfærðurFirirætlun.ætlunarstefnir = eiginleikar.leikstefnir.einki;
          }
          uppfærðurFirirætlun.aðgeingi = eiginleikar.aðgeingi;
          uppfærðurFirirætlun.aðrir_meiga_bjóða = eiginleikar.aðrirmeigabjóða;
          uppfærðurFirirætlun.mesti_fjöldi_gutlara = eiginleikar.mestifjöldigutlara;
          uppfærðurFirirætlun.skifta_í_lið = eiginleikar.forritskiftirílið;
            
          if (breittirEiginleikar.valiðKin === 1) {
            uppfærðurFirirætlun.kin = "Gumi"
          } else if (breittirEiginleikar.valiðKin === 2) {
            uppfærðurFirirætlun.kin = "Fljóð"
          } else {
            uppfærðurFirirætlun.kin = null;
          }
          if (eiginleikar.veljaaldur) {
            uppfærðurFirirætlun.hæsti_aldur = eiginleikar.hæstialdur;
            uppfærðurFirirætlun.lægsti_aldur = eiginleikar.lægstialdur;
          } else {
            uppfærðurFirirætlun.hæsti_aldur = null;
            uppfærðurFirirætlun.lægsti_aldur = null;
          }

          if (breittirEiginleikar.valinnÆtlunarstefnir !== firriÆtlunarstefnir) {
            uppfærðurFirirætlun.erStefnir = false;
          }
          
          return uppfærðurFirirætlun;
        })
        skikkaBreitaVali(false);
      } 

    } catch (villaBónar) {
      skikkaBreitaEiginleikumVilla(fáMiðlaravillu(villaBónar));
    }
    skikkaBreitaEiginleikumHleður(null)
  }

  const firraKin = fáKinLeiks(firirætlun.kin);

  const breitaEiginleikumHluti = (
    <div className="umgjörð-lóðrétt-ómiðjað breitaeiginl-aðal">
      <h2 className="smeija-aðaltakki">Breyta eiginleikum</h2>
      <p className="breitaeiginl-þarfarupplísíngar">Upplýsíngar innan sviga tákna núverandi eiginleika fyrirætlunar</p>
      <div className="breitaeiginl-hættaviðbreitíngar">
        <TakkiAðgerð1 texti="Hætta við að breyta" aðgerð={() => skikkaBreitaVali(false)} />
      </div>

      <div className="breitaeiginl-eiginleiki">
        <ÚtfillíngarMöguleikar texti={`Aðgengi leikja (${firirætlun.aðgeingi})`} gildi={breittAðgeingi} breitaGildi={skikkaBreittuAðgeingi} möguleikar={["Luktur leikur", "Opinn leikur", "Bláopinn leikur"]}/>
      </div>
      <div className="breitaeiginl-eiginleiki">
        <ÚtfillíngarHak texti={`Aðrir mega bjóða (${jáEðaNei(firirætlun.aðrir_meiga_bjóða)})`} gildi={breittAðrirmeigabjóða} breitaGildi={skikkaBreittuAðrirmeigabjóða}/>
      </div>
      <div className="breitaeiginl-eiginleiki">
        <ÚtfillíngarTala texti={`Mesti fjöldi gutlara (${firirætlun.mesti_fjöldi_gutlara})`} gildi={breitturMestifjöldigutlara} breitaGildi={skikkaBreittumMestifjöldigutlara}/>
      </div>
      <div className="breitaeiginl-eiginleiki">
        <ÚtfillíngarHak texti={`Láta forrit skipta í lið (${jáEðaNei(firirætlun.skifta_í_lið)})`} gildi={breittForritskiftirílið} breitaGildi={skikkaBreittuForritskiftirílið}/>
      </div>
      <div className="breitaeiginl-velja">
        <TakkiAðgerð1 texti={veljaKin ? "Ókynskorðaður leikur" : "Kynskorðaður leikur"} aðgerð={() => skikkaVeljaKin(!veljaKin)}/>
      </div>
      {veljaKin && (
        <div className="breitaeiginl-eiginleiki">
          <ÚtfillíngarMöguleikar texti={`Kyn (${firraKin ? firraKin : "Óvalið"})`} gildi={breittKin} breitaGildi={skikkaBreittuKini} möguleikar={["Meyjaleikur \u2640", "Sveinaleikur \u2642"]}/>
        </div>
      )}
      <div className="breitaeiginl-velja">
        <TakkiAðgerð1 texti={veljaAldur ? "Sleppa aldurstakmörkun" : "Hafa aldurstakmörkun"} aðgerð={() => skikkaVeljaAldur(!veljaAldur)}/>
      </div>
      {veljaAldur && (
        <>
          <div className="breitaeiginl-eiginleiki">
            <ÚtfillíngarTala texti={`Hæsti aldur (${(firirætlun.hæsti_aldur == null) ? "óvalið" : firirætlun.hæsti_aldur})`} gildi={breitturHæstialdur} breitaGildi={skikkaBreittumHæstaaldri}/>
          </div>
          <div className="breitaeiginl-eiginleiki">
            <ÚtfillíngarTala texti={`Lægsti aldur (${(firirætlun.lægsti_aldur == null) ? "óvalið" : firirætlun.lægsti_aldur})`} gildi={breitturLægstialdur} breitaGildi={skikkaBreittumLægstaaldri}/>
          </div>
        </>
      )}
      <div className="breitaeiginl-eiginleiki">
        <ÚtfillíngarTexti texti={`Leiksmæli (${firirætlun.athugasemd ? firirætlun.athugasemd : ""})`} gildi={breittLeiksmæli.current} breitaGildi={breitaLeiksmæli}/>
      </div>
      <div className="breitaeiginl-eiginleiki">
        <ÚtfillíngarTexti texti={`Gera saman eftir leik (${firirætlun.gera_saman_eftir_leik ? firirætlun.gera_saman_eftir_leik : ""})`} gildi={breittGeraSaman.current} breitaGildi={breitaGeraSaman}/>
      </div>
      <div className="breitaeiginl-eiginleiki">
        <ÚtfillíngarMöguleikar texti={`Leikháttur leikja (${firirætlun.leikháttur})`} gildi={breittÍþrótt} breitaGildi={skikkaBreittriÍþrótt} möguleikar={[...íþróttir,"Annað"]}/>
      </div>
      {!breitturLeikstefnir && (
        <div className="breitaeiginl-velja">
          <TakkiAðgerð1 texti="Velja annan ætlunarstefni" aðgerð={() => skikkaVeljaLeikstefni(true)}/>
        </div>
      )}
      {breitturLeikstefnir && (
        <>
          <p className="ivirgefaleik-valinnleikstefnir-titill breitaeiginl-leikstefnir-auka-titill">Valinn ætlunarstefnir</p>
          <div className="ivirgefaleik-leiksstefnisskjöldur breitaeiginl-leikstefnir-auka">
            <img src={`${slóðminda}gutlaramindir/${breitturLeikstefnir.mind}`} className="ivirgefaleik-leiksstefnismind" alt="nýr ætlunarstefnir"/>
            <p className="ivirgefaleik-leiksstefnisnafn">{breitturLeikstefnir.gælunafn}</p>
            <TakkiAðgerð2 texti="Vil ekki velja þennan gutlara" aðgerð={() => skikkaBreittumLeikstefni(null)}/>
          </div>
        </>
      )} 

      <div className="breitaeiginl-staðfestaval">
        <VilluHleðurTakki
          svarMiðlara={breitaEiginleikumSvar}
          hleður={breitaEiginleikumHleður}
          villa={breitaEiginleikumVilla}
          aðgerð={() => breitaEiginleikum(
            {
              leikstefnir: breitturLeikstefnir,
              íþrótt: breittÍþrótt,
              leiksmæli: breittLeiksmæli.current,
              aðgeingi: breittAðgeingi,
              aðrirmeigabjóða: breittAðrirmeigabjóða,
              mestifjöldigutlara: Number(breitturMestifjöldigutlara),
              veljaaldur: veljaAldur,
              hæstialdur: Number(breitturHæstialdur),
              lægstialdur: Number(breitturLægstialdur),
              kin: breittKin,
              veljakin: veljaKin,
              forritskiftirílið: breittForritskiftirílið,
              gerasaman: breittGeraSaman.current
            }
          )}
          textiAðgerðar="Staðfesta val!"
        />
      </div>
      
    </div>
  )

  return (
    <>
    {breitaEiginleikumHluti}
    {veljaLeikstefni && (
      <Smeija lokaSmeiju={skikkaVeljaLeikstefni}>
        <div className="breitaeiginl-veljaleikstefnilisti">
          <FlettíngarListiMargir
            veffáng={`lid/saekjagutlara/memmadir/${einkiliðs}?`}
            tegundLista="gutlarar"
            breiddLista={3}
            textiAðgerðarLista="Velja"
            aðgerðLista={(gutlari) => {skikkaBreittumLeikstefni(gutlari); skikkaVeljaLeikstefni(false)}}
            mestiFjöldiíLista={24}
            stærð={1}
            sækjaStrax={true}
            efri={memmaðir}
          />
        </div>
      </Smeija>
    )}
    </>
  )

}


export default BreitaEiginleikumFirir;